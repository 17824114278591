import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, IconButton, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import CheckBox from "../CheckBox";

const ListResults = ({ results, totalCount, isLoading, headers, getResults = () => null, onRowClick = () => null, rowsPerPage = results.length, ...rest }) => {
	totalCount = results?.length || 0;
	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState([]);

	useEffect(() => {
		const savedFilters = JSON.parse(window.sessionStorage.getItem(`list-${window.location.pathname}`));

		if (savedFilters) {
			const h = headers.map((header, i) => {
				if (!header.filters) return null;
				const newFilters = Object.fromEntries(Object.entries(header.filters).map(([k, v]) => [k, savedFilters[i][k] ?? v]));
				return newFilters;
			});
			setFilters(h);
		} else {
			setFilters(
				headers.map((h) => {
					return h.filters;
				})
			);
		}
	}, [headers]);

	const handlePageChange = (event, newPage) => {
		const direction = newPage > page ? "next" : "prev";
		setPage(newPage);
		getResults(direction);
	};

	const renderCell = ({ header, cell, onClick, key, row }) => {
		const content = (() => {
			if (header.render) {
				return header.render(cell, row);
			} else if (header.type) {
				switch (header.type) {
					case "number":
						return cell || 0;
					default:
						return cell;
				}
			} else {
				switch (typeof cell) {
					case "number":
						return cell || 0;
					default:
						return cell;
				}
			}
		})();

		return (
			<TableCell key={key} onClick={onClick} size="small">
				<Box sx={{ alignItems: "center", display: "flex" }}>{content}</Box>
			</TableCell>
		);
	};

	const handleSetFilters = (i) => (fn) => {
		setFilters((filters) => {
			fn(filters[i]);
			window.sessionStorage.setItem(`list-${window.location.pathname}`, JSON.stringify(filters));
			return [...filters];
		});
	};

	const filterFunction = (result) => {
		let show = true;
		for (let i = 0; i <= filters.length; i++) {
			if (!filters[i]) continue;
			if (!Object.values(filters[i]).some(Boolean)) continue;
			const key = headers[i].id;
			if (!filters[i][result[key]]) {
				show = false;
				break;
			}
		}
		return show;
	};

	return (
		<Card {...rest}>
			<PerfectScrollbar>
				<Box>
					<Table>
						<TableHead>
							<TableRow>
								{headers.map((h, i) => (
									<HeaderCell key={i} label={h.label} filters={filters[i]} setFilters={handleSetFilters(i)} />
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading ? (
								<TableRow>
									<TableCell colSpan={headers.length}>
										<Typography align="center">Loading</Typography>
									</TableCell>
								</TableRow>
							) : !!results?.length ? (
								results.filter(filterFunction).map((row) => (
									<TableRow hover key={row._id || row.id} onClick={() => onRowClick(row)}>
										{headers.map((header, key) => renderCell({ row, header, cell: dotNot(row, header.id), key, onClick: () => header.onClick && header.onClick(row) }))}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={headers.length}>
										<Typography align="center">No Results</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</Box>
			</PerfectScrollbar>
			<TablePagination component="div" count={totalCount} onPageChange={handlePageChange} page={page} rowsPerPage={rowsPerPage} rowsPerPageOptions={[]} />
		</Card>
	);
};

function HeaderCell({ label, filters, setFilters }) {
	const [anchorEl, setAnchorEl] = useState();

	const handleFilterChange = (filter, value) => {
		setFilters((filters) => {
			filters[filter] = value;
			return filters;
		});
	};

	return (
		<>
			<TableCell>
				<Stack direction="row" alignItems="center">
					{label}
					{filters && (
						<IconButton onClick={({ target }) => setAnchorEl(target)}>
							<FilterAlt />
						</IconButton>
					)}
				</Stack>
			</TableCell>
			<Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={!!anchorEl} onClose={() => setAnchorEl()}>
				{Object.entries(filters || {})?.map(([f, value], key) => (
					<MenuItem key={key}>
						<CheckBox checked={filters[f]} onChange={(v) => handleFilterChange(f, v)} label={f} />
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

function dotNot(obj, path) {
	return path.split(".").reduce((p, c) => p?.[c], obj);
}

ListResults.propTypes = {
	results: PropTypes.array,
	isLoading: PropTypes.bool,
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string,
			type: PropTypes.oneOf(["array", "number", "boolean"]),
			render: PropTypes.func,
		})
	),
};

export default ListResults;
