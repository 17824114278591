import { Check, Close, Edit } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useRef, useState } from "react";

export default function useEdit(initData, onSave) {
	const [state, setState] = useState(initData);
	const [isEditing, setEditing] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const ref = useRef();

	useEffect(() => {
		if (initData) {
			if (Array.isArray(initData)) {
				setState([...initData]);
				ref.current = [...initData];
			} else if (typeof initData === "object") {
				setState({ ...initData });
				ref.current = { ...initData };
			} else {
				setState(initData);
				ref.current = initData;
			}
		}
	}, [initData]);

	const save = async () => {
		ref.current = { ...state };
		setEditing(false);
		if (onSave) {
			setLoading(true);
			let newState = state;
			if (Array.isArray(state)) {
				newState = [...state];
			} else if (typeof state === "object") {
				newState = { ...state };
			}
			await onSave(newState);
			setLoading(false);
		}
	};

	const cancel = () => {
		setEditing(false);

		setState((d) => {
			if (Array.isArray(d)) {
				return [...ref.current];
			} else if (typeof d === "object") {
				return { ...ref.current };
			} else {
				return ref.current;
			}
		});
	};

	const handleUpdate = (fn) => {
		if (typeof fn === "function") {
			setState((s) => fn(s));
		} else {
			setState(fn);
		}
	};

	return [
		state,
		handleUpdate,
		{
			isEditing,
			setEditing,
			CardAction: CardAction({ isEditing, setEditing, isLoading, save, cancel }),
		},
	];
}

function CardAction({ setEditing, isEditing, isLoading, save, cancel }) {
	if (isLoading) return <CircularProgress size={24} sx={{ m: 1 }} />;
	if (!isEditing) {
		return (
			<IconButton onClick={() => setEditing(true)}>
				<Edit />
			</IconButton>
		);
	}
	return (
		<Stack direction="row">
			<IconButton onClick={save}>
				<Check color="success" />
			</IconButton>
			<IconButton onClick={cancel}>
				<Close color="error" />
			</IconButton>
		</Stack>
	);
}
