import { useEffect, useState, createContext, useContext, useReducer } from "react";
import firebase from "../firebase";
import client from "../axios";

const authContext = createContext({
	isReady: false,
	signIn: () => null,
	signOut: () => null,
	isSignedIn: false,
	userInfo: {},
	refresh: () => null,
});

export function AuthProvider({ children }) {
	const value = useProvideAuth();
	return value.isReady && <authContext.Provider value={value}>{children}</authContext.Provider>;
}

export const useAuth = () => {
	return useContext(authContext);
};

function useProvideAuth() {
	const [isSignedIn, setSignedIn] = useState(null);
	const [userInfo, userDispatch] = useReducer((user, action) => {
		if (action.update) {
			return { ...user, ...action.update };
		} else if (action.set) {
			return { ...user, ...action.set };
		} else if (action.reset) {
			return {};
		} else console.error(action);
	}, {});

	useEffect(() => {
		let timeout;
		firebase.auth().onIdTokenChanged(async (user) => {
			if (!user) return setSignedIn(false);

			const { claims } = await user.getIdTokenResult();
			const tokenManager = user.toJSON().stsTokenManager;
			const expiresAt = tokenManager.expirationTime;
			client.defaults.headers.common["X-SavedBy-Token"] = tokenManager.accessToken;

			if (claims.roles?.length) {
				userDispatch({ update: { token: () => user.getIdToken(false), displayName: user.displayName, avatar: user.photoURL, email: user.email, uid: user.uid, roles: claims.roles } });
				timeout = setTimeout(async () => {
					await user.reload();
				}, expiresAt - Date.now());
				return setSignedIn(true);
			} else {
				await firebase.auth().signOut();
			}
		});
		return () => timeout && clearTimeout(timeout);
	}, []);

	const signIn = async (email, password, remember = true) => {
		await firebase.auth().setPersistence(firebase.auth.Auth.Persistence[remember ? "LOCAL" : "SESSION"]);
		return firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((u) => u.user.getIdTokenResult())
			.then((t) => !!t.claims.roles?.length);
	};

	const signOut = () => {
		return firebase.auth().signOut();
	};

	const refresh = () => {
		return firebase.auth().currentUser.reload();
	};

	const changePassword = async (newPass) => {
		await firebase.auth().currentUser.reload();
		return firebase.auth().currentUser.updatePassword(newPass);
	};

	const changeEmail = (newEmail) => {
		return firebase.auth().currentUser.updateEmail(newEmail);
	};

	return {
		isReady: isSignedIn !== null,
		signIn,
		signOut,
		isSignedIn: !!isSignedIn,
		userInfo,
		refresh,
		changePassword,
		changeEmail,
	};
}
