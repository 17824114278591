import { Avatar, Button, Card, CardContent, CardHeader, List, ListItem, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import { CgCheck, CgCircleci, CgClose } from "react-icons/cg";
import capitalize from "src/utils/capitalize";
import LineItem from "./LineItem";

export default function ItemsCard({ fulfillmentStatus, fulfillments, lineItems, canceledAt, source }) {
	return lineItems ? (
		<Card>
			{renderHeader(fulfillmentStatus, canceledAt)}
			<CardContent>
				<Stack>
					<List>
						{source === "POS" ? (
							<Typography>POS</Typography>
						) : (
							!!fulfillments.length && (
								<ListItem>
									<ListItemText
										primary={fulfillments[0]?.tracking_company}
										secondary={
											<Button target="_blank" href={fulfillments[0]?.tracking_url} LinkComponent="a">
												{fulfillments[0]?.tracking_number}
											</Button>
										}
									/>
								</ListItem>
							)
						)}
						{lineItems.map((l, i) => (
							<ListItem divider={lineItems.length - 1 !== i} disableGutters key={l.id}>
								<LineItem {...l} />
							</ListItem>
						))}
					</List>
				</Stack>
			</CardContent>
		</Card>
	) : (
		<Skeleton variant="rectangle" />
	);
}

const renderHeader = (fulfillment_status, canceledAt) => {
	let bgcolor;
	let icon;
	let title;
	if (canceledAt) {
		bgcolor = "red";
		icon = <CgClose size={200} />;
		title = "Cancelled - " + new Date(canceledAt).toLocaleString();
	} else if (fulfillment_status) {
		bgcolor = "green";
		icon = <CgCheck size={200} color={bgcolor} />;
		title = capitalize(fulfillment_status);
	} else {
		bgcolor = "orange";
		icon = <CgCircleci size={200} color={bgcolor} />;
		title = "Unfulfilled";
	}

	return <CardHeader avatar={<Avatar sx={{ bgcolor }}>{icon}</Avatar>} title={<h2>{title}</h2>} />;
};
