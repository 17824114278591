import { Stack, Button, MenuItem, TextField, Card, CardContent, CardHeader, FormControl, InputLabel, Grid, Select, Collapse, Checkbox, FormGroup, FormControlLabel, OutlinedInput } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import API from "src/API";
import View from "src/components/View";
import useAlert from "src/utils/Alert";

export default function SettingsView() {
	const [isLoading, setLoading] = useState(false);
	const [shopifyOpen, setShopifyOpen] = useState(false);
	const [settings, setSettings] = useState({});
	const { success } = useAlert();
	const nav = useNavigate();

	useEffect(() => {
		API.settings.get().then(setSettings).catch(console.error);
	}, []);

	const handleSave = (update) => {
		setLoading(true);
		API.settings
			.update(update)
			.then(() => success("Settings Saved"))
			.catch(console.error)
			.finally(() => setLoading(false));
	};

	return (
		<View
			title="Settings | Admin"
			isLoading={isLoading}
			toolbar={
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Button onClick={() => nav(-1)} variant="outlined">
						Back
					</Button>
				</Stack>
			}
		>
			<Stack spacing={2}>
				<CardHeader title="Default Tasks" />
				<Card>
					<CardHeader title="Shopify" onClick={() => setShopifyOpen((s) => !s)} />
					<Collapse in={shopifyOpen}>
						<CardContent>
							<Stack style={{ alignItems: "center" }}>
								<Stack spacing={2}>
									{settings.defaultTasks?.shopify?.map((task) => (
										<Task {...{ key: task.id, task, setSettings, platform: "shopify" }} />
									))}
								</Stack>
								<Button style={{ width: "80%" }} onClick={() => handleSave({ shopify: settings.defaultTasks?.shopify })}>
									Save
								</Button>
							</Stack>
						</CardContent>
					</Collapse>
				</Card>

				<CardHeader title="Default Widget Config" />
				<WidgetConfig {...{ defaultWidgetConfig: settings.defaultWidgetConfig, setSettings }} />

				<CardHeader title="Miscellaneous" />
				<Card>
					<CardContent>
						<Stack spacing={2}>
							<Misc {...{ misc: settings?.misc, msm: settings?.misc?.msm, setSettings }} />
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</View>
	);
}

function Task({ task, setSettings, platform }) {
	const handleChange = (update) => {
		setSettings((settings) => {
			Object.assign(task, update);
			return { ...settings };
		});
	};

	return (
		<Card>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={6} sm={4} md>
						<TextField fullWidth value={task.id} onChange={({ target }) => handleChange({ label: target.value })} label="ID" />
					</Grid>

					<Grid item xs={6} sm={4} md>
						<TextField fullWidth value={task.label} label="Label" onChange={({ target }) => handleChange({ label: target.value })} />
					</Grid>

					<Grid item xs sm={4} md>
						<FormControl fullWidth>
							<InputLabel id="done-label">Done</InputLabel>
							<Select value={task.done} onChange={({ target }) => handleChange({ done: target.value })} labelId="done-label" id="done" input={<OutlinedInput label="Done" />}>
								<MenuItem value={true}>True</MenuItem>
								<MenuItem value={false}>False</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={6} md>
						<TextField fullWidth value={task.actionLabel} label="Action Label" onChange={({ target }) => handleChange({ actionLabel: target.value })} />
					</Grid>

					<Grid item xs md>
						<FormControl fullWidth>
							<InputLabel id="action-label">Action</InputLabel>
							<Select value={task.action} onChange={({ target }) => handleChange({ action: target.value })} labelId="action-label" id="action" input={<OutlinedInput label="Action" />}>
								<MenuItem value="calendly">Calendly</MenuItem>
								<MenuItem value="link">Link</MenuItem>
								<MenuItem value="popup">Pop Up</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={12}>
						{["calendly", "link"].includes(task.action) && <TextField fullWidth value={task.url} label="URL" onChange={({ target }) => handleChange({ url: target.value })} />}
						{task.action === "popup" && <TextField multiline maxRows={5} fullWidth value={task.popupText} label="Popup Text" onChange={({ target }) => handleChange({ popupText: target.value })} />}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function WidgetConfig({ defaultWidgetConfig }) {
	const [widgetConfig, setWidgetConfig] = useState();
	const { success } = useAlert();

	useEffect(() => {
		if (defaultWidgetConfig) {
			setWidgetConfig(defaultWidgetConfig);
		}
	}, [defaultWidgetConfig]);

	const handleSave = (update) => {
		API.settings
			.update({ defaultWidgetConfig: widgetConfig })
			.then(() => success("Settings Saved"))
			.catch(console.error);
	};

	if (!widgetConfig) return null;
	return (
		<Card>
			<CardContent>
				<Stack spacing={1}>
					<TextField type="number" inputProps={{ min: 0 }} label="Max Coverage" value={widgetConfig?.maxCoverage || ""} onChange={({ target }) => setWidgetConfig((wc) => ({ ...wc, maxCoverage: target.value }))} />
				</Stack>
			</CardContent>
			<CardContent>
				<Stack spacing={1}>
					<FormGroup>
						<FormControlLabel control={<Checkbox checked={!!widgetConfig?.optInCond} onChange={(e, v) => setWidgetConfig((wc) => ({ ...wc, optInCond: v }))} />} label="Auto Opt-In" />
					</FormGroup>
				</Stack>
			</CardContent>
			<CardContent>
				<Stack spacing={1}>
					{widgetConfig?.feeTiers?.map((fee, key) => (
						<WidgetConfigFee {...{ key, fee, setWidgetConfig }} />
					))}
				</Stack>

				<Button onClick={handleSave}>Save</Button>
			</CardContent>
		</Card>
	);
}

function Misc({ msm, misc, setSettings }) {
	const [MSM, setMSM] = useState([]);
	const [defaultMSM, setDefaultMSM] = useState("");
	const { success } = useAlert();

	const handleSave = (update) => {
		API.settings
			.update(update)
			.then(() => success("Settings Saved"))
			.catch(console.error);
	};

	useEffect(() => {
		if (API.merchants) {
			API.merchants.getOnboarding().then(setMSM).catch(console.error);
		}
	}, []);

	useEffect(() => {
		setDefaultMSM(msm || "");
	}, [msm]);

	if (!MSM) return null;

	return (
		<Card>
			<CardContent>
				<Stack spacing={1}>
					<Stack spacing={2}>
						<FormControl>
							<InputLabel id="demo-dialog-select-label">MSM</InputLabel>
							<Select value={defaultMSM || ""} onChange={({ target }) => setDefaultMSM(target.value)} labelId="demo-dialog-select-label" id="demo-dialog-select" input={<OutlinedInput label="MSM" />}>
								{MSM.msms?.map((m) => (
									<MenuItem key={m._id} value={m._id}>
										{m.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
					<Button onClick={() => handleSave({ msm: defaultMSM })}>Save</Button>
				</Stack>
			</CardContent>
			<CardContent>
				<Stack spacing={1}>
					<Stack spacing={2}>
						<TextField label="Widget Version" value={misc?.widgetVersion || ""} onChange={({ target }) => setSettings((w) => ({ ...w, misc: { ...w.misc, widgetVersion: target.value } }))} />
					</Stack>
					<Button onClick={() => handleSave({ widgetVersion: misc.widgetVersion })}>Save</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}

function WidgetConfigFee({ fee, setWidgetConfig }) {
	const handleChange = (update) => {
		setWidgetConfig((wc) => {
			Object.assign(fee, update);
			return { ...wc };
		});
	};

	return (
		<Stack direction="row" spacing={1}>
			<TextField fullWidth type="number" inputProps={{ min: 0 }} label="Max" value={fee.max} onChange={({ target }) => handleChange({ max: target.value })} />
			<TextField fullWidth type="string" inputProps={{ min: 0 }} label="Price" value={fee.price} onChange={({ target }) => handleChange({ price: target.value })} />
		</Stack>
	);
}
