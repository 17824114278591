import { Avatar, AvatarGroup, Stack } from "@mui/material";
import View from "src/components/View";
import { useNavigate } from "react-router";
import StatusChip from "src/components/StatusChip";
import { useAuth } from "src/providers/AuthProvider";
import { useClaims } from "src/layouts/claim/ClaimsLayout";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import API from "src/API";
import SearchBar from "src/components/SearchBar";
import StatusHeader from "src/components/StatusHeader";
const options = ["APPROVED", "REVIEWING", "CLOSED", "DENIED"];

const ClaimsView = () => {
	const nav = useNavigate();
	const { userInfo } = useAuth();
	const { claims, isLoading } = useClaims();
	const [merchants, setMerchants] = useState([]);
	const [filteredClaims, setFilteredClaims] = useState([]);
	const [statusFilters, setStatusFilters] = useState(["REVIEWING"]);

	useEffect(() => {
		API.merchants.getAll().then(({ merchants }) => setMerchants(merchants));
	}, []);

	/**
	 * @type {import("@mui/x-data-grid").GridColDef}
	 */
	const columns = [
		{
			field: "status",
			headerName: "Status",
			minWidth: 200,
			renderCell: ({ value, row }) => <StatusChip variant="claim" status={value} subStatus={row.subStatus} />,
			sortable: false,
			disableColumnMenu: true,
			renderHeader: ({ colDef }) => <StatusHeader {...{ colDef, statusFilters, setStatusFilters, options }} />,
		},
		{
			field: "reviewers",
			headerName: "Reviewers",
			renderCell: ({ value }) => (
				<AvatarGroup>
					{value?.map((r, i) => (
						<Avatar key={i} sx={{ bgcolor: r.reviewed ? "green" : r.uid === userInfo.uid ? "orange" : "grey" }}>
							{r.name
								.split(" ")
								.map((l) => l[0])
								.join("")}
						</Avatar>
					))}
				</AvatarGroup>
			),
			flex: 1,
			sortable: false,
		},
		{
			field: "orderName",
			headerName: "Order #",
			flex: 1,
		},
		{
			field: "customer",
			headerName: "Email",
			renderCell: ({ value }) => value?.email,
			valueGetter: ({ value }) => value.email,
			flex: 1,
		},
		{
			field: "mid",
			headerName: "Merchant",
			valueGetter: ({ value }) => merchants.find((m) => m._id === value)?.name || value,
			flex: 1,
		},
		{
			field: "createdAt",
			headerName: "Claim Date",
			renderCell: ({ value }) => new Date(value).toLocaleString("en", { dateStyle: "short", timeStyle: "short" }),
			flex: 1,
		},
		{
			field: "resolvedAt",
			headerName: "Resolved Date",
			renderCell: ({ value }) => value && new Date(value).toLocaleString("en", { dateStyle: "short", timeStyle: "short" }),
			flex: 1,
		},
	];

	const onResults = useCallback(
		(results) => {
			setFilteredClaims((m) => {
				if (statusFilters?.length) {
					return results.filter((m) => statusFilters.includes(m.status));
				}
				return results;
			});
		},
		[statusFilters]
	);

	return (
		<View title="Claims" isLoading={isLoading}>
			<Stack spacing={3}>
				<SearchBar sx={{ pt: 2 }} data={claims} onResults={onResults} searchFunctions={searchFunctions} />
				<DataGrid rows={filteredClaims} columns={columns} getRowId={(r) => r._id} onRowClick={({ id }) => nav(id)} />
			</Stack>
		</View>
	);
};

export default ClaimsView;

const searchFunctions = [
	(s, r) => r.orderName.toLowerCase().includes(s.toLowerCase()),
	(s, r) => r.reorderName?.toLowerCase()?.includes(s.toLowerCase()),
	(s, r) => r.orderId === s,
	(s, r) => r.reorderId === s,
	(s, r) => r.customer?.email?.toLowerCase()?.includes(s.toLowerCase()),
	(s, r) => (r.customer?.firstName + " " + r.customer?.lastName)?.toLowerCase()?.includes(s.toLowerCase()),
];
