import { Avatar, Badge, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { withErrorBoundary } from "@sentry/react";
import { useState } from "react";
import Money from "src/Money";
import useEdit from "src/useEdit";
import FallBackCard from "../../components/FallBackCard";
import withLoading from "../../utils/withLoading";
import CheckBox from "../../components/CheckBox";
import QuantityInput from "./QuantityInput";
import currency from "src/utils/currency";
import { CardGiftcard, Discount, MonetizationOnOutlined, Refresh } from "@mui/icons-material";

function ClaimItems({ saveClaim, editDisabled, order, claim, inventories }) {
	const [open, setOpen] = useState(false);
	const t = useTheme();
	const isMd = useMediaQuery(t.breakpoints.down("md"), { noSsr: true });

	const [data, setData, { CardAction, isEditing }] = useEdit(claim, (c) => saveClaim({ ...c, newEvent: { type: "UPDATED", subType: "Items" } }), { disabled: editDisabled });

	const handleChange = (key, item) => (value) => {
		setData((data) => {
			const isRTS = claim.reason === "Returned to Sender";
			const itemCount = data.items.reduce((p, c) => p + c.quantity, 0);
			const index = data.items.findIndex((i) => i === item);
			const updatedItem = { ...item };
			updatedItem[key + "Count"] = value;
			data.items = [...data.items];
			data.items[index] = updatedItem;

			const itemsForReorder = data.items.filter((i) => i.reorderCount);
			const reorderCount = itemsForReorder.reduce((p, c) => p + c.reorderCount, 0);
			const originalShippingTotal = +order.shippingLines[0]?.price + +order.shippingLines[0]?.tax || "0.00";

			const reorderTotal = isRTS
				? "0.00"
				: itemsForReorder
						.reduce((p, c) => {
							if (!c.reorderCount) return p;
							const subtotal = new Money(c.totalPrice).multiply(c.reorderCount);
							return p.add(subtotal);
						}, new Money())
						.toString();

			const itemsForRefund = data.items.filter((i) => i.refundCount);
			const refundCount = itemsForRefund.reduce((p, c) => p + c.refundCount, 0);
			const refundTotal = isRTS
				? "0.00"
				: itemsForRefund
						.reduce((p, c) => {
							if (!c.refundCount) return p;
							const subtotal = new Money(c.totalPrice).multiply(c.refundCount);
							return p.add(subtotal);
						}, new Money())
						.toString();

			const itemsForStoreCredit = data.items.filter((i) => i.storeCreditCount);
			const storeCreditCount = itemsForStoreCredit.reduce((p, c) => p + c.storeCreditCount, 0);
			const storeCreditTotal = isRTS
				? "0.00"
				: itemsForStoreCredit
						.reduce((p, c) => {
							if (!c.storeCreditCount) return p;
							const subtotal = new Money(c.totalPrice).multiply(c.storeCreditCount);
							return p.add(subtotal);
						}, new Money())
						.toString();

			let refundShippingTotal = data.refundShippingTotal;
			let reorderShippingTotal = data.reorderShippingTotal;

			if (itemCount === refundCount) {
				refundShippingTotal = originalShippingTotal;
				reorderShippingTotal = "0.00";
			} else if (itemCount === reorderCount) {
				reorderShippingTotal = originalShippingTotal;
				refundShippingTotal = "0.00";
			} else {
				refundShippingTotal = "0.00";
				reorderShippingTotal = "0.00";
			}

			const claimTotal = new Money(reorderTotal).add(refundTotal).add(refundShippingTotal).add(reorderShippingTotal).add(storeCreditTotal).toString();
			return { ...data, reorderCount, refundCount, refundTotal, reorderTotal, storeCreditCount, storeCreditTotal: storeCreditTotal, claimTotal, refundShippingTotal, reorderShippingTotal };
		});
	};

	const refundShippingChange = (shippingLine, v) => {
		setData((data) => {
			//? Do we add tax to price?
			const refundShippingTotal = v ? new Money(shippingLine.price).add(shippingLine.tax).toString() : "0.00";

			// CALCULATE REFUND TOTAL
			const itemsForRefund = data.items.filter((i) => i.refundCount);
			const refundTotal = itemsForRefund.reduce((p, c) => p.add(new Money(c.totalPrice).multiply(c.refundCount)), new Money()).toString();

			const claimTotal = new Money(data.reorderTotal).add(refundTotal).add(refundShippingTotal).add(data.reorderShippingTotal).add(data.storeCredit).toString();

			return {
				...data,
				refundShippingTotal,
				refundTotal,
				claimTotal,
			};
		});
	};

	const unfulfilledItems = data.items.filter((item) => !item.fulfillmentStatus);

	if (isMd) return <MobileView {...{ order, claim, CardAction, data, handleChange, isEditing, unfulfilledItems, refundShippingChange, open, setOpen }} />;
	return (
		<Card sx={{ width: "100%" }}>
			<CardHeader title="Items" action={(claim.status === "REVIEWING" || claim.subStatus === "PENDING") && CardAction} />
			{order.fulfillments
				.filter((f) => f.status !== "cancelled")
				.map((fulfillment, key) => (
					<Fulfillment {...{ fulfillment, items: data.items, key, handleChange, isEditing, order, inventories }} />
				))}

			{!!unfulfilledItems.length && <UnfulfilledItems {...{ items: unfulfilledItems, handleChange, isEditing, order }} />}

			<ShippingLines {...{ shippingLines: order.shippingLines, refundShippingChange, refundShipping: data.refundShippingTotal !== "0.00", editDisabled: !isEditing }} />

			<Totals {...{ order, data }} />
			<EditReorderShippingDialog {...{ open, onClose: () => setOpen(false) }} />
		</Card>
	);
}

function ShippingLines({ shippingLines, refundShippingChange, refundShipping, editDisabled }) {
	return (
		<>
			<CardHeader title="Shipping" />
			<CardContent>
				<List>
					{shippingLines.map((item, i) => (
						<ListItem key={i}>
							<ListItemAvatar>
								<Avatar>S</Avatar>
							</ListItemAvatar>
							<Stack direction="row">
								<ListItemText primary={item.name} secondary={item.price + " + " + item.tax} />
								<CheckBox label="Refund?" checked={refundShipping} onChange={(v) => refundShippingChange(item, v)} disabled={editDisabled} />
							</Stack>
						</ListItem>
					))}
				</List>
			</CardContent>
		</>
	);
}

function EditReorderShippingDialog({ open, onClose }) {
	const [amount, setAmount] = useState(0.0);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Edit Reorder Shipping</DialogTitle>
			<DialogContent>
				<TextField value={amount} onChange={({ target }) => setAmount(target.value)} label="Amount" />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Submit</Button>
			</DialogActions>
		</Dialog>
	);
}

function UnfulfilledItems({ items, handleChange, isEditing, order }) {
	return (
		<CardContent>
			<Stack spacing={2}>
				<Stack direction="row" spacing={2}>
					<Chip color="warning" label="UNFULFILLED" />
				</Stack>
				<List>
					{items.map((item) => (
						<LineItem
							{...{
								key: item?.lineItemId,
								item,
								editDisabled: !isEditing,
								handleChange,
								order,
							}}
						/>
					))}
				</List>
			</Stack>
		</CardContent>
	);
}

function Fulfillment({ fulfillment: f, items, handleChange, isEditing, order, inventories }) {
	const fulfillments = f.lineItems.map((li) => items.find((i) => li === i.lineItemId)).filter(Boolean);
	const hasShippableItems = fulfillments.some((f) => f?.shippable);
	if (!hasShippableItems) return null;

	return (
		<CardContent sx={{ width: "100%" }}>
			<Stack spacing={2} width="100%">
				<Stack direction="row" spacing={2}>
					<Button href={f.trackingUrl} target="_blank">
						{f.trackingCompany}
					</Button>
					<Chip color="success" label={f.shipmentStatus !== null ? f.shipmentStatus.toUpperCase() + " - " + new Date(f.updatedAt).toLocaleDateString() : "N/A " + new Date(f.updatedAt).toLocaleDateString()} />
				</Stack>
				<List sx={{ width: "100%" }}>
					{fulfillments.map((item) => (
						<LineItem
							{...{
								key: item?.lineItemId,
								inventoryStatus: inventories.status,
								inventory: inventories.data?.find((d) => +d.variantId === item.variantId)?.inventory,
								item,
								editDisabled: !isEditing,
								handleChange,
								order,
							}}
						/>
					))}
				</List>
			</Stack>
		</CardContent>
	);
}

function LineItem({ item, editDisabled, handleChange, order, inventory, inventoryStatus }) {
	const [expanded, setExpanded] = useState(false);
	const [qtyOpen, setQtyOpen] = useState(false);

	if (!item || !order) return null;

	const itemRefunds = order.refunds.flatMap((r) => r.lineItems).filter((li) => li.line_item_id === item.lineItemId);

	return (
		<ListItem disableGutters sx={{ width: "100%" }}>
			<ListItemAvatar>
				<Badge anchorOrigin={{ vertical: "top", horizontal: "right" }} overlap="circular" badgeContent={itemRefunds.length && <MonetizationOnOutlined sx={{ fontSize: 20 }} />}>
					<Badge anchorOrigin={{ vertical: "bottom", horizontal: "right" }} overlap="circular" badgeContent={item.discounts.length && <Discount sx={{ fontSize: 20 }} />}>
						<Avatar onClick={() => setExpanded((t) => !t)}>{item.quantity || 0}</Avatar>
					</Badge>
				</Badge>
			</ListItemAvatar>

			<Grid container width="100%" minWidth={0}>
				<Grid item xs={12} width="100%">
					<Typography textOverflow="ellipsis" noWrap={!expanded}>
						{item.title}
					</Typography>
				</Grid>
				<Grid container item>
					<Grid item xs>
						<Typography variant="caption">
							{item.variantTitle} <b>{currency(item.totalPrice)}</b>
						</Typography>

						{item.discounts && (
							<Collapse in={expanded}>
								<Stack>
									{item.discounts.map((d, i) => (
										<Typography variant="caption" key={i}>
											<i>
												• {d.code} {currency(d.amount)}
											</i>
										</Typography>
									))}
								</Stack>
							</Collapse>
						)}

						{itemRefunds && (
							<Collapse in={expanded}>
								<Stack>
									{itemRefunds.map((d, i) => (
										<Stack direction="row" key={i} alignItems="center" spacing={1}>
											<MonetizationOnOutlined />

											<Typography variant="caption" key={i}>
												<i>{currency(d.total)}</i>
											</Typography>
										</Stack>
									))}
								</Stack>
							</Collapse>
						)}
					</Grid>
					<Grid item>
						<Stack direction="row" onClick={editDisabled ? undefined : () => setQtyOpen(true)} spacing={1}>
							<Chip size="small" label={item.refundCount} icon={<MonetizationOnOutlined />} />
							<Chip size="small" label={item.reorderCount} icon={<Refresh />} />
							<Chip size="small" label={item.storeCreditCount} icon={<CardGiftcard />} />
						</Stack>
					</Grid>
					<Grid item pl={1}>
						<Inventory status={inventoryStatus} inventory={inventory} />
					</Grid>
				</Grid>
			</Grid>

			<QuantityDialog {...{ open: qtyOpen, onClose: () => setQtyOpen(false), item, editDisabled, handleChange }} />
		</ListItem>
	);
}

function Inventory({ status, inventory }) {
	if (status === "loading") return <CircularProgress size={20} />;
	if (status === "success") {
		const totalQuantity = inventory.reduce((p, c) => p + c.quantity, 0);
		const locationCount = inventory.length;
		const label = `${totalQuantity} at ${locationCount} location${locationCount > 1 ? "s" : ""}`;
		const summary = (
			<Stack>
				{inventory.map((i) => (
					<Stack direction="row" spacing={2}>
						<Typography>{i.location}</Typography>
						<Typography>{i.quantity}</Typography>
					</Stack>
				))}
			</Stack>
		);
		return (
			<Tooltip title={summary} placement="right">
				<Chip icon={<Inventory />} size="small" label={label} />
			</Tooltip>
		);
	}
	return null;
}

function QuantityDialog({ open, onClose, item, editDisabled, handleChange }) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent>
				<QuantityInput value={item.refundCount} max={item.quantity - item.reorderCount - item.storeCreditCount} onChange={handleChange("refund", item)} label={"Refund"} disabled={editDisabled} icon={<MonetizationOnOutlined />} />
				<QuantityInput value={item.reorderCount} max={item.quantity - item.refundCount - item.storeCreditCount} onChange={handleChange("reorder", item)} label={"Reorder"} disabled={editDisabled} icon={<Refresh />} />
				<QuantityInput value={item.storeCreditCount} max={item.quantity - item.reorderCount - item.refundCount} onChange={handleChange("storeCredit", item)} label={"Store Credit"} disabled={editDisabled} icon={<CardGiftcard />} />
			</DialogContent>
		</Dialog>
	);
}

function Loading() {
	return (
		<Card>
			<CardHeader title="Items" />

			<CardContent>
				<Stack>
					<Skeleton width={200} />
					<br />
					<Skeleton width={60} />
					<Skeleton width={120} />
					<Skeleton width={60} />
					<Skeleton width={120} />
				</Stack>
			</CardContent>
		</Card>
	);
}

export default withErrorBoundary(withLoading(ClaimItems, Loading), { fallback: FallBackCard("Items", "Error loading claim items") });

function MobileView({ order, claim, CardAction, data, handleChange, isEditing, unfulfilledItems, refundShippingChange, open, setOpen }) {
	return (
		<Card sx={{ width: "100%" }}>
			<CardHeader title="Items" action={(claim.status === "REVIEWING" || claim.subStatus === "PENDING") && CardAction} />
			{order.fulfillments
				.filter((f) => f.status !== "cancelled")
				.map((fulfillment, key) => (
					<Fulfillment {...{ fulfillment, items: data.items, key, handleChange, isEditing, order }} />
				))}

			{!!unfulfilledItems.length && <UnfulfilledItems {...{ items: unfulfilledItems, handleChange, isEditing, order }} />}

			<ShippingLines {...{ shippingLines: order.shippingLines, refundShippingChange, refundShipping: data.refundShippingTotal !== "0.00", editDisabled: !isEditing }} />

			<EditReorderShippingDialog {...{ open, onClose: () => setOpen(false) }} />
			<Totals {...{ order, data }} />
		</Card>
	);
}

function Totals({ order, data }) {
	return (
		<Grid container spacing={1}>
			<Grid item xs={6}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<Typography colSpan={2} component={TableCell}>
								Original Order
							</Typography>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>SavedBy</TableCell>
							<TableCell align="right">${order.totals.savedBy}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Discount</TableCell>
							<TableCell align="right">${order.totals.discount}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Shipping</TableCell>
							<TableCell align="right">${order.totals.shipping}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Tax</TableCell>
							<TableCell align="right">${order.totals.tax}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<b>Total</b>
							</TableCell>
							<TableCell align="right">
								<b>{currency(order.totals.total)}</b>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Grid>

			<Grid item xs={6}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<Typography colSpan={2} component={TableCell}>
								Claim
							</Typography>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ pr: 0, pl: 1 }}>Refunds ({data.refundCount})</TableCell>
							<TableCell align="right" sx={{ pr: 1, pl: 0 }}>
								{currency(data.refundTotal)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{ pl: 2, pr: 0 }}> Shipping</TableCell>
							<TableCell align="right" sx={{ pr: 1, pl: 0 }}>
								{currency(data.refundShippingTotal)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{ pr: 0, pl: 1 }}>Reorders ({data.reorderCount})</TableCell>
							<TableCell align="right" sx={{ pr: 1, pl: 0 }}>
								{currency(data.reorderTotal)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{ pl: 2, pr: 0 }}> Shipping</TableCell>
							<TableCell align="right" sx={{ pr: 1, pl: 0 }}>
								{currency(data.reorderShippingTotal)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{ pr: 0, pl: 1 }}>Store Credit ({data.storeCreditCount})</TableCell>
							<TableCell align="right" sx={{ pr: 1, pl: 0 }}>
								{currency(data.storeCreditTotal)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{ pr: 0, pl: 1 }}>
								<b>Claim Total</b>
							</TableCell>
							<TableCell align="right" sx={{ pr: 1, pl: 0 }}>
								<b>{currency(data.claimTotal)}</b>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	);
}
