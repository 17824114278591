import { useEffect, useState } from "react";
import { Stack, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function SearchBar({ data, onResults, delay = 0, charLimit = 0, sx = {}, searchFunctions = [] }) {
	const [search, setSearch] = useState("");

	useEffect(() => {
		if (!data) return;
		if (search.length > charLimit) {
			const to = setTimeout(() => {
				const results = data.filter((c) => {
					return searchFunctions.some((sf) => sf(search, c));
				});
				onResults(results);
			}, delay);
			return () => clearTimeout(to);
		} else {
			onResults(data);
		}
	}, [search, data, onResults, charLimit, delay, searchFunctions]);

	return (
		<Stack width="100%">
			<TextField sx={sx} fullWidth value={search} onChange={({ target }) => setSearch(target.value)} InputProps={{ startAdornment: <Search sx={{ mr: 1 }} /> }} />
		</Stack>
	);
}
