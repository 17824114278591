import { AccountBalance, CreditCard, QuestionMark } from "@mui/icons-material";
import { Button, Stack, TextField, Typography, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { FaShopify } from "react-icons/fa";
import API from "src/API";

export default function ChargeDialog({ isChargeOpen, selectedStatement: statement, onClose, merchant, handleCharge, handleSkipStatement }) {
	const [paymentMethod, setPaymentMethod] = useState();
	const [chargeAmount, setChargeAmount] = useState(0.0);
	const [shopifyAppCharge, setShopifyAppCharge] = useState();
	const [transactions, setTransactions] = useState([]);
	const [error, setError] = useState();

	const [description, setDescription] = useState("SavedBy Fee Remittance");

	useEffect(() => {
		// CLEAR OUT STATE
		setTransactions();
		setShopifyAppCharge();
		setPaymentMethod();

		if (statement && merchant) {
			setDescription("SavedBy Fee Remittance");
			setShopifyAppCharge();

			const $lt = new Date();
			$lt.setUTCDate(1);
			$lt.setUTCHours(0, 0, 0, 0);
			const $gte = new Date($lt);
			$gte.setMonth($gte.getMonth() - 1);

			Promise.allSettled([
				API.transactions.getAll({ account: { mid: merchant._id }, createdAt: { $gte }, type: "CHARGE", description: statement.period.date }),
				API.merchants.getAppCharges(merchant._id),
			]).then(([t, sac]) => {
				setTransactions(t.value.transactions);
				setPaymentMethod(merchant.paymentMethods.default || "other");

				if (sac.status === "fulfilled") {
					const appCharge = sac.value.appCharges?.find((ac) => ac.status === "active");
					setShopifyAppCharge(appCharge);
				} else {
					setShopifyAppCharge(null);
				}
			});
		}
	}, [statement, merchant]);

	useEffect(() => {
		if (!(statement && transactions)) return;
		const totalCharges = transactions?.reduce((p, c) => p + c.amount, 0);
		const amountLeft = statement.netTotal - totalCharges;

		const cappedAmounts = [amountLeft, statement.netTotal];
		if (paymentMethod === "Shopify") cappedAmounts.push(shopifyAppCharge?.balance_remaining);

		setChargeAmount(Math.abs(Math.min(...cappedAmounts)).toFixed(2));
	}, [transactions, statement, shopifyAppCharge, paymentMethod]);

	const charge = (e) => {
		if (window.confirm(`Charge ${merchant.name} ${chargeAmount.toLocaleString("en", { style: "currency", currency: "USD" })}?`)) {
			handleCharge({ paymentMethod, chargeAmount: statement.netTotal >= 0 ? chargeAmount : -chargeAmount, description, appChargeId: shopifyAppCharge?.id }).catch((e) => setError(e.response.data));
		}
	};

	if (!(merchant && statement)) return null;
	const { paymentMethods } = merchant;
	const totalCharges = transactions?.reduce((p, c) => p + +c.amount, 0)?.toFixed(2) || "0.00";
	const amountLeft = Math.abs(statement.netTotal - +totalCharges).toFixed(2);
	const cappedAmount = Math.abs(Math.min(+amountLeft, +statement.netTotal, paymentMethod === "Shopify" ? shopifyAppCharge?.balance_remaining : Infinity));
	return (
		<Dialog open={!!(isChargeOpen && merchant)} onClose={onClose} maxWidth="sm" fullWidth>
			<TransactionsSection transactions={transactions} />
			<DialogContent>
				<Stack spacing={2}>
					{error && (
						<Typography textAlign="center" color="red">
							{error}
						</Typography>
					)}
					<DialogTitle>New {+statement.netTotal >= 0 ? "Charge" : "Payout"}</DialogTitle>
					<PaymentMethodSelect {...{ paymentMethod, paymentMethods, setPaymentMethod, shopifyAppCharge }} />

					{["Shopify", "ACH", "other"].includes(paymentMethod) && (
						<TextField
							label="Description"
							value={description || ""}
							onChange={({ target }) => setDescription(target.value)}
							InputProps={{
								endAdornment: (
									<Typography color="dimgray" textOverflow="unset" overflow="unset" noWrap>
										{statement?.period?.date}
									</Typography>
								),
							}}
						/>
					)}

					<TextField
						inputProps={{ step: 0.01, min: 0 }}
						InputProps={{
							sx: { color: chargeAmount > amountLeft && "red" },
							endAdornment: (
								<Typography variant="caption" sx={{ pl: 1, color: "black" }} noWrap textOverflow="unset" overflow="unset">
									/ {cappedAmount.toLocaleString("en", { style: "currency", currency: "USD" })}
								</Typography>
							),
						}}
						label={`Amount to ${+statement.netTotal >= 0 ? "charge" : "pay"}`}
						type="number"
						value={chargeAmount || ""}
						// disabled
						onChange={({ target }) => setChargeAmount(+target.value)}
					/>

					<Stack direction="row" justifyContent="space-between">
						<Typography>
							Full Amount: <b>{Math.abs(statement.netTotal).toLocaleString("en", { style: "currency", currency: "USD" })}</b>
						</Typography>
						<Typography>
							Remaining: <b>{amountLeft}</b>
						</Typography>
					</Stack>

					<Button disabled={!(description && chargeAmount > 0 && chargeAmount <= amountLeft)} fullWidth variant="contained" onClick={charge}>
						{+statement.netTotal >= 0 ? "Charge" : "Pay"}
					</Button>
					<Button color="warning" disabled={!amountLeft} fullWidth variant="contained" onClick={handleSkipStatement}>
						Skip this statement and carry balance forward
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function PaymentMethodSelect({ paymentMethod, paymentMethods, setPaymentMethod, shopifyAppCharge }) {
	return (
		<Select value={paymentMethod || ""} onChange={({ target }) => setPaymentMethod(target.value)}>
			{paymentMethods.cards.map((card) => (
				<MenuItem key={card.id} disabled={!paymentMethods.cards?.length} value={card.id}>
					<Stack direction="row" spacing={2} alignItems="center" width="100%">
						<CreditCard />
						<Typography>
							{card.number} - {card.name}
						</Typography>
						{paymentMethods.default === card.id && (
							<Typography flex={1} variant="caption" align="right">
								DEFAULT
							</Typography>
						)}
					</Stack>
				</MenuItem>
			))}

			{shopifyAppCharge && (
				<MenuItem value="Shopify">
					<Stack direction="row" spacing={2} alignItems="center" width="100%">
						<FaShopify fontSize={24} />
						<Typography>Shopify {"(" + (shopifyAppCharge?.balance_remaining?.toLocaleString("en", { currency: shopifyAppCharge?.currency, style: "currency" }) || "?") + " remaining)"}</Typography>
						{paymentMethods.default === "Shopify" && (
							<Typography flex={1} variant="caption" align="right">
								DEFAULT
							</Typography>
						)}
					</Stack>
				</MenuItem>
			)}

			{paymentMethods.ACH && (
				<MenuItem value="ACH">
					<Stack direction="row" spacing={2} alignItems="center" width="100%">
						<AccountBalance />

						<Typography>ACH </Typography>
						{paymentMethods.default === "ACH" && (
							<Typography flex={1} variant="caption" align="right">
								DEFAULT
							</Typography>
						)}
					</Stack>
				</MenuItem>
			)}

			<MenuItem value="other">
				<Stack direction="row" spacing={2} alignItems="center" width="100%">
					<QuestionMark />

					<Typography>Other </Typography>
					{paymentMethods.default === "other" && (
						<Typography flex={1} variant="caption" align="right">
							DEFAULT
						</Typography>
					)}
				</Stack>
			</MenuItem>
		</Select>
	);
}

function TransactionsSection({ transactions = [] }) {
	return (
		<>
			<DialogTitle>Charges</DialogTitle>

			<DialogContent>
				<List dense>
					{!transactions.length && <Typography textAlign="center">No Charges</Typography>}
					{transactions.map((c) => (
						<ListItem key={c._id}>
							<ListItemText primary={new Date(c.createdAt).toLocaleDateString()} />
							<ListItemText primary={c.description} />
							<ListItemText primary={c.provider} />
							<ListItemText primary={c.type} />
							<ListItemText primary={Number(c.amount).toLocaleString("en", { currency: "USD", style: "currency" })} />
						</ListItem>
					))}
				</List>
			</DialogContent>
		</>
	);
}
