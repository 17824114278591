import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import API from "src/API";

/**
 *
 * @param {*} param0
 * @returns
 */
export default function PDFView({ statement }) {
	const [url, setUrl] = useState();

	useEffect(() => {
		if (statement) {
			API.statements
				.get(statement._id)
				.then(({ pdf }) => {
					if (!pdf) setUrl(false);
					const buffer = Buffer.from(pdf.data);
					const blob = new Blob([buffer], { type: "application/pdf" });
					const link = URL.createObjectURL(blob);
					setUrl(link + "#toolbar=0");
				})

				.catch(console.error);
		} else setUrl();
	}, [statement]);

	if (statement && !url) {
		return (
			<Stack sx={{ position: "fixed" }} width="50vw" height="100vh" justifyContent="center" alignItems="center">
				<Typography>Select a PDF</Typography>
			</Stack>
		);
	}

	if (!url)
		return (
			<Stack sx={{ position: "fixed" }} width="50vw" height="100vh" justifyContent="center" alignItems="center">
				<Typography>Select a PDF</Typography>
			</Stack>
		);
	return <iframe title="PDF" src={url} style={{ width: "100%", height: "100%" }}></iframe>;
}
