import { Circle } from "@mui/icons-material";
import { Button, MenuItem, Stack, Card, CardHeader, CardContent, Select, TextField, List, ListItem, ListItemIcon, ListItemText, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import API from "src/API";
import CheckBox from "src/components/CheckBox";
import LoadingIndicator from "src/components/LoadingIndicator";
import View from "src/components/View";
import merchantColors from "src/components/merchantColors";
import { useAuth } from "src/providers/AuthProvider";
import useAlert from "src/utils/Alert";

export default function AutomationsView() {
	const { success, error } = useAlert();

	const { data: merchants, isLoading } = useQuery("merchants", () => API.merchants.getAll().then((r) => r.merchants));

	return (
		<View open={false} title="Reports">
			<Stack spacing={1}>
				<SyncDatabase {...{ merchants, success, error }} />
				<LoadingIndicator open={isLoading} />
				<SendStatementEmail {...{ merchants, success, error }} />
				<GetDiscounts {...{ success, error }} />

				<AutomationTaskList />
			</Stack>
		</View>
	);
}

function GetDiscounts({ success, error }) {
	const [minDate, setMinDate] = useState(new Date().toISOString().split("T")[0]);
	const [maxDate, setMaxDate] = useState(new Date().toISOString().split("T")[0]);
	const { email } = useAuth().userInfo;

	const handleExecute = () => {
		API.automations
			.getDiscounts({ minDate, maxDate, email })
			.then(() => success("Task Started"))
			.catch(error);
	};

	return (
		<Card>
			<CardHeader title="Get Discounts" />
			<CardContent>
				<Stack spacing={2} direction="row">
					<TextField label="Min. Date" type="date" onChange={({ target }) => setMinDate(target.value)} value={minDate} />

					<TextField label="Max. Date" type="date" onChange={({ target }) => setMaxDate(target.value)} value={maxDate} />

					<Button onClick={handleExecute}>Run</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}

function SyncDatabase({ merchants, success, error }) {
	const [mid, setMid] = useState("");
	const [createdAtMinDate, setCreatedAtMinDate] = useState(new Date().toISOString().split("T")[0]);
	const [createdAtMaxDate, setCreatedAtMaxDate] = useState(new Date().toISOString().split("T")[0]);
	const [updatedAtEnabled, setUpdatedAtEnabled] = useState(false);
	const [updatedAtMinDate, setUpdatedAtMinDate] = useState(new Date().toISOString().split("T")[0]);
	const [syncList, setSyncList] = useState([]);

	useEffect(() => {
		merchants && setMid(merchants[0]._id);
	}, [merchants]);

	const handleExecute = () => {
		API.automations
			.syncDatabase({ mid, createdAtMinDate, createdAtMaxDate, updatedAtMinDate: updatedAtEnabled && updatedAtMinDate })
			.then(() => success("Sync started"))
			.catch(error);
	};

	const handleSync = (mid) => {
		API.automations
			.syncDatabase({ mid, createdAtMinDate, createdAtMaxDate, updatedAtMinDate: updatedAtEnabled && updatedAtMinDate })
			.then(() => {
				success("Sync started");
				setSyncList((list) => {
					const merchant = list.find((m) => m._id === mid);
					merchant.isChecked = true;
					return [...list];
				});
			})
			.catch(error);
	};

	const handleCheck = (mid, v) => {
		setSyncList((list) => {
			const merchant = list.find((m) => m._id === mid);
			merchant.isChecked = v;
			return [...list];
		});
	};

	useEffect(() => {
		if (!merchants) return;
		setSyncList(merchants.filter((m) => !["UNINSTALLED"].includes(m.status)).map((m) => ({ _id: m._id, isChecked: false, name: m.name, status: m.status })));
	}, [merchants]);

	if (!merchants) return null;

	return (
		<Card>
			<CardHeader title="Sync Database" />
			<CardContent>
				<Stack spacing={2} direction="row">
					<TextField label="Created At Min. Date" type="date" onChange={({ target }) => setCreatedAtMinDate(target.value)} value={createdAtMinDate} />
					<TextField label="Created At Max. Date" type="date" onChange={({ target }) => setCreatedAtMaxDate(target.value)} value={createdAtMaxDate} />

					<CheckBox checked={updatedAtEnabled} onChange={setUpdatedAtEnabled}>
						<TextField disabled={!updatedAtEnabled} label="Updated At Min. Date" type="date" onChange={({ target }) => setUpdatedAtMinDate(target.value)} value={updatedAtMinDate} />
					</CheckBox>

					<Button onClick={handleExecute}>Sync</Button>
				</Stack>
				<Grid container>
					{syncList.map((m) => (
						<Grid item xs={3}>
							<Card>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<CheckBox
										onChange={(v) => handleCheck(m._id, v)}
										checked={m.isChecked}
										label={
											<Stack alignItems="center" direction="row" spacing={1}>
												<Circle style={{ color: merchantColors[m.status] }} />
												<Typography>{m.name}</Typography>
											</Stack>
										}
									/>
									<Button onClick={() => handleSync(m._id)}>Sync</Button>
								</Stack>
							</Card>
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
}

function AutomationTaskList() {
	const [automationTasks, setAutomationTasks] = useState();

	useEffect(() => {
		API.automations
			.getTasks()
			.then(({ tasks }) => setAutomationTasks(tasks))
			.catch(console.error);
	}, []);

	const colors = {
		RUNNING: "orange",
		ERROR: "red",
		SUCCESS: "green",
	};

	if (!automationTasks) return null;
	return (
		<List>
			{automationTasks.map((task) => (
				<ListItem key={task._id}>
					<ListItemIcon>
						<Circle style={{ color: colors[task.status] }} />
					</ListItemIcon>
					<ListItemText primary={task.taskName} secondary={task.duration} />
					<ListItemText primary={JSON.stringify(task, 2, 2)} />
				</ListItem>
			))}
		</List>
	);
}

function SendStatementEmail({ merchants, success, error }) {
	const [mid, setMid] = useState("");
	const [month, setMonth] = useState(new Date().toISOString().split("-").slice(0, 2).join("-"));

	useEffect(() => {
		merchants && setMid(merchants[0]._id);
	}, [merchants]);

	const handleExecute = () => {
		API.automations
			.sendStatement({ mid, month })
			.then(() => success("Statement Sent!"))
			.catch(error);
	};

	if (!merchants) return null;
	return (
		<Card>
			<CardHeader title="Send Statement Email" />
			<CardContent>
				<Stack spacing={2} direction="row">
					<Select value={mid} onChange={({ target }) => setMid(target.value)}>
						{merchants.map((m) => (
							<MenuItem key={m._id} value={m._id}>
								{m.name}
							</MenuItem>
						))}
					</Select>

					<TextField type="month" onChange={({ target }) => setMonth(target.value)} value={month} />

					<Button onClick={handleExecute}>Send</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}
