import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, MenuItem, Select, Skeleton, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { withErrorBoundary } from "@sentry/react";
import { useEffect, useState } from "react";
import { useAuth } from "src/providers/AuthProvider";
import CheckBox from "../../components/CheckBox";
import FallBackCard from "../../components/FallBackCard";
import withLoading from "../../utils/withLoading";
import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

function StatusCard({ _id, saveClaim, approveClaim, processClaim, status, subStatus, reviewers, refundCount, reorderCount, storeCreditCount, _refund, _reorder, setProcessOpen, addressVerified }) {
	const [decisionOpen, setDecisionOpen] = useState();
	const [isLoading, setLoading] = useState(false);
	const { userInfo } = useAuth();

	const processOnlyByDev = _refund || _reorder;

	const handleSubmit = () => {
		saveClaim({
			status: "REVIEWING",
			subStatus: "IN_REVIEW",
			newEvent: {
				type: "ACTION",
				subType: "CLAIM_CREATED",
			},
		});
	};

	const handleDecision = (data) => {
		setLoading(true);
		if (data.status === "APPROVED") {
			approveClaim()
				.then(() => setDecisionOpen(false))
				.catch(console.error)
				.finally(() => setLoading(false));
		} else {
			saveClaim({
				status: data.status,
				subStatus: data.subStatus,
				newEvent: {
					type: "ACTION",
					subType: `CLAIM_${data.status}`,
					content: data.content,
				},
			})
				.then(() => setDecisionOpen(false))
				.catch(console.error)
				.finally(() => setLoading(false));
		}
	};

	const handleReopen = () => {
		saveClaim({
			status: "REVIEWING",
			subStatus: "IN_REVIEW",
			newEvent: {
				type: "ACTION",
				subType: "CLAIM_REOPENED",
			},
		});
	};

	const handleProcessClick = () => {
		// FINISH AUTO PROCESSING
		processClaim(_id, userInfo.displayName);
	};

	return (
		<Card>
			<CardContent>
				<Stack spacing={2}>
					<RenderStatusAction
						{...{
							status,
							subStatus,
							handleSubmit,
							setDecisionOpen,
							handleReopen,
							processOnlyByDev,
							isDev: userInfo.roles.includes("DEV"),
							isOwner: userInfo.roles.includes("OWNER"),
							saveClaim,
							handleProcessClick,
							reviewers,
							setProcessOpen,
						}}
					/>
				</Stack>
			</CardContent>
			<DecisionDialog {...{ open: decisionOpen, onClose: () => setDecisionOpen(), handleDecision, refundCount, reorderCount, storeCreditCount, addressVerified, isLoading }} />
		</Card>
	);
}

function RenderStatusAction({ status, subStatus, handleSubmit, setDecisionOpen, handleReopen, processOnlyByDev, isDev, isOwner, handleProcessClick, saveClaim, reviewers, setProcessOpen }) {
	if (status === "DRAFT") return <Button onClick={handleSubmit}>SUBMIT</Button>;
	if (status === "REVIEWING") return <Button onClick={() => setDecisionOpen(true)}>Approve / Deny / Close</Button>;
	if (status === "CLOSED" || status === "DENIED") return <Button onClick={handleReopen}>REOPEN</Button>;

	if (status === "APPROVED") {
		// FINISH THE AUTOMATION
		if (subStatus === "PROCESSING-DEV1") {
			if (isDev) return <Button onClick={handleProcessClick}>Finish Process Claim</Button>;
			return (
				<Typography textAlign="center" variant="h1" color="red">
					DO NOT PROCESS
				</Typography>
			);
		} else if (subStatus === "PROCESSING-DEV2") {
			// FOR GREG
			if (isOwner || isDev) return <Button onClick={() => setProcessOpen(true)}>Manually Process</Button>;
			return (
				<Typography textAlign="center" variant="h1" color="red">
					DO NOT PROCESS
				</Typography>
			);
		} else if (subStatus === "PROCESSING") {
			// FOR AGENTS
			return <Button onClick={() => setProcessOpen(true)}>Manually Process</Button>;
		}
		if (subStatus === "PENDING") return <Review {...{ saveClaim, reviewers }} />;
		if (subStatus === "PROCESSED") return <Processed />;
	}
	return "REPORT THIS IN SLACK";
}

function Processed() {
	return (
		<Stack direction="row" spacing={2}>
			<Check color="success" />
			<Typography>Processed</Typography>
		</Stack>
	);
}

function DecisionDialog({ open, onClose, handleDecision, refundCount, reorderCount, storeCreditCount, addressVerified, isLoading }) {
	const [decision, setDecision] = useState("");
	const [subStatus, setSubStatus] = useState("");
	const [content, setContent] = useState(undefined);

	const handleSubmit = () => {
		handleDecision({ status: decision, subStatus, content });
	};

	useEffect(() => {
		if (open) {
			setDecision("");
			setSubStatus("");
		}
	}, [open]);

	useEffect(() => {
		if (decision === "APPROVED") {
			setSubStatus("PROCESSING");
		} else {
			setSubStatus();
		}
	}, [decision]);

	useEffect(() => {
		if (subStatus !== "OTHER") {
			setContent(undefined);
		}
	}, [subStatus]);

	let submitDisabled = true;
	if (decision === "APPROVED") {
		submitDisabled = false;
	} else if (subStatus === "OTHER" && content) {
		submitDisabled = false;
	} else if (subStatus && subStatus !== "OTHER") {
		submitDisabled = false;
	}

	return (
		<Dialog fullWidth open={!!open} onClose={onClose}>
			<DialogTitle>Claim Decision</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Typography color="error" display={!((refundCount || reorderCount || storeCreditCount) && addressVerified) ? "initial" : "none"}>
						You must verify the customer's address and update the item counts for either Refund, Reorder, or Store Credit before approving this claim.
					</Typography>
					<ToggleButtonGroup fullWidth exclusive value={decision} onChange={(e, v) => setDecision(v)}>
						<ToggleButton color="success" value="APPROVED" disabled={!((refundCount || reorderCount || storeCreditCount) && addressVerified)}>
							Approve
						</ToggleButton>
						<ToggleButton color="info" value="CLOSED">
							Close
						</ToggleButton>
						<ToggleButton color="error" value="DENIED">
							Deny
						</ToggleButton>
					</ToggleButtonGroup>

					{decision === "DENIED" && (
						<Select value={subStatus || 0} onChange={({ target }) => setSubStatus(target.value)}>
							<MenuItem value={0} disabled>
								Denied Reason
							</MenuItem>
							<MenuItem value="Incorrect Address">Incorrect Address</MenuItem>
							<MenuItem value="Outside Claim Window">Outside Claim Window</MenuItem>
							<MenuItem value="Held in Customs">Held in Customs</MenuItem>
							<MenuItem value="OTHER">Other - See Details</MenuItem>
						</Select>
					)}

					{decision === "CLOSED" && (
						<Select value={subStatus || 0} onChange={({ target }) => setSubStatus(target.value)}>
							<MenuItem value={0} disabled>
								Closed Reason
							</MenuItem>
							<MenuItem value="CUSTOMER_INACTIVITY">Customer Inactivity</MenuItem>
							<MenuItem value="DELIVERED">Delivered</MenuItem>
							<MenuItem value="DUPLICATE">Duplicate</MenuItem>
							<MenuItem value="UNFULFILLED">Unfulfilled</MenuItem>
							<MenuItem value="OTHER">Other - See Details</MenuItem>
						</Select>
					)}

					{subStatus === "OTHER" && <TextField multiline rows={3} label="Details" value={content} onChange={({ target }) => setContent(target.value)} />}

					<LoadingButton variant="contained" loading={isLoading} disabled={submitDisabled} fullWidth onClick={handleSubmit}>
						Submit
					</LoadingButton>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function Review({ saveClaim, reviewers }) {
	const { userInfo } = useAuth();

	const handleCheck = (r) => () => {
		if (window.confirm("THIS CAN NOT BE REVERTED!\nMake sure everything is perfect on this claim.\nIf there is an error reach out to #claimsTeam slack channel first.")) {
			r.reviewed = true;
			saveClaim({
				reviewers,
				newEvent: {
					type: "ACTION",
					subType: "REVIEWED",
				},
			});
		}
	};

	return (
		<Stack>
			<CardHeader title="Internal Review" />
			{reviewers?.map((r) => (
				<CheckBox key={r.uid} disabled={userInfo.uid !== r.uid || r.reviewed} label={r.name} checked={r.reviewed} onChange={handleCheck(r)} />
			))}
		</Stack>
	);
}

function Loading() {
	return (
		<Card>
			<CardContent>
				<Stack spacing={2}>
					<Stack spacing={1} direction="row">
						<Skeleton variant="circular" width={75} height={60} />
						<Skeleton width={150} />
						<Skeleton width="100%" />
						<Skeleton width={100} />
					</Stack>
					{Array(3)
						.fill(0)
						.map((x, i) => (
							<Stack key={i} direction="row" justifyContent="space-between">
								<Stack spacing={1} direction="row">
									<Skeleton variant="circular" sx={{ ml: 2 }} width={24} height={24} />
									<Skeleton width={Math.random() * 400 + 100} />
								</Stack>

								<Skeleton width={150} />
							</Stack>
						))}
				</Stack>
			</CardContent>
		</Card>
	);
}

export default withErrorBoundary(withLoading(StatusCard, Loading), { fallback: FallBackCard("", "An error occurred") });
