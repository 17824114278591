import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import API from "src/API";

const claimsContext = createContext();

export default function ClaimLayout() {
	const claimsHook = useProvideClaims();

	return (
		<claimsContext.Provider value={claimsHook}>
			<Outlet />
		</claimsContext.Provider>
	);
}

export function useClaims() {
	return useContext(claimsContext);
}

function useProvideClaims() {
	const [isLoading, setLoading] = useState(true);
	const [isMutated, setMutated] = useState(false);
	const [claim, setClaim] = useState();
	const [claims, setClaims] = useState();
	const cursorRef = useRef({});
	const { id } = useParams();

	useEffect(() => {
		API.claims
			.getAll()
			.then((claims) => setClaims(claims))
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		if (claims) {
			const claim = claims.find((m) => m._id === id);
			if (claim) {
				setMutated(true);
				setClaim(claim);
			}
		} else if (id) {
			API.claims
				.get(id)
				.then(setClaim)
				.catch(console.error)
				.finally(() => setLoading(false));
		}
	}, [id, claims]);

	const save = async (data = {}) => {
		if (data) {
			setClaim((m) => ({ ...m, ...data }));
			setMutated(false);
		}
		return API.claims.update(claim._id, { ...claim, ...data }).then((claim) => {
			setClaims((claims = []) => {
				const matched = claims?.find((c) => c._id === claim._id);
				if (matched) {
					Object.assign(matched, claim);
				}
				return [...claims];
			});
		});
	};

	const set = (f) => {
		if (typeof f === "function") {
			const m = f(claim);
			setClaim(m);
		} else {
			setClaim((m) => m);
		}
		setMutated(true);
	};

	const [nextClaimId, prevClaimId] = useMemo(() => {
		if (!claims || !id) return [null, null];
		const index = claims.findIndex((c) => c._id === id);
		const nextClaimId = claims[index + 1]?._id;
		const prevClaimId = claims[index - 1]?._id;
		return [nextClaimId, prevClaimId];
	}, [claims, id]);

	const processClaim = useCallback((_id, displayName) => {
		setLoading(true);
		return API.claims
			.process(_id, displayName)
			.then(({ claim }) => {
				setClaim((c) => {
					Object.assign(c, claim);
					return { ...c };
				});

				setClaims((claims) => {
					const found = claims.find((c) => c._id === _id);
					if (found) {
						Object.assign(found, claim);
						return [...claims];
					} else return claims;
				});
			})
			.finally(() => setLoading(false));
	}, []);

	const manualProcess = useCallback((_id, data) => {
		setLoading(true);
		return API.claims
			.manualProcess(_id, data)
			.then(({ claim }) => {
				setClaim((c) => {
					Object.assign(c, claim);
				});
				setClaims((claims) => {
					const found = claims.find((c) => c._id === _id);
					if (found) {
						Object.assign(found, claim);
						return [...claims];
					} else return claims;
				});
			})
			.finally(() => setLoading(false));
	}, []);

	const queryClaims = useCallback((query) => {
		setLoading(true);
		if (query.cursor) {
			query.cursor = cursorRef.current[query.cursor];
		}
		API.claims
			.query(query)
			.then(({ claims, next, prev }) => {
				setClaims(claims);
				cursorRef.current = { prev, next };
			})
			.finally(() => setLoading(false));
	}, []);

	return { claim, claims, isLoading, set, setClaim, isMutated, save, nextClaimId, prevClaimId, processClaim, manualProcess, queryClaims };
}
