import { Stack, Grid, Typography, CardContent, Card, CardHeader, useMediaQuery, CircularProgress } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import MuiImage from "mui-image";
import View from "src/components/View";
import { listFiles } from "../../firebase";
import { ExpandMore, ChevronRight, FolderOutlined, Image, LockRounded } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { SiJavascript } from "react-icons/si";

const CdnView = () => {
	const [tree, setTree] = useState([
		{
			name: "logos",
			folders: [
				{
					name: "merchants",
				},
			],
		},
		{
			name: "statements",
		},
		{
			name: "widget",
			folders: [
				{
					name: "sfw",
				},
				{
					name: "cow",
				},
			],
		},
	]);
	const [selectedFile, setSelectedFile] = useState();
	const isSm = useMediaQuery((t) => t.breakpoints.down("md"));

	const handleSelect = (e, path) => {
		const segments = path.split("/");

		const file = segments.reduce((p, c) => {
			const item = p.find((b) => b.fullPath === path || b.name === c);

			const items = [...(item.children || []), ...(item.folders || [])];
			return items.length ? items : item;
		}, tree);

		if (file?.fullPath) setSelectedFile(file);
	};

	return (
		<View title="CDN" sx={{ height: "100%", overflowY: "hidden" }}>
			<Card sx={{ height: "100%", mb: 2 }}>
				<CardHeader title="CDN" />
				<CardContent sx={{ height: "calc(100% - 132px)" }}>
					<Grid container flexDirection={!isSm ? "row-reverse" : "row"} height="100%">
						<Grid item xs={12} md={6} height={isSm ? 100 : 500}>
							<Preview {...{ selectedFile }} />
						</Grid>
						<Grid item xs={12} md={6} sx={{ overflowY: "auto", height: "100%" }}>
							<TreeView onNodeFocus={handleSelect}>
								{tree.map((dir) => (
									<Directory key={dir.name} path={dir.name} setSelectedFile={setSelectedFile} setTree={setTree} folders={dir.folders}>
										{dir.children}
									</Directory>
								))}
							</TreeView>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</View>
	);
};

export default CdnView;

function Preview({ selectedFile }) {
	const [src, setSrc] = useState();
	const [type, setType] = useState();

	useEffect(() => {
		if (!selectedFile) return;

		selectedFile.getMetadata().then((r) => {
			setType(r.contentType);
			if (!/text/.test(r.contentType)) {
				selectedFile.getDownloadURL().then(setSrc);
			} else setSrc();
		});
	}, [selectedFile]);

	return (
		<CardContent sx={{ width: "100%", height: "100%" }}>
			{type === "text/javascript" && (
				<Stack width="100%" height="100%" justifyContent="center" alignItems="center">
					<SiJavascript style={{ fontSize: 100 }} />
				</Stack>
			)}
			{src && <MuiImage src={src} style={{ objectFit: "contain" }} />}
		</CardContent>
	);
}

function Directory({ path, children = [], setSelectedFile, setTree, folders = [] }) {
	const [status, setStatus] = useState("loading");
	const setFiles = useCallback(
		(files) => {
			setTree((tree) => {
				const folderName = path.match(/\/(.*)/)?.[1] || path;
				const branch = tree.find((b) => b.name === folderName);

				if (typeof files === "function") {
					files([...(branch.children || []), ...(branch.folders || [])]);
				} else {
					branch.children = files;
				}
				return [...tree];
			});
		},
		[path, setTree]
	);

	useEffect(() => {
		if (!path) return;
		listFiles(path)
			.then((files) => {
				setStatus("ready");
				setFiles(files);
			})
			.catch((e) => {
				if (e.code === "storage/unauthorized") {
					setStatus("unauthorized");
				}
			});
	}, [path, setFiles]);

	const folderName = path.match(/\/(.*)/)?.[1] || path;

	return (
		<TreeItem
			nodeId={path}
			disabled={["loading", "unauthorized"].includes(status)}
			icon={icons[status]}
			label={
				<Stack spacing={1} alignItems="center" direction="row">
					<FolderOutlined />
					<Typography>{folderName}</Typography>
				</Stack>
			}
			collapseIcon={<ExpandMore />}
			expandIcon={<ChevronRight />}
		>
			{folders.map((folder) => (
				<Directory key={folder.name} path={path + "/" + folder.name} setSelectedFile={setSelectedFile} setTree={setFiles} folders={folder.folders} children={folder.children} />
			))}
			{children.map((item, i) => {
				return <TreeItem key={item.fullPath} icon={icons[item.name.match(/\.(.*)/g)?.[0]]} nodeId={item.fullPath} label={item.name} {...item}></TreeItem>;
			})}
		</TreeItem>
	);
}

const icons = {
	".min.js": <SiJavascript />,
	".png": <Image />,
	unauthorized: <LockRounded />,
	loading: <CircularProgress size={14} />,
};
