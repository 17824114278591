import {
	Stack,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Skeleton,
	TextField,
	Card,
	CardHeader,
	CardContent,
	Grid,
	List,
	ListItem,
	Checkbox,
	IconButton,
	Box,
	Avatar,
	Typography,
	ListItemAvatar,
	ListItemText,
	Collapse,
	ListItemSecondaryAction,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import API from "src/API";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import View from "src/components/View";
import MerchantPlan from "src/components/merchant/MerchantPlan";
import { ChevronDown, ChevronUp, Delete, Trash2 } from "react-feather";
import { Save } from "@mui/icons-material";

const OnboardView = () => {
	const [isLoading, setLoading] = useState(true);
	const { merchant, set: setMerchant, save } = useMerchant();
	const [menu, setMenu] = useState();
	const [salesUsers, setSalesUsers] = useState();
	const { mid } = useParams();

	useEffect(() => {
		Promise.all([API.merchants.getOnboarding(mid), API.sales.getUsers()]).then(([menu, users]) => {
			setMenu(menu);
			setSalesUsers(users);
		});
	}, [mid]);

	useEffect(() => {
		if (merchant) {
			setLoading(false);
		}
	}, [merchant]);

	const href = useMemo(() => {
		const sp = new URLSearchParams({
			store_type: "managed_store",
			store_domain: merchant?.domain?.replace(".myshopify.com", ""),
			permissions: ["dashboard"],
		});

		return "https://partners.shopify.com/1788855/stores/new?" + sp.toString();
	}, [merchant]);

	const handleSave = (data) => {
		setLoading(true);
		save(data).finally(setLoading);
	};

	const sales = salesUsers?.map((user) => ({ ...user.sales.find((s) => s.mid === mid), user }))?.filter((s) => s.mid);

	const handleAddSales = (data) => {
		const user = salesUsers.find((u) => u.uid === data.uid);

		user.sales.push({
			commissionPercentage: data.commission,
			startMonth: data.startMonth,
			endMonth: data.endMonth,
			mid,
		});

		API.sales
			.updateUser(user.uid, { sales: user.sales })
			.then((res) => {
				setSalesUsers((users) => {
					Object.assign(user, res.user);
					return [...users];
				});
			})
			.catch(console.error);
	};

	const handleDeleteSales = (uid) => {
		const user = salesUsers.find((u) => u.uid === uid);
		if (window.confirm(`Remove ${user.displayName} from ${merchant.name}'s sales commission?`)) {
			user.sales = user.sales.filter((s) => s.mid !== mid);

			API.sales
				.updateUser(user.uid, { sales: user.sales })
				.then((res) => {
					setSalesUsers((users) => {
						Object.assign(user, res.user);
						return [...users];
					});
				})
				.catch(console.error);
		}
	};

	if (!menu || !merchant || !salesUsers) return null;

	return (
		<View.Merchant title="Merchant" isLoading={isLoading}>
			<Grid container columnSpacing={2}>
				<Grid container item xs={12} md={9} rowSpacing={2} direction="column">
					<Grid item>
						<MerchantPlan {...{ merchant, saveMerchant: handleSave }} />
					</Grid>
					<Grid item>
						<TasksCard {...{ tasks: merchant?.tasks || [], setMerchant, save: handleSave }} />
					</Grid>
				</Grid>

				<Grid container item xs={12} md={3} direction="column" rowSpacing={2}>
					<Grid item>
						<Button fullWidth href={href} target="_blank">
							Start Partner Request
						</Button>
					</Grid>
					<Grid item>
						<MerchantSuccessSelect {...{ merchant, msms: menu?.msms, setMerchant }} />
					</Grid>
					<Grid item>
						<SalesUserSelect {...{ salesUsers, sales, handleAddSales, handleDeleteSales }} />
					</Grid>
					<Grid item>
						<MerchantNotes {...{ merchant, handleSave }} />
					</Grid>
				</Grid>
			</Grid>
		</View.Merchant>
	);
};

export default OnboardView;

function MerchantSuccessSelect({ merchant, msms, setMerchant, handleSave }) {
	if (!merchant) return <Skeleton />;
	const handleChange = ({ target }) => {
		const msm = msms.find((m) => m._id === target.value);
		setMerchant((m) => ({ ...m, msm }));
	};
	return (
		<Card>
			<CardHeader title="Merchant Success Manager" />
			<CardContent>
				<FormControl sx={{ m: 1, minWidth: 120 }}>
					<InputLabel id="msm-select-label">MSM</InputLabel>
					<Select value={merchant.msm?._id || ""} onChange={handleChange} labelId="msm-select-label">
						{msms?.map((m) => (
							<MenuItem key={m._id} value={m._id}>
								{m.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</CardContent>
		</Card>
	);
}

function SalesUserSelect({ salesUsers, sales = [], handleAddSales, handleDeleteSales }) {
	if (!salesUsers) return <Skeleton />;

	return (
		<Card>
			<CardHeader title="Sales" />

			<CardContent>
				<Stack>
					<List>
						{sales.map((s) => (
							<ListItem key={s.user._id}>
								<ListItemAvatar>
									<Avatar src={s.user.photoURL}>{s.user.displayName.split(" ").map((c) => c[0])}</Avatar>
								</ListItemAvatar>
								<ListItemText primary={`${s.user.displayName} ${s.commissionPercentage}%`} secondary={s.startMonth + " - " + s.endMonth} />
								<ListItemSecondaryAction>
									<IconButton onClick={() => handleDeleteSales(s.user._id)}>
										<Trash2 color="red" />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>

					<AddSalesUser {...{ salesUsers: salesUsers.filter((u) => !sales.some((s) => s.user.uid === u.uid)), onAdd: handleAddSales }} />
				</Stack>
			</CardContent>
		</Card>
	);
}

function AddSalesUser({ salesUsers, onAdd }) {
	const [open, setOpen] = useState(false);
	const [uid, setUid] = useState("");
	const [commission, setCommission] = useState(10);
	const [startMonth, setStartMonth] = useState("");
	const [endMonth, setEndMonth] = useState("");

	const handleAdd = () => {
		onAdd({
			uid,
			commission,
			startMonth,
			endMonth,
		});
		setUid("");
		setCommission(10);
		setStartMonth("");
		setEndMonth("");
		setOpen(false);
	};

	useEffect(() => {
		const initMonth = new Date();
		setStartMonth(initMonth.toISOString().slice(0, 7));
		initMonth.setUTCFullYear(initMonth.getUTCFullYear() + 1);
		setEndMonth(initMonth.toISOString().slice(0, 7));
	}, [salesUsers]);

	return (
		<Stack>
			<MenuItem onClick={() => setOpen((t) => !t)}>
				<Stack width="100%" direction="row" justifyContent="space-between">
					<Typography>Add Sales Commission</Typography>
					{open ? <ChevronUp /> : <ChevronDown />}
				</Stack>
			</MenuItem>
			<Collapse in={open}>
				<Stack spacing={1}>
					<Stack direction="row" spacing={1}>
						<Select fullWidth value={uid} onChange={({ target }) => setUid(target.value)}>
							{salesUsers?.map((user) => (
								<MenuItem key={user.uid} value={user.uid}>
									<Stack direction="row" spacing={2}>
										<Avatar sx={{ width: 24, height: 24, fontSize: 12 }} src={user.photoURL}>
											{user.displayName.split(" ").map((c) => c[0])}
										</Avatar>
										<Typography>{user.displayName}</Typography>
									</Stack>
								</MenuItem>
							))}
						</Select>
						<TextField type="number" label="Commission" fullWidth value={commission} onChange={({ target }) => setCommission(+target.value)} />
					</Stack>
					<Stack direction="row" spacing={1}>
						<TextField label="Start Month" fullWidth type="month" value={startMonth} onChange={({ target }) => setStartMonth(target.value)} />
						<TextField label="End Month" fullWidth type="month" value={endMonth} onChange={({ target }) => setEndMonth(target.value)} />
					</Stack>
					<Button onClick={handleAdd}>ADD</Button>
				</Stack>
			</Collapse>
		</Stack>
	);
}

function MerchantNotes({ merchant, handleSave }) {
	const [onboardingNotes, setOnboardingNotes] = useState(merchant.onboardingNotes || "");

	const handleSaveClick = () => {
		handleSave({ onboardingNotes });
	};

	return (
		<Card>
			<CardHeader title="Onboarding Notes" />
			<CardContent>
				<Stack spacing={2}>
					<TextField
						label="Notes"
						multiline
						minRows={5}
						value={onboardingNotes}
						onChange={({ target }) => setOnboardingNotes(target.value)}
						placeholder="Add additional info like onboarding/intergration, etc"
					/>
					<Button fullWidth disabled={!onboardingNotes.length || onboardingNotes === merchant.onboardingNotes} onClick={handleSaveClick}>
						Save
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}

function TasksCard({ tasks, setMerchant, save }) {
	const handleChange = (task, update) => {
		setMerchant((m) => {
			Object.assign(task, update);
			return { ...m };
		});
	};

	const handleDeleteTask = (task) => {
		setMerchant((m) => {
			m.tasks = m.tasks.filter((t) => t !== task);
			return { ...m };
		});
	};

	return (
		<Card sx={{ position: "relative" }}>
			<CardHeader title="Tasks" />
			<Box sx={{ position: "absolute", top: 10, right: 10 }}>
				<IconButton onClick={save}>
					<Save />
				</IconButton>
			</Box>
			<CardContent>
				<List>
					{tasks.map((t, i) => (
						<ListItem key={i}>
							<Card sx={{ width: "100%", p: 2 }}>
								<Stack direction="row" spacing={2} justifyContent="space-between">
									<Checkbox checked={t.done} onChange={(e, done) => handleChange(t, { done })} />
									{t.expiresAt < Date.now() ? (
										"EXPIRED"
									) : (
										<Button variant="text" onClick={() => handleChange(t, { enabled: !t.enabled })}>
											{t.enabled ? "enabled" : "disabled"}
										</Button>
									)}
									<TextField label="ID" variant="standard" value={t.id} onChange={({ target }) => handleChange(t, { id: target.value })} />
									<TextField label="Label" variant="standard" value={t.label} onChange={({ target }) => handleChange(t, { label: target.value })} />
									<TextField label="Action Label" variant="standard" value={t.actionLabel} onChange={({ target }) => handleChange(t, { actionLabel: target.value })} />
									<Select label="Action Type" variant="standard" value={t.action} onChange={({ target }) => handleChange(t, { action: target.value })}>
										<MenuItem value="link">Link</MenuItem>
										<MenuItem value="calendly">Calendly</MenuItem>
										<MenuItem value="popup">PopUp</MenuItem>
									</Select>
									{["calendly", "link"].includes(t.action) && <TextField label="URL" variant="standard" value={t.url} onChange={({ target }) => handleChange(t, { url: target.value })} />}
									{t.action === "popup" && <TextField label="Popup Text" multiline variant="standard" value={t.popupText} onChange={({ target }) => handleChange(t, { popupText: target.value })} />}
									<IconButton onClick={() => handleDeleteTask(t)}>
										<Delete />
									</IconButton>
								</Stack>
							</Card>
						</ListItem>
					))}
					<ListItem>
						<Card sx={{ width: "100%", p: 2 }}>
							<Button>Add Task</Button>
						</Card>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
}
