import { Button, MenuItem, Stack, Select, TextField, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";

export default function EditAdjustmentDialog({ selected, onClose, update, deleteAdjustment, merchants }) {
	const [adjustment, setAdjustment] = useState(selected);

	useEffect(() => {
		if (selected) {
			setAdjustment(selected);
		} else {
			setAdjustment({});
		}
	}, [selected]);

	const handleSubmit = () => {
		update(adjustment);
	};

	if (!adjustment || !merchants || !selected) return null;

	return (
		<Dialog open={!!selected} onClose={onClose}>
			<DialogTitle>Edit Adjustment</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Select value={adjustment?.mid || "default"} onChange={({ target }) => setAdjustment((a) => ({ ...a, mid: target.value }))}>
						<MenuItem value={"default"} disabled>
							Select Merchant
						</MenuItem>
						{merchants.map((m, i) => {
							return (
								<MenuItem value={m._id} key={i}>
									{m.name}
								</MenuItem>
							);
						})}
					</Select>

					<TextField type="month" value={adjustment?.month} label="Billing Month" onChange={({ target }) => setAdjustment((a) => ({ ...a, month: target.value }))} />
					<TextField value={adjustment?.amount} label="Amount" onChange={({ target }) => setAdjustment((a) => ({ ...a, amount: target.value }))} />
					<TextField multiline minRows={3} value={adjustment?.description} label="Description" onChange={({ target }) => setAdjustment((a) => ({ ...a, description: target.value }))} />
					<Button onClick={handleSubmit} disabled={!(adjustment?.amount && adjustment?.description && adjustment?.month) || adjustment?.amount === "0.00"}>
						Submit
					</Button>
					<Button color="error" onClick={() => deleteAdjustment(selected._id)}>
						Delete
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
