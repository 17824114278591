import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Badge, Box, Grid, Hidden, IconButton, List, ListItemButton, Menu, Slide, Stack, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import Logo from "../../components/Logo";
import { useNotifications } from "src/providers/NotificationProvider";
import { Assignment, Info } from "@mui/icons-material";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
	return (
		<AppBar elevation={0} {...rest}>
			<Toolbar>
				<RouterLink to="/">
					<Logo style={{ height: 40 }} />
				</RouterLink>
				<Box sx={{ flexGrow: 1 }} />

				<Notifications />

				<Hidden lgUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

DashboardNavbar.propTypes = {
	onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;

function Notifications() {
	const { notifications, markAsRead } = useNotifications();
	const [filtered, setFiltered] = useState([]);
	const [tab, setTab] = useState(0);
	const [open, setOpen] = useState(false);
	const anchorRef = useRef();
	const nav = useNavigate();

	const newCount = notifications.filter((n) => !n.isRead).length;

	const handleClick = (n) => {
		// MARK AS READ
		if (n.type !== "CLAIM") {
			markAsRead(n._id);
		}

		if (n.link) {
			nav(n.link);
			setOpen(false);
		}
	};

	useEffect(() => {
		setFiltered(tab ? notifications : notifications.filter((n) => !n.isRead));
	}, [tab, notifications]);

	return (
		<>
			<IconButton ref={anchorRef} color="inherit" onClick={() => setOpen(true)}>
				<Badge badgeContent={newCount} color="error">
					<NotificationsIcon />
				</Badge>
			</IconButton>
			<Menu TransitionComponent={Slide} sx={{ my: 3 }} TransitionProps={{ direction: "left" }} open={open} onClose={() => setOpen(false)} anchorEl={() => anchorRef.current}>
				<Box width={400} maxHeight={800}>
					<Tabs value={tab} variant="fullWidth" onChange={(e, v) => setTab(v)}>
						<Tab value={0} label="New" />
						<Tab value={1} label="All" />
					</Tabs>
					<List sx={{ width: "100%", height: "100%" }}>
						{!filtered.length && <Typography textAlign="center">Nothing new here</Typography>}
						{filtered.map((n) => (
							<ListItemButton onClick={() => handleClick(n)} dense divider key={n._id} sx={{ bgcolor: !n.isRead && "blanchedalmond" }}>
								<Grid container>
									<Grid item xs={1}>
										{icons[n.type]}
									</Grid>
									<Grid container item xs>
										<Stack width="100%">
											<Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
												<Typography>
													<b>{n.title}</b>
												</Typography>
												<Typography variant="caption">{formatElapsed(n.createdAt)}</Typography>
											</Stack>
											<Typography variant="caption">{n.description}</Typography>
										</Stack>
									</Grid>
								</Grid>
							</ListItemButton>
						))}
					</List>
				</Box>
			</Menu>
		</>
	);
}

const icons = {
	CLAIM: <Assignment />,
	undefined: <Info />,
};

function formatElapsed(ts) {
	return new Date(ts).toLocaleString();
}
