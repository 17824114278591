import { Stack, Button, MenuItem, TextField, Select, Typography, Grid, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import * as Icons from "@mui/icons-material";
import View from "src/components/View";
import { Card, CardContent, CardHeader } from "@mui/material";
import useEdit from "src/useEdit";
import { Slack } from "react-feather";
import API from "src/API";

const ClaimPoliciesView = () => {
	const { merchant, setMerchant } = useMerchant();

	const updateClaimPolicies = (claimPolicies) => {
		API.merchants.updateClaimPolicies(merchant?._id, claimPolicies).then(({ merchant }) => setMerchant(merchant));
	};

	const [data, setData, { CardAction, isEditing }] = useEdit(merchant?.claimPolicies || INIT_DATA, (claimPolicies) => updateClaimPolicies(claimPolicies));

	const handleAddContact = () => {
		setData((d) => ({ ...d, contacts: [...(d.contacts || []), { type: "EMAIL", value: "" }] }));
	};

	const handleContactTypeChange =
		(contact) =>
		({ target }) => {
			contact.type = target.value;
			setData((d) => ({
				...d,
				contacts: [...d.contacts],
			}));
		};

	const handleContactValueChange =
		(contact) =>
		({ target }) => {
			contact.value = target.value;
			setData((d) => ({
				...d,
				contacts: [...d.contacts],
			}));
		};

	return (
		<View.Merchant title="Merchant">
			<Card>
				<CardHeader action={CardAction} />
				<Grid container>
					<Grid item xs={12} md={6}>
						<CardHeader title="Claim Actions" />
						<CardContent>
							<Stack spacing={1}>
								<Option label="Gift Cards" value={data.giftCards} disabled={!isEditing} onChange={(v) => setData((d) => ({ ...d, giftCards: v }))} />
								<Option label="Customer Service" value={data.customerService} disabled={!isEditing} onChange={(v) => setData((d) => ({ ...d, customerService: v }))} />
								<Option label="Reorders" value={data.reorders} disabled={!isEditing} onChange={(v) => setData((d) => ({ ...d, reorders: v }))} />
								<Option label="Refunds" value={data.refunds} disabled={!isEditing} onChange={(v) => setData((d) => ({ ...d, refunds: v }))} />
							</Stack>
						</CardContent>
					</Grid>

					<Grid item xs={12} md={6}>
						<CardHeader title="Contact Info" />
						<CardContent>
							{data?.contacts?.map((contact, i) => (
								<Stack direction="row" key={i} spacing={1}>
									<Select value={contact.type} onChange={handleContactTypeChange(contact)} disabled={!isEditing}>
										<MenuItem value={"SLACK"}>
											<Stack direction="row" spacing={1} alignItems="center">
												<Slack />
												<Typography>Slack</Typography>
											</Stack>
										</MenuItem>
										<MenuItem value={"EMAIL"}>
											<Stack direction="row" spacing={1} alignItems="center">
												<Icons.Email />
												<Typography>Email</Typography>
											</Stack>
										</MenuItem>
										<MenuItem value={"OTHER"}>Other</MenuItem>
									</Select>
									<TextField value={contact.value} onChange={handleContactValueChange(contact)} disabled={!isEditing} />
								</Stack>
							))}
							<Button sx={{ mt: 2, display: !isEditing && "none" }} onClick={handleAddContact}>
								Add Contact
							</Button>
						</CardContent>
					</Grid>

					<Grid item xs={12}>
						<CardHeader title="General Notes" />
						<CardContent>
							<TextField disabled={!isEditing} fullWidth multiline minRows={5} value={data?.notes || ""} onChange={({ target }) => setData((d) => ({ ...d, notes: target.value }))} />
						</CardContent>
					</Grid>
				</Grid>
			</Card>
		</View.Merchant>
	);
};

export default ClaimPoliciesView;

function Option({ label, disabled, value, onChange }) {
	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<ToggleButtonGroup exclusive value={+value} size="small" onChange={(e, v) => onChange(v)} disabled={disabled}>
				<ToggleButton color="error" value={0}>
					No
				</ToggleButton>
				<ToggleButton color="success" value={1}>
					Yes
				</ToggleButton>
				<ToggleButton color="info" value={2}>
					Special
				</ToggleButton>
			</ToggleButtonGroup>
			<Typography>
				<b>{label}</b>
			</Typography>
		</Stack>
	);
}

const INIT_DATA = {
	notes: "",
	contacts: [],
	giftCards: true,
	customerService: true,
	reorders: true,
	refunds: true,
};
