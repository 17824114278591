import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import API from "src/API";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import LoadingIndicator from "../components/LoadingIndicator";
import { useAuth } from "src/providers/AuthProvider";
import { useNavigate, useParams } from "react-router";

const TosView = () => {
	const [isLoading, setLoading] = useState(false);
	const [tos, setTos] = useState([]);
	const { userInfo } = useAuth();
	const nav = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (API.terms_of_service) {
			setLoading(true);
			API.terms_of_service
				.get(id)
				.then(setTos)
				.catch(console.error)
				.finally(() => setLoading(false));
		}
	}, [id]);

	const handleSave = () => {
		setLoading(true);
		API.terms_of_service
			.update(id, tos)
			.then(() => {
				setTos((tos) => {
					tos.mutated = false;
					return Object.assign(tos);
				});
			})
			.finally(() => setLoading(false));
	};

	const handlePublish = () => {
		if (window.confirm("Are you sure you want to publish this version?")) {
			API.terms_of_service.publish(id, tos).then(() => {
				setTos((tos) => {
					tos.published = true;
					return { ...tos };
				});
			});
		}
	};

	return tos ? (
		<Box p={2}>
			<Stack spacing={2}>
				<Grid container>
					<Grid xs item>
						<Typography>
							{new Date(tos.createdAt).toLocaleString()} by <b>{userInfo.displayName || "Unknown"}</b>
						</Typography>
					</Grid>
					<Grid item>
						<Button onClick={() => nav("/cms/terms-of-service")}>Back</Button>
					</Grid>
					<Grid item>
						<Button onClick={handlePublish} disabled={tos.published || tos.mutated}>
							{tos.published ? "published" : "publish"}
						</Button>
					</Grid>
				</Grid>

				<TextField fullWidth multiline minRows={10} value={tos.content} onChange={({ target }) => setTos((t) => ({ ...t, content: target.value, mutated: true }))} />
			</Stack>
			<IconButton onClick={handleSave} disabled={!tos.mutated}>
				<SaveIcon />
			</IconButton>
			<LoadingIndicator open={isLoading} />
		</Box>
	) : (
		<LoadingIndicator open={true} />
	);
};

export default TosView;
