import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Divider, Drawer, Hidden, List, Typography } from "@mui/material";
import { ShoppingBag, Users, LogOut, Settings, Edit, Code } from "react-feather";
import NavItem from "../../components/NavItem";
import getInitials from "src/utils/getInitials";
import { useAuth } from "src/providers/AuthProvider";
import { AccountBalance, Assignment, AttachMoney, Storage } from "@mui/icons-material";

const items = [
	{
		href: "/claims",
		icon: Assignment,
		title: "Claims",
		roles: ["AGENT", "DEV", "OWNER"],
	},
	{
		icon: AttachMoney,
		title: "Sales",
		subLinks: [
			{
				title: "Dashboard",
				href: "/sales",
				roles: ["DEV", "OWNER", "SALES"],
			},
			{
				title: "Bookings",
				href: "/sales/bookings",
				roles: ["DEV", "OWNER", "SALES"],
			},
		],
		roles: ["DEV", "OWNER", "SALES"],
	},
	{
		href: "/merchants",
		icon: ShoppingBag,
		title: "Merchants",
		roles: ["AGENT", "DEV", "OWNER"],
	},
	{
		href: "/users",
		icon: Users,
		title: "Users",
		roles: ["DEV", "OWNER"],
	},
	{
		icon: Edit,
		title: "CMS",
		subLinks: [
			{
				title: "Merchant FAQ",
				href: "/cms/merchant-faq",
				roles: ["DEV", "OWNER"],
			},
			{
				title: "Customer FAQ",
				href: "/cms/customer-faq",
				roles: ["DEV", "OWNER"],
			},
			{
				title: "Terms of Service",
				href: "/cms/terms-of-service",
				roles: ["DEV", "OWNER"],
			},
			// {
			// 	title: "Policies",
			// 	href: "/cms/policies",
			// },
		],
		roles: ["DEV", "OWNER"],
	},
	{
		icon: Settings,
		title: "Settings",
		subLinks: [
			{
				title: "Settings",
				href: "/settings",
				roles: ["DEV"],
			},
			{
				title: "Plans",
				href: "/plans",
				roles: ["DEV"],
			},
			{
				title: "Roles",
				href: "/settings/roles",
				roles: ["DEV"],
			},
		],
		roles: ["DEV"],
	},
	{
		icon: AccountBalance,
		title: "Billing",
		roles: ["DEV", "OWNER"],
		subLinks: [
			{
				title: "Accounts",
				href: "/billing/accounts",
				roles: ["DEV", "OWNER"],
			},
			{
				title: "Statements",
				href: "/billing/statements",
				roles: ["DEV", "OWNER"],
			},
			{
				title: "Old Statements",
				href: "/billing/old-statements",
				roles: ["DEV", "OWNER"],
			},
			{
				title: "Adjustments",
				href: "/billing/adjustments",
				roles: ["DEV", "OWNER"],
			},
			{
				title: "Transactions",
				href: "/billing/transactions",
				roles: ["DEV", "OWNER"],
			},
		],
	},
	{
		icon: Code,
		title: "Automations",
		href: "/automations",
		roles: ["DEV"],
	},
	{
		icon: Storage,
		title: "CDN",
		href: "/cdn",
		roles: ["DEV", "OWNER"],
	},
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
	const location = useLocation();
	const {
		userInfo: { displayName, avatar, roles },
		signOut,
	} = useAuth();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}

		// API.claims
		// 	.getAll("?status=DRAFT,IN_REVIEW")
		// 	.then((c) => setClaimCount(c.length))
		// 	.catch(console.error);
		// eslint-disable-next-line
	}, [location.pathname]);

	const hasRequiredRole = (requiredRoles) => {
		return requiredRoles?.some((role) => roles?.includes(role));
	};

	const content = (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					p: 2,
				}}
			>
				<Avatar
					component={RouterLink}
					src={avatar}
					sx={{
						cursor: "pointer",
						width: 64,
						height: 64,
						mb: 1,
						bgcolor: "#4DB6FF",
					}}
					to="/account"
				>
					{!avatar && getInitials(displayName)}
				</Avatar>
				<Typography color="textPrimary" variant="h5">
					{displayName}
				</Typography>
			</Box>

			<Divider />

			<Box sx={{ p: 2 }}>
				<List>
					{items
						.filter(({ roles }) => hasRequiredRole(roles))
						.map((item) => (
							<NavItem key={item.title} {...item} display={item.roles.some((i) => i.includes(roles)) ? "flex" : "none"} />
						))}
				</List>
			</Box>
			<Box sx={{ flex: 1 }} />
			<Box sx={{ p: 2 }}>
				<NavItem onClick={() => signOut()} title={"Log Out"} icon={LogOut} />
			</Box>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary" PaperProps={{ sx: { width: 200 } }}>
					{content}
				</Drawer>
			</Hidden>
			<Hidden lgDown>
				<Drawer
					anchor="left"
					open
					variant="persistent"
					PaperProps={{
						sx: { width: 200, top: 64, height: "calc(100% - 64px)" },
					}}
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default DashboardSidebar;
