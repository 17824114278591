import { Card, CardContent, CardHeader, Grid, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import { withErrorBoundary } from "@sentry/react";
import EditableTypography from "src/EditableTypography";
import useEdit from "../../useEdit";
import FallBackCard from "../../components/FallBackCard";
import withLoading from "../../utils/withLoading";

function ClaimInfo({ claim, saveClaim, merchant, isEditDisabled }) {
	const handleSave = (data) => {
		saveClaim({
			...data,
			newEvent: {
				type: "UPDATED",
				subType: data.reason,
			},
		});
	};

	const [data, setEdit, { CardAction, isEditing }] = useEdit(claim, handleSave);

	const handleUpdate =
		(key) =>
		({ target }) => {
			setEdit((d) => {
				d[key] = target.value;
				return { ...d };
			});
		};

	if (!data || !merchant) return null;
	return (
		<Card>
			<CardHeader title="Claim Info" action={!isEditDisabled && CardAction} />

			<CardContent>
				<Grid container>
					<Grid item xs={6}>
						<Typography>
							<b>Order </b>
							<a href={`https://${merchant.domain}/admin/orders/${claim.orderId}`} target="blank">
								{claim.orderName}
							</a>
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography textAlign="end">{new Date(claim.orderCreatedAt).toLocaleString("en", { dateStyle: "short", timeStyle: "short" })}</Typography>
					</Grid>

					<Grid item xs={6}>
						<Typography>
							<b>Claim</b>
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography textAlign="end">{new Date(claim.createdAt).toLocaleString("en", { dateStyle: "short", timeStyle: "short" })}</Typography>
					</Grid>

					{claim.resolvedAt && (
						<>
							<Grid item xs={6}>
								<Typography>
									<b>Resolved </b>
									{claim.reorderId && (
										<a href={`https://${merchant.domain}/admin/orders/${claim.reorderId}`} target="blank">
											{claim.reorderName}
										</a>
									)}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography textAlign="end">{new Date(claim.resolvedAt).toLocaleString("en", { dateStyle: "short", timeStyle: "short" })}</Typography>
							</Grid>
						</>
					)}
					<Grid item xs={12} height={20}></Grid>
					<Grid item xs={6}>
						<Typography>
							<b>Source</b>
						</Typography>
						<Typography>{data.source.charAt(0) + data.source.slice(1).toLowerCase()}</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							<b>Type</b>
						</Typography>
						<EditableTypography select isEditing={isEditing} value={data.reason} onChange={handleUpdate("reason")}>
							<MenuItem value={"Stolen"}>Stolen</MenuItem>
							<MenuItem value={"Lost/Missing"}>Lost/Missing</MenuItem>
							<MenuItem value={"Damaged"}>Damaged</MenuItem>
							<MenuItem value={"Other"}>Other</MenuItem>
							<MenuItem value={"Returned to Sender"}>Returned to Sender</MenuItem>
						</EditableTypography>
					</Grid>

					<Grid item xs={12}>
						<Typography>
							<b>Description</b>
						</Typography>
						<Typography textAlign="justify" whiteSpace="break-spaces">
							{data.description}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function Loading() {
	return (
		<Card>
			<CardContent>
				<Stack>
					<Skeleton width={200} />
					<br />
					<Skeleton width={60} />
					<Skeleton width={120} />
					<Skeleton width={60} />
					<Skeleton width={120} />
				</Stack>
			</CardContent>

			<CardContent>
				<Stack>
					<Typography>
						<b>Type</b>
					</Typography>
					<Skeleton width={140} />
					<br />
					<Typography>
						<b>Description</b>
					</Typography>
					<Skeleton width="80%" />
					<Skeleton width="75%" />
					<Skeleton width="80%" />
					<Skeleton width="40%" />
				</Stack>
			</CardContent>
		</Card>
	);
}

export default withErrorBoundary(withLoading(ClaimInfo, Loading), { fallback: FallBackCard("Error loading claim info") });
