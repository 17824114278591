import { Stack, Button, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Switch, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import View from "src/components/View";
import API from "src/API";
import { AccountBalance, Assignment, AssistantPhoto, Code, ContactEmergency, Email, People, Receipt, Sell, Sms, Widgets, Wallet } from "@mui/icons-material";
import { useAuth } from "src/providers/AuthProvider";
import useAlert from "src/utils/Alert";
import { LoadingButton } from "@mui/lab";
import MerchantInfo from "src/components/merchant/MerchantInfo";

const MerchantView = () => {
	const [isLoading, setLoading] = useState(true);
	const [featuresOpen, setFeaturesOpen] = useState(false);
	const [testEmailOpen, setTestEmailOpen] = useState(false);
	const { merchant, save } = useMerchant();
	const { success, error } = useAlert();
	const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));

	useEffect(() => {
		if (merchant) {
			setLoading(false);
		}
	}, [merchant]);

	const sendTestEmail = (email) => {
		API.merchants
			.sendTestEmail(merchant._id, email)
			.then(() => success("Test Email Sent!"))
			.catch(error)
			.finally(() => {
				setTestEmailOpen(false);
			});
	};

	return (
		<View.Merchant title="Merchant" isLoading={isLoading}>
			{merchant && (
				<Grid container>
					{/* GENERAL INFO */}
					<Grid item xs={12} md={4}>
						<MerchantInfo {...{ merchant, saveMerchant: save }} />
					</Grid>

					<Grid item container spacing={2} xs={12} md={8} height="100%" pl={isSm && 2}>
						<BigButton label="Onboarding" to={`/merchants/${merchant._id}/onboarding`} icon={<ContactEmergency fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Orders" to={`/merchants/${merchant._id}/orders`} icon={<Receipt fontSize={"large"} />} rolesAccess={["AGENT", "DEV", "OWNER"]} />
						<BigButton label="Billing" to={`/merchants/${merchant._id}/billing`} icon={<AccountBalance fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Dev" to={`/merchants/${merchant._id}/dev`} icon={<Code fontSize={"large"} />} rolesAccess={["DEV"]} />
						<BigButton label="Widget Config" to={`/merchants/${merchant._id}/widget-config`} icon={<Widgets fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Product" to={`/merchants/${merchant._id}/product`} icon={<Sell fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Test Email" onClick={() => setTestEmailOpen(true)} icon={<Email fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Users" to={`/merchants/${merchant._id}/users`} icon={<People fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Claim Policies" to={`/merchants/${merchant._id}/claim-policies`} icon={<Assignment fontSize={"large"} />} rolesAccess={["DEV", "OWNER", "AGENT"]} />
						<BigButton hide={!merchant.featureFlags.includes("sms")} label="SMS" to={`/merchants/${merchant._id}/sms`} icon={<Sms fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Feature Flags" onClick={() => setFeaturesOpen(true)} icon={<AssistantPhoto fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
						<BigButton label="Payment Methods" to={`/merchants/${merchant._id}/payment-methods`} icon={<Wallet fontSize={"large"} />} rolesAccess={["DEV", "OWNER"]} />
					</Grid>

					<TestEmailDialog {...{ open: testEmailOpen, onClose: () => setTestEmailOpen(false), sendTestEmail }} />
					<FeaturesDialog {...{ open: featuresOpen, onClose: () => setFeaturesOpen(false), featureFlags: merchant.featureFlags, save }} />
				</Grid>
			)}
		</View.Merchant>
	);
};

export default MerchantView;

function BigButton({ to, label, icon, onClick, rolesAccess, hide }) {
	const {
		userInfo: { roles },
	} = useAuth();
	const nav = useNavigate();
	if (hide) return null;
	return (
		<Grid item xs={6} md={4}>
			<Button fullWidth variant="outlined" onClick={() => (onClick ? onClick() : nav(to))} sx={{ height: 100 }} disabled={rolesAccess.some((role) => roles.includes(role)) ? false : true}>
				<Stack spacing={2} alignItems="center">
					{icon}
					{label}
				</Stack>
			</Button>
		</Grid>
	);
}

function TestEmailDialog({ open, onClose, sendTestEmail }) {
	const { userInfo } = useAuth();
	const [email, setEmail] = useState(userInfo.email);

	return (
		<>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>Send Test Email</DialogTitle>
				<DialogContent>
					<TextField label="Email" value={email} onChange={({ target }) => setEmail(target.value)} />
				</DialogContent>
				<DialogActions>
					<Button disabled={!email} fullWidth variant="contained" onClick={() => sendTestEmail(email)}>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function FeaturesDialog({ open, onClose, featureFlags, save }) {
	const [features, setFeatures] = useState([]);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		setFeatures(featureFlags || []);
	}, [featureFlags]);

	const handleChange = (flag) => (e, v) =>
		setFeatures((f) => {
			const newFlags = v ? [...f, flag] : f.filter((f) => f !== flag);
			return newFlags.sort();
		});

	const handleSave = () => {
		setLoading(true);
		save({ featureFlags: features }).then(() => {
			setLoading(false);
			onClose();
		});
	};

	return (
		<Dialog open={!!open} onClose={onClose}>
			<DialogTitle>Feature Flags</DialogTitle>
			<DialogContent>
				<Stack direction="row" spacing={1} alignItems="center">
					<Switch checked={features.includes("managePaymentMethods")} onChange={handleChange("managePaymentMethods")} />
					<Typography>Manage Payment Methods</Typography>
				</Stack>
				<Stack direction="row" spacing={1} alignItems="center">
					<Switch checked={features.includes("rechargeIntegration")} onChange={handleChange("rechargeIntegration")} />
					<Typography>Recharge Integration</Typography>
				</Stack>
				<Stack direction="row" spacing={1} alignItems="center">
					<Switch checked={features.includes("sms")} onChange={handleChange("sms")} />
					<Typography>SMS</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<LoadingButton variant="contained" loading={isLoading} disabled={featureFlags.toString() === features.toString()} fullWidth onClick={handleSave}>
					Submit
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
