import { Check, Close } from "@mui/icons-material";
import { IconButton, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function EditableTypography({ children, select, onChange, useOnClick, variant, onSave, textProps, inputProps, ...props }) {
	const [isEditing, setEditing] = useState(props.isEditing);
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		setEditing(props.isEditing);
	}, [props.isEditing]);

	const handleClick = () => {
		if (!useOnClick) return;
		setEditing(true);
	};

	const handleChange = (e) => {
		if (onChange) {
			onChange(e);
		} else {
			setValue(e.target.value);
		}
	};

	const handleClose = () => {
		setEditing(false);
		setValue(props.value);
	};

	const handleSave = () => {
		setEditing(false);
		onSave?.(value);
	};

	if (!isEditing)
		return (
			<Typography {...textProps} onClick={handleClick}>
				{value}
			</Typography>
		);

	return select ? (
		<Select size="small" value={value} onChange={onChange}>
			{children}
		</Select>
	) : (
		<TextField
			inputProps={inputProps}
			value={value}
			variant={variant}
			onChange={handleChange}
			InputProps={{
				endAdornment: (
					<Stack direction="row">
						<IconButton onClick={handleClose}>
							<Close color="error" />
						</IconButton>
						<IconButton onClick={handleSave}>
							<Check color="success" />
						</IconButton>
					</Stack>
				),
			}}
		/>
	);
}
