import { useState } from "react";
import { useAuth } from "src/providers/AuthProvider";
import getInitials from "src/utils/getInitials";
import firebase from "../../firebase";

import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography, Dialog, DialogTitle, Input } from "@mui/material";
import API from "src/API";

const AccountProfile = (props) => {
	const [inputFile, setInputFile] = useState();
	const [dialogOpen, setDialogOpen] = useState(false);
	const { displayName, avatar, uid } = useAuth().userInfo;

	const handleSubmit = (e) => {
		//TODO -- INTEGRATE WITH API TO SET NEW AVATAR IN DATA BASE

		//THIS DOESNT WORK
		setDialogOpen(false);
		const storageRef = firebase.storage().ref(uid + "/profilePic/" + inputFile.name);
		const task = storageRef.put(inputFile);
		console.log(task);
	};

	const handleTest = () => {
		API.test().then(console.log).catch(console.error);
	};

	return (
		<Card {...props}>
			<CardContent>
				<Box sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
					<Avatar src={avatar} sx={{ height: 100, width: 100, bgcolor: "#4DB6FF" }} onClick={handleTest}>
						{!avatar && getInitials(displayName)}
					</Avatar>
					<Typography color="textPrimary" gutterBottom variant="h3">
						{displayName}
					</Typography>
				</Box>
			</CardContent>
			<Divider />
			<CardActions>
				<Button color="primary" fullWidth variant="text" onClick={() => setDialogOpen(true)}>
					Upload picture
				</Button>
			</CardActions>
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<DialogTitle>Upload Profile Image</DialogTitle>
				<Input type="file" onChange={(e) => setInputFile(e.target.files[0])} />
				<Button color="primary" fullWidth variant="text" onClick={handleSubmit}>
					Confirm
				</Button>
			</Dialog>
		</Card>
	);
};

export default AccountProfile;
