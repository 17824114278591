export default function doObjectsMatch(obj1 = {}, obj2 = {}) {
  const entries1 = Object.entries(obj1);
  const entries2 = Object.entries(obj1);


  if (entries1.length !== entries2.length) return false;

  for (var [key, value] of entries1) {
    if (value !== obj2[key]) return false
  }
  return true
}