import { CheckCircle, Circle, CircleOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Grid, List, ListItemButton, ListSubheader, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DataGrid } from "@mui/x-data-grid";
import API from "src/API";
import LoadingIndicator from "src/components/LoadingIndicator";
import View from "src/components/View";

export default function OldStatementsView() {
	const [selectedStatement, setSelectedStatement] = useState();
	const [statements, setStatements] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [open, setOpen] = useState(true);

	useQuery("oldStatements", () => API.statements.getAllV1().then((r) => r.oldStatements), { onSuccess: setStatements });

	const getIconProps = (status) => {
		if (status === "CREATED") {
			return <Circle color="primary"></Circle>;
		} else if (status === "APPROVED") {
			return <CircleOutlined color="success"></CircleOutlined>;
		} else if (status === "BILLED") {
			return <CheckCircle color="success"></CheckCircle>;
		} else if (status === "SKIPPED") {
			return <Circle color="disabled"></Circle>;
		} else {
			return <Circle color="primary"></Circle>;
		}
	};

	const handleNegativeTotals = (netTotal) => {
		return netTotal < 0 ? <Typography color="red">{netTotal}</Typography> : <Typography>{netTotal}</Typography>;
	};

	const rows = statements?.map((statement) => {
		return { id: statement._id, status: statement.status, merchantName: statement.merchantName, netTotal: statement.netTotal, month: statement.month, ...statement };
	});

	const columns = [
		{
			field: "status",
			headerName: "Status",
			width: 75,
			renderCell: (params) => {
				const status = params.formattedValue;
				return getIconProps(status);
			},
		},
		{ field: "month", headerName: "Month", width: 150 },
		{ field: "merchantName", headerName: "Merchant", width: 250 },
		{
			field: "netTotal",
			headerName: "Net Total",
			width: 150,
			renderCell: (params) => {
				const netTotal = +params.formattedValue;
				return handleNegativeTotals(netTotal);
			},
		},
	];

	return (
		<View title="Reports" isLoading={isLoading}>
			<Grid container sx={{ width: "100%", height: "100%" }} spacing={2}>
				<Grid item xs={6}>
					<Stack mb={2}>
						<Collapse in={open} collapsedSize={50}>
							<List>
								<ListItemButton onClick={() => setOpen(!open)} sx={{ justifyContent: "space-between", border: 1 }}>
									<Typography>Monthly Total Summaries</Typography>
									{open ? <ExpandLess /> : <ExpandMore />}
								</ListItemButton>
								{statements
									?.filter((st) => st.month)
									.map((s, i, r) => (
										<Fragment key={s._id}>
											{s.month !== r[i - 1]?.month && (
												<ListSubheader sx={{ bgcolor: "transparent", pl: 3 }} disableGutters>
													<Grid container spacing={1} alignItems="center" justifyContent="space-between">
														<Grid item xs={3}>
															{s.month}
														</Grid>

														<Grid item xs={5}>
															Claims:
															{r
																.filter((ss) => ss.month === s.month)
																.reduce((p, c) => p - +c.claimsTotal, 0)
																.toLocaleString("en", { style: "currency", currency: "USD" })}
														</Grid>
														<Grid item xs={4}>
															Net:
															{r
																.filter((ss) => ss.month === s.month)
																.reduce((p, c) => p + +c.netTotal, 0)
																.toLocaleString("en", { style: "currency", currency: "USD" })}
														</Grid>
													</Grid>
												</ListSubheader>
											)}
										</Fragment>
									))}
							</List>
						</Collapse>
					</Stack>
					<DataGrid rows={rows} columns={columns} checkboxSelection={false} onRowClick={(e) => setSelectedStatement(e)} initialState={{ pagination: { paginationModel: { pageSize: 50 } } }} pageSizeOptions={[25, 50, 100]} />
					<LoadingIndicator open={!!isLoading} />
				</Grid>
				<Grid container item xs={6}>
					<Grid item xs={12} sx={{ height: "100%", pt: 1 }}>
						<PDFView statement={selectedStatement} {...{ setLoading }} />
					</Grid>
				</Grid>
			</Grid>
		</View>
	);
}

/**
 *
 * @param {*} param0
 * @returns
 */

function PDFView({ statement, setLoading }) {
	const [url, setUrl] = useState();

	useEffect(() => {
		if (statement) {
			setLoading(true);
			API.statements
				.getV1(statement.id)
				.then(({ pdf }) => {
					if (!pdf) setUrl(false);
					const buffer = Buffer.from(pdf.data);
					const blob = new Blob([buffer], { type: "application/pdf" });
					const link = URL.createObjectURL(blob);
					setUrl(link + "#toolbar=0");
				})

				.catch(console.error)
				.finally(() => setLoading(false));
		} else setUrl();
	}, [statement, setLoading]);

	if (statement && !url) {
		return (
			<Stack sx={{ position: "fixed" }} width="50vw" height="100vh" justifyContent="center" alignItems="center">
				<Typography>Select a PDF</Typography>
			</Stack>
		);
	}

	if (!url)
		return (
			<Stack sx={{ position: "fixed" }} width="50vw" height="100vh" justifyContent="center" alignItems="center">
				<Typography>Select a PDF</Typography>
			</Stack>
		);
	return <iframe title="PDF" src={url} style={{ width: "100%", height: "100%" }}></iframe>;
}
