import client from "./axios";

export default class API {
	static merchants = {
		getAll: () => req.get("/merchants"),
		get: (mid) => req.get("/merchants/" + mid).then((r) => r.merchant),
		update: (mid, update) => req.put(`/merchants/${mid}`, update),
		updateClaimPolicies: (mid, update) => req.put(`/merchants/${mid}/claim-policies`, update),
		delete: (mid) => window.alert("CANT DELETE RN"),
		create: (doc) => req.post("/merchants/new", doc),
		getOrders: (mid, cursor) => req.get(`/merchants/${mid}/orders?${cursor}`),
		getStoreOrders: (mid, cursor) => req.get(`/merchants/${mid}/store-orders` + (cursor ? "?" + cursor.toString() : "")),
		queryStoreOrders: (mid, terms = "") => req.get(`/merchants/${mid}/store-orders/query?terms=` + encodeURIComponent(terms)),
		getOrder: (mid, oid) => req.get(`/merchants/${mid}/orders/${oid}`),
		getStoreOrder: (mid, oid) => req.get(`/merchants/${mid}/store-orders/${oid}`),
		getReports: (mid) => req.get(`/merchants/${mid}/reports`),
		// createReport: (mid, createdAfter) => req.post(`/merchants/${mid}/reports/create`, { createdAfter }),
		charge: (mid, price, description, rid) => req.post(`/merchants/${mid}/charge`, { price, description, rid }),
		getAppCharges: (mid) => req.get(`/merchants/${mid}/app-charges`),
		deleteRecurringAppCharge: (mid, id) => req.delete(`/merchants/${mid}/app-charges/${id}`),
		createAppCharge: (mid, cappedAmount, description) => req.post(`/merchants/${mid}/app-charges`, { cappedAmount, description }),
		createUsageCharge: (mid, appChargeId, description, amount) => req.post(`/merchants/${mid}/app-charges/${appChargeId}/usage-charges`, { description, amount }),
		updateAppCharge: (mid, appChargeId, cappedAmount) => req.put(`/merchants/${mid}/app-charges/${appChargeId}`, { cappedAmount }),
		getWidgetConfig: (mid) => req.get(`/merchants/${mid}/widget-config`),
		getOnboarding: (mid) => req.get(`/merchants/${mid}/onboarding`).then((r) => r.onboarding),
		getUsers: (mid) => req.get(`/merchants/${mid}/users`).then((r) => r.users),
		createUser: (mid, user) => req.post(`/merchants/${mid}/users/new`, user),
		updateUser: (mid, uid, update) => req.put(`/merchants/${mid}/users/${uid}`, update).then((r) => r.user),
		deleteUser: (mid, uid) => req.delete(`/merchants/${mid}/users/${uid}`).then((r) => r.success),
		sendTestEmail: (mid, email) => req.post(`/merchants/${mid}/test-email`, { email }),
		tasks: {
			getAll: (mid) => req.get(`/merchants/${mid}/tasks`).then((r) => r.tasks),
			update: (mid, tasks) => req.put(`/merchants/${mid}/tasks`, { tasks }).then((r) => r.tasks),
		},
		product: {
			get: (mid) => req.get(`/merchants/${mid}/product`).then((r) => r.product),
			create: (mid) => req.post(`/merchants/${mid}/product`).then((r) => r.product),
			update: (mid, product) => req.put(`/merchants/${mid}/product`, { product }).then((r) => r.product),
			getInventory: (mid, variantIds) => req.get(`/merchants/${mid}/inventory?variantIds=${variantIds.join(",")}`).then((r) => r.inventories),
		},
		webhooks: {
			get: (mid) => req.get(`/merchants/${mid}/webhooks`).then((r) => r.webhooks),
			create: (mid, { address, topic }) => req.post(`/merchants/${mid}/webhooks/new`, { address, topic }).then((r) => r.webhook),
			edit: (mid, data) => req.put(`/merchants/${mid}/webhooks/${data.id}`, data).then((r) => r.webhook),
			delete: (mid, id) => req.delete(`/merchants/${mid}/webhooks/${id}`).then((r) => r.success),
		},
		scriptTags: {
			get: (mid) => req.get(`/merchants/${mid}/script-tags`).then((r) => r.scriptTags),
			create: (mid, src) => req.post(`/merchants/${mid}/script-tags/new`, { src }).then((r) => r.scriptTag),
			delete: (mid, id) => req.delete(`/merchants/${mid}/script-tags/${id}`).then((r) => r.success),
			update: (mid, id, src) => req.put(`/merchants/${mid}/script-tags/${id}`, { src }).then((r) => r.scriptTag),
		},
		widget: {
			deleteFeeTier: (mid, variantId) => req.delete(`/merchants/${mid}/fee-tier/${variantId}`).then((r) => r),
			updateFeeTier: (mid, tier) => req.put(`/merchants/${mid}/fee-tier`, tier).then((r) => r.variant),
		},
		storefrontToken: {
			get: (mid) => req.get(`/merchants/${mid}/storefront-access-tokens`).then((r) => r.tokens),
			delete: (mid, id) => req.delete(`/merchants/${mid}/storefront-access-tokens/${id}`).then((r) => r),
			create: (mid) => req.post(`/merchants/${mid}/storefront-access-tokens`).then((r) => r),
		},
		paymentMethods: {
			get: (mid) => req.get(`/merchants/${mid}/payment-methods`).then((r) => r),
			makeDefault: (mid, id, data) => req.put(`/merchants/${mid}/payment-methods/${id}`, data),
			add: (mid, payment) => req.post(`/merchants/${mid}/payment-methods`, payment),
			delete: (mid) => req.delete(`/merchants/${mid}/payment-methods`).then((r) => r.success),
		},
	};

	static sales = {
		getSummary: (month) => req.get(`/sales/summary?month=${month}`).then((r) => r.summary),
		getUsers: () => req.get(`/sales/users`).then((r) => r.users),
		getBookings: () => req.get(`/sales/booked-calls`).then((r) => r.bookings),
		updateBooking: (id, update) => req.put(`/sales/booked-calls/${id}`, update).then((r) => r.booking),
		updateUser: (uid, data) => req.put(`/sales/users/${uid}`, data),
	};

	static automations = {
		createStatement: (data) => req.post(`/automations/create-statement`, data),
		sendStatement: (data) => req.post(`/automations/send-statement`, data),
		syncDatabase: (data) => req.post(`/automations/sync-database`, data),
		getTasks: () => req.get(`/automations/tasks`),
		createPDF: (data) => req.post(`/automations/pdf`, data),

		scrapeOrders: (mid) => req.post(`/merchants/${mid}/scrape-orders`),
		syncOrders: (mid, orders) => req.post("/tasks/sync-orders", { orders, mid }),
	};
	static claims = {
		getAll: () => req.get("/claims").then((r) => r.claims), // TODO
		query: (query) => req.post("/claims", query), // TODO
		find: (mid, oid) => req.get(`/claims/find?${new URLSearchParams({ mid, oid }).toString()}`).then((r) => r.claim),
		get: (id) => req.get("/claims/" + id).then((r) => r.claim),
		update: (id, update) => req.put(`/claims/${id}`, update).then((r) => r.claim),
		process: (id, who) => req.post(`/claims/${id}/process`, { who }),
		manualProcess: (id, data) => req.post(`/claims/${id}/manual-process`, data),
		approve: (id) => req.post(`/claims/${id}/approve`).then((r) => r.claim),
		delete: (id) => req.delete(`/claims/${id}`).then((r) => r.success),
		create: (doc) => req.post("/claims/new", doc).then(({ claim }) => claim),
	};
	static users = {
		getAll: () => req.get("/users").then((r) => r.users),
		get: (id) => req.get(`/users/${id}`),
		create: (doc) => req.post("/users/new", doc).then((r) => r.user),
		delete: (uid) => req.delete(`/users/${uid}`).then((r) => r.success),
		update: (uid, update) => req.put(`/users/${uid}`, update).then((r) => r.user),
		sendPassReset: (uid) => req.post(`/users/sendPassReset`, { uid }),
	};
	static scopes = {
		get: () => req.get("/scopes"),
	};
	static reports = {
		create: (data) => req.post("/reports/create", data),
		getAllByMid: (mid) => req.get(`/reports/${mid}`),
		getById: (mid, id) => req.get(`/reports/${mid}/${id}`),
		setAdjustment: (mid, id, adjustments) => req.post(`/reports/${mid}/${id}/adjustments`, adjustments),
	};
	static settings = {
		get: () => req.get("/settings").then((r) => r.settings),
		update: (update) => req.put("/settings", update),
		getRoles: () => req.get("/settings/roles").then((r) => r.roles),
		updateRole: (role) => req.put("/settings/roles/" + role.id, role).then((r) => r.role),
		deleteRole: (roleId) => req.delete("/settings/roles/" + roleId),
		createRole: () => req.post("/settings/roles").then((r) => r.role),
	};
	static merchant_faqs = {
		delete: (topicId, faqId) => req.delete(`/cms/merchant-faq/${topicId}/${faqId}`).then((r) => r.success),
		update: (topic, f) => req.put(`/cms/merchant-faq/${topic}/${f.id || "new"}`, f).then((r) => r.faq),
		getAll: () => req.get("/cms/merchant-faq").then((r) => r.merchant_faq),
		createTopic: (topic) => req.post("/cms/merchant-faq/new", topic).then((r) => r.topic),
		updateTopic: (topic) => req.put("/cms/merchant-faq/" + topic.id, topic).then((r) => r.topic),
		deleteTopic: (id) => req.delete("/cms/merchant-faq/" + id).then((r) => r.success),
	};
	static terms_of_service = {
		create: (tos) => req.post("/cms/terms-of-service/new", tos).then((r) => r.id),
		update: (id, tos) => req.put(`/cms/terms-of-service/${id}`, tos).then((r) => r.tos),
		getAll: () => req.get("/cms/terms-of-service").then((r) => r.tos),
		get: (id) => req.get(`/cms/terms-of-service/${id}`).then((r) => r.terms),
		publish: (id) => req.post(`/cms/terms-of-service/${id}/publish`),
	};
	static plans = {
		getPlans: () => req.get("/plans"),
		updatePlan: (plan) => req.put("/plans/" + plan.id, plan).then((r) => r.plan),
		deletePlan: (id) => req.delete("/plans/" + id),
		createPlan: () => req.post("/plans").then((r) => r.plan),
	};
	static statements = {
		getAll: () => req.get(`/statements`),
		get: (id) => req.get(`/statements/${id}`),
		getAllV1: () => req.get(`/old-statements`),
		getV1: (id) => req.get(`/old-statements/${id}`),
		create: (data) => req.post(`/statements`, data),
		update: (id, data) => req.put(`/statements/${id}`, data),
		send: (id) => req.post(`/statements/${id}/send`),
		delete: (id) => req.delete(`/statements/${id}`),
		charge: (id, data) => req.post(`/statements/${id}/charge`, data),
	};
	static transactions = {
		getAccounts: () => req.get(`/transactions/accounts`),
		getAll: (query) => req.post(`/transactions`, { query }),
	};
	static notifications = {
		get: () => req.get(`/notifications`),
		update: (_id, data) => req.put(`/notifications/${_id}`, data),
	};
	static adjustments = {
		create: (data) => req.post(`/adjustments`, data),
		getAll: () => req.get(`/adjustments`),
		get: (id) => req.get(`/adjustments/${id}`),
		update: (id, data) => req.put(`/adjustments/${id}`, data),
		delete: (id) => req.delete(`/adjustments/${id}`),
	};
	static test = () => req.get(`/test/`);
}

function request(method = "GET") {
	return async (path, data) => client(path, { data, method }).then(({ data }) => data);
}

const req = {
	get: request("GET"),
	post: request("POST"),
	put: request("PUT"),
	delete: request("DELETE"),
};
