import {
	Stack,
	Button,
	TableContainer,
	Table,
	TableHead,
	ListSubheader,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	MenuItem,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Select,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Skeleton,
	ToggleButtonGroup,
	ToggleButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAlert from "src/utils/Alert";
import API from "src/API";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import * as Icons from "@mui/icons-material";
import shopifyWebhookTopics from "src/shopifyWebhookTopics";
import View from "src/components/View";
import { Card, CardContent, CardHeader, Radio, FormControl, RadioGroup, FormControlLabel, InputAdornment } from "@mui/material";
import { Delete, Check, Edit, Close } from "@mui/icons-material";

export default function DevView() {
	return (
		<View.Merchant title="Merchant">
			<Stack spacing={1}>
				<WebhooksCard />
				<ScriptTagsCard />
				<StorefrontTokenCard />
			</Stack>
		</View.Merchant>
	);
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ WEBHOOKS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function WebhooksCard() {
	const [, setLoading] = useState(true);
	const { merchant } = useMerchant();
	const [newWebhookOpen, setNewWebhookOpen] = useState(false);
	const [editingWebhook, setEditingWebhook] = useState(false);
	const [webhooks, setWebhooks] = useState([]);
	const { success, error } = useAlert();

	useEffect(() => {
		if (merchant?._id) {
			API.merchants.webhooks.get(merchant._id).then(setWebhooks).catch(error).finally(setLoading);
		}
	}, [merchant, error]);

	const handleDeleteWebhook = (id) => {
		if (window.confirm("Delete webhook?")) {
			setLoading(true);
			API.merchants.webhooks
				.delete(merchant._id, id)
				.then(() => setWebhooks((wh) => wh.filter((w) => w.id !== id)))
				.catch(error)
				.finally(setLoading);
		}
	};

	const handleEditWebhook = ({ id, address, topic }) => {
		setLoading(true);

		API.merchants.webhooks
			.edit(merchant._id, { id, address, topic })
			.then((updated) =>
				setWebhooks((wh) => {
					Object.assign(editingWebhook, updated);
					return [...wh];
				})
			)
			.catch(error)
			.finally(() => {
				setLoading(false);
				setEditingWebhook(null);
			});
	};

	const handleAddWebhook = ({ address, topic }) => {
		setLoading(true);
		API.merchants.webhooks
			.create(merchant._id, { address, topic })
			.then((wh) => {
				success("Webhook created!");
				setWebhooks((webhooks) => [...webhooks, wh]);
			})
			.catch(error)
			.finally(() => {
				setLoading(false);
				setNewWebhookOpen(false);
			});
	};

	return (
		<Card>
			<Stack spacing={1}>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<ListSubheader disableSticky>Webhooks</ListSubheader>
							<TableRow>
								<TableCell>Topic</TableCell>
								<TableCell>API Version</TableCell>
								<TableCell>Address</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{webhooks.map((hook, i) => (
								<TableRow key={i} onClick={() => console.log(hook)}>
									<TableCell>{hook.topic}</TableCell>
									<TableCell>{hook.api_version}</TableCell>
									<TableCell>{hook.address}</TableCell>
									<TableCell>
										<Stack spacing={1} direction="row">
											<IconButton onClick={() => setEditingWebhook(hook)}>
												<Icons.Edit />
											</IconButton>
											<IconButton onClick={() => handleDeleteWebhook(hook.id)}>
												<Icons.Delete />
											</IconButton>
										</Stack>
									</TableCell>
								</TableRow>
							))}
							<TableRow>
								<TableCell colSpan={4} style={{ textAlign: "center" }}>
									<Button fullWidth variant="contained" onClick={() => setNewWebhookOpen(true)}>
										New Webhook
									</Button>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
			<NewWebhookDialog
				{...{
					open: newWebhookOpen,
					onClose: () => setNewWebhookOpen(false),
					handleAddWebhook,
					mid: merchant?._id,
				}}
			/>
			<EditWebhookDialog
				{...{
					webhook: editingWebhook,
					onClose: () => setEditingWebhook(false),
					handleEditWebhook,
					mid: merchant?._id,
				}}
			/>
		</Card>
	);
}

function NewWebhookDialog({ open, onClose, handleAddWebhook, mid }) {
	const [address, setAddress] = useState("");
	const [topic, setTopic] = useState(shopifyWebhookTopics[0]);

	useEffect(() => {
		const url = new URL(`https://api.savedby.io//shopify/webhooks/${topic.replace("/", "_").toUpperCase()}?mid=${mid}`);
		setAddress(url.toString());
	}, [topic, mid]);

	return (
		<Dialog fullWidth maxWidth="sm" {...{ open, onClose }}>
			<DialogTitle>New Webhook</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<TextField label="Address" placeholder="/path/to/hook" fullWidth value={address} onChange={({ target }) => setAddress(target.value)} />
					<Select label="Topic" fullWidth value={topic} onChange={({ target }) => setTopic(target.value)}>
						{shopifyWebhookTopics.map((topic) => (
							<MenuItem key={topic} value={topic}>
								{topic}
							</MenuItem>
						))}
					</Select>

					<Button disabled={!address} onClick={() => handleAddWebhook({ address, topic })} fullWidth>
						Create
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function EditWebhookDialog({ onClose, handleEditWebhook, webhook, mid = "" }) {
	const [address, setAddress] = useState("");
	const [topic, setTopic] = useState("");
	const [env, setEnv] = useState("");

	useEffect(() => {
		if (webhook) {
			setAddress(webhook.address);
			setTopic(webhook.topic);
			const url = new URL(webhook.address);
			if (url.host === "api.savedby.io") setEnv("api.savedby.io");
			if (url.host === "savedby.ngrok.io") setEnv("savedby.ngrok.io");
		}
	}, [webhook]);

	const handleEnvironmentChange = (e, v) => {
		setAddress((addr) => {
			let url;
			try {
				url = new URL(addr);
				url.host = v;
			} catch (e) {
				url = new URL(`https://${v}`);
			}
			url.pathname = `/shopify/webhooks/${topic.replace("/", "_").toUpperCase()}`;
			url.search = `?mid=${mid}`;

			if (v === "api.savedby.io") setEnv("api.savedby.io");
			if (v === "savedby.ngrok.io") setEnv("savedby.ngrok.io");
			return url.toString();
		});
	};

	return (
		<Dialog fullWidth maxWidth="sm" {...{ open: !!webhook, onClose }}>
			<DialogTitle>Edit Webhook - {webhook?.id}</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<TextField label="Address" placeholder="/path/to/hook" fullWidth value={address} onChange={({ target }) => setAddress(target.value)} />

					<ToggleButtonGroup exclusive onChange={handleEnvironmentChange} value={env}>
						<ToggleButton value="api.savedby.io">PROD</ToggleButton>
						<ToggleButton value="savedby.ngrok.io">DEV</ToggleButton>
					</ToggleButtonGroup>

					<Select label="Topic" fullWidth value={topic} onChange={({ target }) => setTopic(target.value)}>
						{shopifyWebhookTopics.map((topic) => (
							<MenuItem key={topic} value={topic}>
								{topic}
							</MenuItem>
						))}
					</Select>

					<Button disabled={!address} onClick={() => handleEditWebhook({ id: webhook.id, address, topic })} fullWidth>
						Update
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SCRIPT TAGS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function ScriptTagsCard() {
	const [, setLoading] = useState(true);
	const { merchant } = useMerchant();
	const [newScriptTagOpen, setNewScriptTagOpen] = useState(false);
	const [scriptTags, setScriptTags] = useState([]);
	const { success, error } = useAlert();

	useEffect(() => {
		if (merchant?._id) {
			API.merchants.scriptTags.get(merchant._id).then(setScriptTags).catch(error).finally(setLoading);
		}
	}, [merchant, error]);

	const handleDeleteScriptTag = (id) => {
		if (window.confirm("Delete webhook?")) {
			API.merchants.scriptTags
				.delete(merchant._id, id)
				.then(() => setScriptTags((scriptTags) => scriptTags.filter((st) => st.id !== id)))
				.catch(error);
		}
	};

	const handleAddScriptTag = (source) => {
		setLoading(true);
		API.merchants.scriptTags
			.create(merchant._id, source)
			.then((scriptTag) => {
				success("Script tag created!");
				setScriptTags((scriptTags) => [...scriptTags, scriptTag]);
			})
			.catch(error)
			.finally(() => {
				setLoading(false);
				setNewScriptTagOpen(false);
			});
	};

	const widgetScriptTag = scriptTags.find((st) => /\/widget/.test(st.src));

	const updateScriptTag = (scriptTagId, src, cb = () => null) => {
		setLoading(true);
		return API.merchants.scriptTags
			.update(merchant._id, scriptTagId, src)
			.then((scriptTag) => {
				setScriptTags((scriptTags) => {
					const matchedScriptTag = scriptTags.find((st) => st.id === scriptTagId);
					if (matchedScriptTag) {
						Object.assign(matchedScriptTag, scriptTag);
					} else {
						scriptTags.push(scriptTag);
					}
					return [...scriptTags];
				});
			})
			.then(cb)
			.catch(console.error)
			.finally(setLoading);
	};

	return (
		<Card>
			<Stack>
				<Widget {...{ widgetScriptTag, updateScriptTag }} />
			</Stack>
			<Stack spacing={1}>
				<TableContainer component={Paper}>
					<CardHeader title="Script Tags" />
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Event</TableCell>
								<TableCell colSpan={3}>Source</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{scriptTags.map((st, i) => (
								<ScriptTagRow {...{ key: i, st, updateScriptTag, handleDeleteScriptTag }} />
							))}
							<TableRow>
								<TableCell colSpan={4} style={{ textAlign: "center" }}>
									<Button fullWidth variant="contained" onClick={() => setNewScriptTagOpen(true)}>
										New Script Tag
									</Button>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
			<NewScriptTagDialog {...{ open: newScriptTagOpen, onClose: () => setNewScriptTagOpen(false), handleAddScriptTag }} />
		</Card>
	);
}

function ScriptTagRow({ st, updateScriptTag, handleDeleteScriptTag }) {
	const [src, setSrc] = useState("");
	const [isEditing, setEditing] = useState(false);

	useEffect(() => {
		setSrc(decodeURI(st.src));
	}, [st]);

	const handleCancel = () => {
		setSrc(st.src);
		setEditing(false);
	};

	const handleSave = () => {
		updateScriptTag(st.id, src, () => setEditing(false));
	};

	return (
		<TableRow>
			<TableCell>{st.event}</TableCell>
			{isEditing ? (
				<TableCell>
					<TextField variant="standard" fullWidth value={src} onChange={({ target }) => setSrc(target.value)} />
				</TableCell>
			) : (
				<TableCell>{src}</TableCell>
			)}

			<TableCell>
				{isEditing ? (
					<Stack spacing={2} direction="row">
						<IconButton onClick={handleSave}>
							<Check />
						</IconButton>
						<IconButton onClick={handleCancel}>
							<Close />
						</IconButton>
					</Stack>
				) : (
					<IconButton onClick={() => setEditing((e) => !e)}>
						<Edit />
					</IconButton>
				)}
			</TableCell>

			<TableCell>
				<IconButton onClick={() => handleDeleteScriptTag(st.id)}>
					<Delete />
				</IconButton>
			</TableCell>
		</TableRow>
	);
}

function NewScriptTagDialog({ open, onClose, handleAddScriptTag }) {
	const [source, setSource] = useState("");

	return (
		<Dialog fullWidth {...{ open, onClose }}>
			<DialogTitle>New Script Tag</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<TextField label="Source" fullWidth value={source} onChange={({ target }) => setSource(target.value)} />
					<Button onClick={() => setSource("https://api.savedby.io/widget/v3-1-0")}>Production</Button>
					<Button onClick={() => setSource("https://savedby.ngrok.io/proxy/3000/static/js/widget.js")}>Dev</Button>
					<Button disabled={!source} onClick={() => handleAddScriptTag(source)} fullWidth>
						Create
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function Widget({ widgetScriptTag, updateScriptTag }) {
	const [widgetVersion, setWidgetVersion] = useState("");
	const [widgetType, setWidgetType] = useState("");
	const [mutated, setMutated] = useState(false);

	useEffect(() => {
		setMutated(false);
		if (widgetScriptTag?.src) {
			const version = widgetScriptTag.src.match(/(?:(?![v]))\d+-\d+-\d+/g)?.[0];

			if (version) {
				setWidgetType("version");
				setWidgetVersion(version.replaceAll("-", "."));
			} else if (widgetScriptTag.src) {
				setWidgetType("dev");
			} else setWidgetType("version");
		}
	}, [widgetScriptTag?.src]);

	const handleWidgetChange = (e, type) => {
		setWidgetType(type);
		let src = "";
		if (type === "version") {
			const version = widgetVersion.match(/^\d+\.\d+\.\d+$/g)?.[0];
			if (version) {
				src = "https://api.savedby.io/widget/v" + version.replaceAll(".", "-");
			}
		} else {
			src = "https://savedby.ngrok.io/static/js/widget.js";
		}

		updateScriptTag(widgetScriptTag?.id, src);
	};

	useEffect(() => {
		if (widgetType === "dev") {
			setMutated(false);
		} else if (widgetScriptTag?.src) {
			if (/(?:(?![v]))\d+-\d+-\d+/.test(widgetScriptTag?.src) && widgetType === "version" && widgetScriptTag?.src.includes(widgetVersion.replaceAll(".", "-"))) {
				setMutated(false);
			} else setMutated(true);
		} else setMutated(true);
	}, [widgetScriptTag?.src, widgetType, widgetVersion]);

	const handleSave = () => {
		updateScriptTag(widgetScriptTag?.id, "https://api.savedby.io/widget/v" + widgetVersion.replaceAll(".", "-"));
	};

	return (
		<Card>
			<CardHeader title="Widget" />
			<CardContent>
				<FormControl>
					<RadioGroup value={widgetType} onChange={handleWidgetChange}>
						<TextField
							error={!/^\d+\.\d+\.\d+$/.test(widgetVersion)}
							label="Version"
							disabled={widgetType !== "version"}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Button onClick={handleSave} disabled={!/^\d+\.\d+\.\d+$/.test(widgetVersion) || !mutated}>
											Save
										</Button>
									</InputAdornment>
								),
								startAdornment: <InputAdornment position="start">v</InputAdornment>,
							}}
							value={widgetVersion}
							onChange={({ target }) => setWidgetVersion(target.value)}
						/>
						<FormControlLabel value="version" control={<Radio />} label="Version" />
						<FormControlLabel value="dev" control={<Radio />} label="Dev" />
					</RadioGroup>
				</FormControl>
			</CardContent>
		</Card>
	);
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ STOREFRONT TOKEN ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function StorefrontTokenCard() {
	const [tokens, setTokens] = useState();
	const [error, setError] = useState();
	const { merchant } = useMerchant();
	const alert = useAlert();

	useEffect(() => {
		setTokens();
		setError();
		if (!merchant) return;
		API.merchants.storefrontToken
			.get(merchant._id)
			.then(setTokens)
			.catch((e) => setError(e.response.data));
	}, [merchant]);

	const createToken = () => {
		if (window.confirm("Create Storefront Token?")) {
			API.merchants.storefrontToken
				.create(merchant._id)
				.then(({ token }) => {
					setTokens((tokens) => {
						tokens.unshift(token);
						return [...tokens];
					});
					alert.success("Token created!");
				})
				.catch(alert.error);
		}
	};

	const handleDelete = (tokenId) => {
		if (window.confirm("Delete Storefront Token?")) {
			API.merchants.storefrontToken
				.delete(merchant._id, tokenId)
				.then(() => {
					setTokens((tokens) => tokens.filter((t) => t.id !== tokenId));
				})
				.catch(alert.error);
		}
	};

	return (
		<Card>
			<CardHeader title="Storefront Token" />
			<CardContent>
				{tokens || error ? (
					<Stack spacing={2}>
						{error && <Typography color="red">{error}</Typography>}
						{tokens && !tokens?.length && <Typography>No Tokens</Typography>}

						<List>
							{tokens?.map((t) => (
								<ListItem key={t.id}>
									<ListItemText primary={t.access_token + " -- " + t.access_scope} secondary={t.created_at} />
									<ListItemSecondaryAction onClick={() => handleDelete(t.id)}>
										<Delete />
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>

						<Button variant="contained" disable={!!error} onClick={createToken}>
							Create Token
						</Button>
					</Stack>
				) : (
					<Skeleton />
				)}
			</CardContent>
		</Card>
	);
}
