import { Stack, Card, CardHeader, Button, InputAdornment, CardContent, IconButton, TextField, Typography, ToggleButtonGroup, ToggleButton, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { Close, AddCircle } from "@mui/icons-material";

import useAlert from "src/utils/Alert";
import API from "src/API";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import View from "src/components/View";

const ProductView = () => {
	const [isLoading, setLoading] = useState(true);
	const { merchant } = useMerchant();
	const [product, setProduct] = useState();
	const { error, success } = useAlert();

	useEffect(() => {
		if (merchant?._id) {
			API.merchants.product.get(merchant._id).then(setProduct).catch(error).finally(setLoading);
		}
	}, [merchant, error]);

	const handleCreateProduct = () => {
		setLoading(true);
		API.merchants.product
			.create(merchant._id)
			.then(setProduct)
			.then(() => {
				success("Product Created");
			})
			.catch(error)
			.finally(setLoading);
	};

	const handleAddImage = () => {
		setProduct((product) => {
			product._mutated = true;
			product.images.push({ src: "" });
			return { ...product };
		});
	};

	const handleSave = () => {
		setLoading(true);
		API.merchants.product
			.update(merchant._id, product)
			.then(setProduct)
			.then(() => {
				success("Product Saved");
			})
			.catch(error)
			.finally(setLoading);
	};

	const handleRemoveImage = (image) => {
		setProduct((product) => {
			product._mutated = true;
			product.images = product.images.filter((i) => i !== image);
			return { ...product };
		});
	};

	const handleImageSrcChange =
		(image) =>
		({ target }) => {
			setProduct((product) => {
				product._mutated = true;
				image.src = target.value;
				return { ...product };
			});
		};

	const handlePropChange =
		(key) =>
		({ target }) => {
			setProduct((product) => {
				product._mutated = true;
				product[key] = target.value;
				return { ...product };
			});
		};

	return (
		<View.Merchant
			title="Merchant"
			isLoading={isLoading}
			rightMenu={[
				{
					onClick: handleCreateProduct,
					label: "Create Product",
				},
			]}
		>
			<Card>
				<CardHeader title="Product" />
				<CardContent>
					{product ? (
						<Stack spacing={2}>
							<TextField label="Title" fullWidth value={product?.title || ""} onChange={handlePropChange("title")} />
							<TextField label="Handle" fullWidth value={product?.handle || ""} onChange={handlePropChange("handle")} />
							<ToggleButtonGroup exclusive value={product?.status} onChange={(e, value) => handlePropChange("status")({ target: { value } })}>
								<ToggleButton value="draft">Draft</ToggleButton>
								<ToggleButton value="active">Publish</ToggleButton>
							</ToggleButtonGroup>
							<TextField label="Body HTML" multiline rows={3} value={product?.body_html || ""} onChange={handlePropChange("body_html")} />
							<Stack spacing={2}>
								<Stack alignItems="center" spacing={1} direction="row">
									<Typography>Images</Typography>
									<IconButton onClick={handleAddImage}>
										<AddCircle />
									</IconButton>
								</Stack>

								{product?.images?.map((image, i) => (
									<TextField
										key={i}
										placeholder="https://cdn.savedby.io/logos/savedby/SavedByFullLogo320x132.png"
										onChange={handleImageSrcChange(image)}
										label="Image URL"
										value={image.src || ""}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end" onClick={() => handleRemoveImage(image)}>
													<IconButton>
														<Close />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								))}
							</Stack>
							<Button disabled={!product._mutated} onClick={handleSave}>
								Save
							</Button>
						</Stack>
					) : (
						<Stack spacing={2}>
							<Skeleton height={70} />
							<Skeleton height={70} width={150} />
							<Skeleton height={220} />
							<Skeleton width={200} />
							<Skeleton height={70} />
							<Skeleton />
						</Stack>
					)}
				</CardContent>
			</Card>
		</View.Merchant>
	);
};

export default ProductView;
