import { Avatar, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { CgCheck, CgUnavailable } from "react-icons/cg";
import { FaCcAmazonPay, FaCcAmex, FaCcApplePay, FaCcPaypal, FaCcStripe, FaCcVisa } from "react-icons/fa";
import { GoAlert } from "react-icons/go";
import { SiDiscover, SiMastercard, SiShopify } from "react-icons/si";
import capitalize from "src/utils/capitalize";
import pluralize from "src/utils/pluralize";

export default function FinancialCard({ refunds, discounts, totals: { discount, shipping, subtotal, tax, total, savedBy }, shippingLines, payment: { status, method }, lineItems }) {
	// TODO -- multi shipping lines?
	if (!lineItems) return <Skeleton sx={{ width: "100%", height: 345 }} variant="rectangular" />;

	const statusIcon = {
		NOT_PAID: (
			<Avatar sx={{ bgcolor: "orange" }}>
				<GoAlert color="black" />
			</Avatar>
		),
		PAID: (
			<Avatar sx={{ bgcolor: "green" }}>
				<CgCheck size={200} color="white" />
			</Avatar>
		),
		partially_refunded: (
			<Avatar sx={{ bgcolor: "green" }}>
				<CgCheck size={200} color="white" />
			</Avatar>
		),
	}[status];

	return (
		<Card>
			<CardHeader title={<h2>{capitalize(status.replace("_", " "))}</h2>} avatar={statusIcon} />
			<CardContent>
				<Stack spacing={1} sx={{ px: 2 }}>
					{/* SAVEDBY LINE */}
					<Stack direction="row">
						<Typography variant="h5" flex={1}>
							SavedBy
						</Typography>
						<Typography textAlign="right" flex={1}>
							${savedBy}
						</Typography>
					</Stack>

					{/* DISCOUNT LINE */}
					{!!discounts?.length && (
						<Stack direction="row">
							<Typography variant="h5" flex={1}>
								Discount
							</Typography>
							<Typography align="center" flex={1}>
								{discounts[0].code}
							</Typography>
							<Typography textAlign="right" flex={1}>
								${discount}
							</Typography>
						</Stack>
					)}

					{/* SUBTOTAL LINE */}
					<Stack direction="row">
						<Typography variant="h5" flex={1}>
							Subtotal
						</Typography>
						<Typography align="center" flex={1}>
							{pluralize(lineItems.length, "item")}
						</Typography>
						<Typography textAlign="right" flex={1}>
							${subtotal}
						</Typography>
					</Stack>

					{/* SHIPPING LINE */}
					<Stack direction="row">
						<Typography variant="h5" flex={1}>
							Shipping
						</Typography>
						<Typography flex={1} align="center">
							{shippingLines[0]?.name}
						</Typography>
						<Typography textAlign="right" flex={1}>
							${shipping}
						</Typography>
					</Stack>

					{/* TAX LINE */}
					<Stack direction="row">
						<Typography flex={1} variant="h5">
							Tax
						</Typography>
						<Typography align="center" flex={1}>
							?
						</Typography>
						<Typography flex={1} textAlign="right">
							${tax}
						</Typography>
					</Stack>

					<Divider />

					{/* TOTAL */}
					<Stack direction="row">
						<Typography flex={1} variant="h5">
							Total
						</Typography>
						<Typography align="center" flex={1}></Typography>
						<Typography flex={1} textAlign="right">
							${total}
						</Typography>
					</Stack>
				</Stack>
			</CardContent>

			{false && (
				<CardContent>
					<Divider />
					<Grid container>
						<Grid item component={Typography} xs>
							Paid by customer
						</Grid>
						<Grid item xs>
							{getCardIcon(method)}
						</Grid>
						<Grid item component={Typography}>
							{"$total(?)"}
						</Grid>
					</Grid>
				</CardContent>
			)}

			{refunds && (
				<>
					<Divider />

					<CardHeader title={<h2>Refunds</h2>} />
					<CardContent>
						<Stack spacing={1} sx={{ px: 2 }}>
							{refunds.map((r) => (
								<Stack key={r.id} direction="row" justifyContent="space-between">
									<Typography>{new Date(r.createdAt).toLocaleString()}</Typography>
									<Typography>{r.description}</Typography>
									<Typography>{r.amount.replace("-", "-$")}</Typography>
								</Stack>
							))}
						</Stack>
					</CardContent>
				</>
			)}
		</Card>
	);
}

function getCardIcon(gateway, payment) {
	const key = gateway === "shopify_payments" ? payment.credit_card_company : gateway;

	return {
		shopify_payments: <SiShopify size={25} />,
		offline: <CgUnavailable size={25} />,
		amazon_payments: <FaCcAmazonPay size={25} />,
		discover: <SiDiscover size={25} />,
		mastercard: <SiMastercard size={25} />,
		amazon: <FaCcAmazonPay size={25} />,
		visa: <FaCcVisa size={25} />,
		paypal: <FaCcPaypal size={25} />,
		applepay: <FaCcApplePay size={25} />,
		amex: <FaCcAmex size={25} />,
		stripe: <FaCcStripe size={25} />,
	}[key?.toLowerCase()];
}
