import { Avatar, Button, Card, CardContent, CardHeader, ListItemText, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { useState } from "react";
import getInitials from "src/utils/getInitials";
import { BiEdit } from "react-icons/bi";
import { BsLightningFill } from "react-icons/bs";
import { MdComment } from "react-icons/md";
import sentenceCase from "../../utils/sentenceCase";

export default function ClaimTimelineCard({ events, saveClaim, displayName, status }) {
	const handlePost = (type, content) => {
		saveClaim({
			newEvent: { type: "COMMENT", content },
		});
	};

	return events ? (
		<Card sx={{ maxWidth: "100%" }}>
			<CardHeader title="Timeline" />
			<CardContent>
				<TimelineItem>
					<TimelineOppositeContent style={{ flex: 0, paddingLeft: 0, paddingRight: 0 }} />
					<TimelineSeparator>
						<TimelineDot color="sb_blue">
							<Avatar sx={{ bgcolor: "#4DB6FF" }}>{getInitials(displayName)}</Avatar>
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent style={{ paddingRight: 0, marginBottom: 50 }}>
						<CommentField handlePost={handlePost} status={status} />
					</TimelineContent>
				</TimelineItem>

				{events
					.sort((a, b) => b.ts - a.ts)
					.map((e, i) => (
						<TimelineItem key={i}>
							<TimelineOppositeContent style={{ flex: 0, paddingLeft: 12, paddingRight: 0 }} />
							<TimelineSeparator style={{ width: 52 }}>
								<TimelineDot style={{ width: 28, height: 28 }}>{getDotIcon(e)}</TimelineDot>
								{i !== events.length - 1 && <TimelineConnector />}
							</TimelineSeparator>
							<TimelineContent style={{ paddingRight: 0 }}>
								<Stack direction="row">
									{e.type === "ACTION" && <ListItemText primary={e.content ? sentenceCase(e.subType).concat(`: ${e.content}`) : sentenceCase(e.subType)} secondary={e.who} />}
									{e.type === "UPDATED" && <ListItemText primary={e.subType === "Verified Address" ? sentenceCase(e.subType) : "Updated " + sentenceCase(e.subType)} secondary={e.who} />}
									{e.type === "COMMENT" && <ListItemText primary={formatHTML(e.content)} secondary={e.who} sx={{ flex: 1, pr: 1 }} />}
									<Typography noWrap textOverflow="unset">
										{new Date(e.ts).toLocaleString("en", { month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" })}
									</Typography>
								</Stack>
							</TimelineContent>
						</TimelineItem>
					))}
			</CardContent>
		</Card>
	) : (
		<Skeleton variant="rectangle" height={300} />
	);
}

function CommentField({ handlePost, status }) {
	const [comment, setComment] = useState("");
	const [type, setType] = useState("");

	const submitComment = () => {
		handlePost(type, comment);
		setComment("");
		setType("");
	};

	let postDisabled = !!type;
	if (type === "COMMENT" && !comment) {
		postDisabled = false;
	}

	return (
		<Stack direction="row" spacing={1}>
			<TextField
				fullWidth
				value={comment}
				onChange={({ target }) => setComment(target.value)}
				variant="outlined"
				placeholder="Leave a comment..."
				InputProps={{
					endAdornment: (
						<>
							<Button variant="outlined" disabled={postDisabled} onClick={submitComment}>
								POST
							</Button>
						</>
					),
				}}
			/>
		</Stack>
	);
}

function getDotIcon(event) {
	return {
		COMMENT: <MdComment />,
		ACTION: <BsLightningFill />,
		UPDATED: <BiEdit />,
	}[event.type];
}

function formatHTML(text) {
	const formatted = text.replace(/https:\/\/(.*?\.com).*?(?=$|\s)/, (href, domain) => `<a href="${href}">${domain}</a>`);
	return <Typography dangerouslySetInnerHTML={{ __html: formatted }} />;
}
