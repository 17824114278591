import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../../providers/AuthProvider";
import LoadingIndicator from "src/components/LoadingIndicator";
import PerfectScrollbar from "react-perfect-scrollbar";
import API from "src/API";

// Components
import View from "src/components/View";

// Material
import { Avatar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";

// Utils
import useAlert from "../../utils/Alert";
import CheckBox from "src/components/CheckBox";

const UsersView = () => {
	const [isLoading, setLoading] = useState(true);
	const [merchantUsers, setMerchantUsers] = useState([]);
	const [selected, setSelected] = useState();
	const [open, setOpen] = useState();
	const { mid } = useParams();
	const { changeEmail, changePassword } = useAuth();
	const { success } = useAlert();

	useEffect(() => {
		if (!mid) return;
		setLoading(true);
		API.merchants
			.getUsers(mid)
			.then((users) => setMerchantUsers(users))
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, [mid]);

	const noUsers = merchantUsers?.length === 0 && !isLoading;

	const createUser = async (user) => {
		setLoading(true);
		await API.merchants
			.createUser(mid, user)
			.then((user) => {
				setMerchantUsers((users) => [user, ...users]);
				return;
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	};

	const updateUser = async (user) => {
		setLoading(true);
		await API.merchants
			.updateUser(mid, user.uid, user)
			.then(() => {
				setMerchantUsers((users) => {
					const u = users.find((u) => u.uid === user.uid);
					Object.assign(u, user);
					return [...users];
				});
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	};

	const deleteUser = async (uid) => {
		setLoading(true);
		await API.merchants
			.deleteUser(mid, uid)
			.then(() => {
				setMerchantUsers((users) => users.filter((u) => u.uid !== uid));
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	};

	const resetPass = (password) => {
		setLoading(true);
		return new Promise(async (resolve, reject) => {
			await changePassword(password)
				.then(() => {
					resolve();
				})
				.catch(reject)
				.finally(() => setLoading(false));
		});
	};

	const updateEmail = (email) => {
		setLoading(true);
		return new Promise(async (resolve, reject) => {
			await changeEmail(email)
				.then(() => {
					resolve();
				})
				.catch(reject)
				.finally(() => setLoading(false));
		});
	};

	return (
		<View.Merchant title="Merchant" isLoading={isLoading}>
			<Stack direction="row" justifyContent="right">
				<Button variant="contained" onClick={() => setOpen(true)} sx={{ width: "125px" }}>
					Add User
				</Button>
			</Stack>
			<Card>
				<PerfectScrollbar>
					<Table>
						<TableBody>
							<TableRow hover>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
										<Typography variant="h4">User</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
										<Typography variant="h4">Name</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
										<Typography variant="h4">Email</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="right">
										<Typography variant="h4">Last Signed In</Typography>
									</Stack>
								</TableCell>
							</TableRow>
							{merchantUsers?.map((user, i) => {
								const { disabled, displayName, email, metadata } = user;
								return (
									<TableRow hover key={i} onClick={() => setSelected(user)}>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
												<Avatar sx={{ bgcolor: disabled ? "red" : "#00AB55" }}>{displayName.split(" ").map((v) => v[0]?.toUpperCase())}</Avatar>
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
												<Typography>{displayName}</Typography>
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
												<Typography>{email}</Typography>
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="right">
												<Typography>{metadata?.lastSignInTime}</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
						{(noUsers || isLoading) && (
							<TableBody>
								<TableRow>
									<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
										<NoResults isLoading={isLoading} label="No Users" />
									</TableCell>
								</TableRow>
							</TableBody>
						)}
					</Table>
				</PerfectScrollbar>
			</Card>
			<EditUserDialog {...{ selected, onClose: () => setSelected(), updateUser, deleteUser, resetPass, updateEmail, success }} />
			<AddUserDialog {...{ open: open, onClose: () => setOpen(), createUser, success }} />
			<LoadingIndicator isLoading={isLoading} />
		</View.Merchant>
	);
};

export default UsersView;

function EditUserDialog({ selected, onClose, updateUser, deleteUser, success }) {
	const [user, setUser] = useState();
	const [error, setError] = useState();
	const [enable, setEnable] = useState(true);

	useEffect(() => {
		if (selected) {
			setUser(selected);
		} else setUser({});
	}, [selected]);

	const handleRoleToggle = (role) => (v) => {
		setUser((u) => {
			if (!u.roles) u.roles = [];
			if (v) {
				u.roles.push(role);
			} else {
				u.roles = u.roles.filter((s) => s !== role);
			}
			return { ...u };
		});
	};

	const handleUpdate = () => {
		updateUser(user)
			.then(() => {
				setUser();
				onClose();
				success("User has been updated!");
			})
			.catch((e) => {
				setError(e);
			});
	};

	const handleDelete = () => {
		if (window.confirm("Are you sure you want to delete this user? This action cannot be undone.")) {
			deleteUser(user.uid).then(() => {
				setUser();
				onClose();
				success("User has been deleted!");
			});
		}
	};

	const handleActive = () => {
		if (window.confirm("Are you sure you want change this user's active status?")) {
			setUser((u) => ({ ...u, disabled: !u.disabled }));
		}
	};

	return (
		<Dialog fullWidth open={!!selected} onClose={onClose}>
			<DialogTitle>Edit User</DialogTitle>
			{error && <DialogContent sx={{ color: "red" }}>{JSON.stringify(error)}</DialogContent>}
			<DialogContent>
				<Grid container spacing={2} sx={{ p: 2 }}>
					<Grid item xs={12} md={6}>
						<Stack spacing={2}>
							<TextField label="Display Name" value={user?.displayName || ""} onChange={({ target }) => setUser((u) => ({ ...u, displayName: target.value }))} />
							<TextField label="Email" value={user?.email || ""} disabled={enable} onChange={({ target }) => setUser((u) => ({ ...u, email: target.value }))} />
							<TextField label="Password" type="password" disabled={enable} onChange={({ target }) => setUser((u) => ({ ...u, password: target.value }))} />
							<Button onClick={() => setEnable((e) => !e)} variant="contained" color="info">
								Change Email/Password
							</Button>
							<Stack spacing={2}>
								<Button onClick={handleDelete} variant="contained" color="error">
									Delete User
								</Button>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack sx={{ mx: 2 }}>
							<Typography sx={{ px: 1.5, pb: 1 }} variant="h5">
								Status
							</Typography>
							<Box>
								<CheckBox checked={!user?.disabled} onChange={handleActive} label="Active" />
							</Box>
						</Stack>
						<Stack sx={{ mx: 2 }}>
							<Typography sx={{ px: 1.5, py: 1 }} variant="h5">
								Permissions
							</Typography>
							<Box>
								<CheckBox checked={!!user?.roles?.includes("ACCOUNT")} onChange={handleRoleToggle("ACCOUNT")} label="Account MGMT" />
							</Box>
							<Box>
								<CheckBox checked={!!user?.roles?.includes("BILLING")} onChange={handleRoleToggle("BILLING")} label="Billing" />
							</Box>
							<Box>
								<CheckBox checked={!!user?.roles?.includes("CLAIMS")} onChange={handleRoleToggle("CLAIMS")} label="Claims" />
							</Box>
							<Box>
								<CheckBox checked={!!user?.roles?.includes("DASH")} onChange={handleRoleToggle("DASH")} label="Dashboard" />
							</Box>
							<Box>
								<CheckBox checked={!!user?.roles?.includes("USERS")} onChange={handleRoleToggle("USERS")} label="Users" />
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleUpdate}>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function AddUserDialog({ open, onClose, createUser, success }) {
	const [error, setError] = useState();
	const [user, setUser] = useState({
		displayName: "",
		email: "",
		roles: [],
	});

	const handleToggleRole = (role) => (v) => {
		setUser((u) => {
			if (v) {
				u.roles.push(role);
			} else {
				u.roles = u.roles.filter((uu) => uu !== role);
			}
			return { ...u };
		});
	};

	const handleCreate = () => {
		createUser(user)
			.then(() => {
				setUser();
				onClose();
				success("User have been created!");
			})
			.catch((e) => {
				setError(e);
			});
	};

	return (
		<Dialog open={!!open} onClose={onClose}>
			<DialogTitle>New User</DialogTitle>
			{error && <DialogContent sx={{ color: "red" }}>{JSON.stringify(error)}</DialogContent>}
			<DialogContent>
				<Stack spacing={2} sx={{ p: 2 }}>
					<TextField label="Display Name" value={user?.displayName || ""} onChange={({ target }) => setUser((u) => ({ ...u, displayName: target.value }))} />
					<TextField label="Email" value={user?.email || ""} onChange={({ target }) => setUser((u) => ({ ...u, email: target.value }))} />
					<TextField type="password" label="Password" value={user?.password || ""} onChange={({ target }) => setUser((u) => ({ ...u, password: target.value }))} />

					<Typography>Permissions</Typography>
					<Stack>
						<Box>
							<CheckBox checked={!!user?.roles?.includes("ACCOUNT")} onChange={handleToggleRole("ACCOUNT")} label="Account MGMT" />
						</Box>
						<Box>
							<CheckBox checked={!!user?.roles?.includes("BILLING")} onChange={handleToggleRole("BILLING")} label="Billing" />
						</Box>
						<Box>
							<CheckBox checked={!!user?.roles?.includes("CLAIMS")} onChange={handleToggleRole("CLAIMS")} label="Claims" />
						</Box>
						<Box>
							<CheckBox checked={!!user?.roles?.includes("DASH")} onChange={handleToggleRole("DASH")} label="Dashboard" />
						</Box>
						<Box>
							<CheckBox checked={!!user?.roles?.includes("USERS")} onChange={handleToggleRole("USERS")} label="Users" />
						</Box>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCreate} disabled={!(user?.displayName && user?.email && user?.roles?.length > 0)}>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function NoResults({ isLoading, label = "No Results", ...other }) {
	return (
		<Paper {...other}>
			<Typography gutterBottom align="center" variant="subtitle1">
				{isLoading ? "Loading..." : label}
			</Typography>
			<Typography variant="body2" align="center"></Typography>
		</Paper>
	);
}
