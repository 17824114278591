import { Cached, CheckCircle, ContentCopy, Help, HourglassBottom, LocationOff, Pending, Schedule, SupportAgent, Undo, WhereToVote } from "@mui/icons-material";
import { Chip } from "@mui/material";

export default function StatusChip({ variant, status, subStatus, sx = {} }) {
	const customStyle = {
		claim: {
			DRAFT: { bgcolor: "yellow", color: "black" },
			REVIEWING: { bgcolor: "orange", color: "black" },
			PROCESSING: { bgcolor: "blue", color: "white" },
			RESOLVED: { bgcolor: "green", color: "white" },
			DENIED: { bgcolor: "red", color: "white" },
		},
	}[variant][status];

	const icon = {
		IN_REVIEW: <SupportAgent style={{ color: "black" }} />,
		AWAITING_SHIPMENT: <Schedule style={{ color: "white" }} />,
		OTHER: <Help style={{ color: "white" }} />,
		WRONG_DELIVERY_ADDRESS: <LocationOff style={{ color: "white" }} />,
		PENDING: <SupportAgent style={{ color: "white" }} />,
		PROCESSING: <Cached style={{ color: "white" }} />,
		PROCESSED: <CheckCircle style={{ color: "white" }} />,
		DELIVERED: <WhereToVote style={{ color: "black" }} />,
		RETURN_TO_SENDER: <Undo style={{ color: "white" }} />,
		CUSTOMER_INACTIVITY: <HourglassBottom style={{ color: "black" }} />,
		DUPLICATE: <ContentCopy style={{ color: "black" }} />,
		UNFULFILLED: <Pending style={{ color: "black" }} />,
	}[subStatus];

	return <Chip {...{ sx }} label={status} icon={icon} title={subStatus} sx={{ ...customStyle, ...sx }} />;
}
