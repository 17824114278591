import { Card, CardContent, CardHeader, Skeleton, Stack, TextField, Typography } from "@mui/material";
import CheckBox from "../../components/CheckBox";
import useEdit from "src/useEdit";
import { withErrorBoundary } from "@sentry/react";
import withLoading from "../../utils/withLoading";
import FallBackCard from "../../components/FallBackCard";

function ShippingCard({ shippingAddress, saveClaim, addressVerified, editDisabled: disabled }) {
	const [data, setData, { isEditing, CardAction }] = useEdit(shippingAddress, (shippingAddress) => saveClaim({ shippingAddress, newEvent: { type: "UPDATED", subType: "Shipping Address" } }), {
		disabled,
	});

	const handleUpdate =
		(key) =>
		({ target }) => {
			setData((d) => {
				d[key] = target.value;
				return { ...d };
			});
		};

	if (!data) return null;
	return (
		<Card>
			<CardHeader title="Shipping Address" action={CardAction} />

			<CardContent>
				{isEditing ? (
					<Stack spacing={1}>
						<TextField label="Name" variant="standard" value={data.name} onChange={handleUpdate("name")} />
						<TextField label="Address 1" variant="standard" value={data.address1} onChange={handleUpdate("address1")} />
						<TextField label="Address 2" variant="standard" value={data.address2} onChange={handleUpdate("address2")} />

						<TextField label="City" variant="standard" value={data.city} onChange={handleUpdate("city")} />
						<TextField label="Province" variant="standard" value={data.provinceCode} onChange={handleUpdate("provinceCode")} />
						<TextField label="Zip Code" variant="standard" value={data.zipCode} onChange={handleUpdate("zipCode")} />

						<TextField label="Country" variant="standard" value={data.country} onChange={handleUpdate("country")} />
					</Stack>
				) : (
					<Stack>
						<Typography>{data.name}</Typography>
						<Typography>{data.address1}</Typography>
						<Typography>{data.address2}</Typography>
						<Typography>
							{data.city}, {data.provinceCode} {data.zipCode}
						</Typography>
						<Typography>{data.country}</Typography>
					</Stack>
				)}
			</CardContent>
			<CardContent>
				<CheckBox label="Address Verified" checked={addressVerified} onChange={(v) => saveClaim({ addressVerified: v, newEvent: { type: "UPDATED", subType: "Verified Address" } })} />
			</CardContent>
		</Card>
	);
}

function Loading() {
	return (
		<Card>
			<CardHeader title="Shipping Address" />

			<CardContent>
				<Stack>
					<Skeleton width={200} />
					<br />
					<Skeleton width={60} />
					<Skeleton width={120} />
					<Skeleton width={60} />
					<Skeleton width={120} />
				</Stack>
			</CardContent>
		</Card>
	);
}

export default withErrorBoundary(withLoading(ShippingCard, Loading), { fallback: FallBackCard("Shipping Address", "Error loading shipping address") });
