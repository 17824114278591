import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CodeEditorView from "src/components/CodeEditorView";
import API from "src/API";
import useAlert from "src/utils/Alert";
import { Stack, Grid, Card, CardContent, Skeleton, Chip, Menu, MenuItem, Button, Typography, Divider, Dialog, DialogTitle, DialogContent, TextField, IconButton } from "@mui/material";
import { Trash } from "react-feather";

export default function ReportView() {
	const [isLoading, setLoading] = useState(true);
	const [report, setReport] = useState();
	const [anchorEl, setAnchorEl] = useState(false);
	const [addAdjustmentOpen, setAddAdjustmentOpen] = useState(true);
	const nav = useNavigate();
	const { error, info, success } = useAlert();
	const { id, mid } = useParams();

	useEffect(() => {
		API.reports
			.getById(mid, id)
			.then(({ report }) => setReport(report))
			.catch(error)
			.finally(setLoading);
	}, [error, mid, id]);

	const handleAddAdjustment = (newAdjustment) => {
		setLoading(true);

		const adjustments = [...report.adjustments, newAdjustment];

		API.reports
			.setAdjustment(mid, id, adjustments)
			.then(() => {
				setReport((r) => ({
					...r,
					adjustments,
				}));
			})
			.catch(error)
			.finally(setLoading);
	};

	const handleRemoveAdjustment = (adjustment) => {
		setLoading(true);

		const adjustments = report.adjustments.filter((a) => a !== adjustment);

		API.reports
			.setAdjustment(mid, id, adjustments)
			.then(() => {
				setReport((r) => ({
					...r,
					adjustments: r.adjustments,
					chargeTotal: r.chargeTotal,
				}));
			})
			.catch(error)
			.finally(setLoading);
	};

	const handleCharge = () => {
		const amount = window.prompt("Amount to charge", report.chargeTotal || "0.00") || "0.00";
		if (window.confirm("Charge $" + amount + "?")) {
			info("Charging merchant...");
			API.merchants
				.charge(mid, amount, report.name, report.id)
				.then(() => success("Charged!"))
				.catch(error);
		}
	};

	return (
		<CodeEditorView open={false} title="Reports" isLoading={isLoading}>
			<Stack direction="row" justifyContent="space-between">
				<Button onClick={() => nav(-1)} variant="outlined">
					Back
				</Button>

				<Button onClick={({ target }) => setAnchorEl(target)} variant="outlined">
					menu
				</Button>
				<Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl()}>
					<MenuItem disabled={!!report?.chargedOn} onClick={handleCharge}>
						Charge
					</MenuItem>
					<MenuItem disabled={!!report?.chargedOn} onClick={() => setAddAdjustmentOpen(true)}>
						Add Adjustment
					</MenuItem>
				</Menu>
			</Stack>
			<Stack spacing={1}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TopCard {...{ report }} />
					</Grid>
					<Grid item lg={8} xs={12}>
						<FinancialCard {...{ report, handleRemoveAdjustment }} />
					</Grid>
				</Grid>
			</Stack>
			<AdjustmentDialog {...{ open: addAdjustmentOpen, onClose: () => setAddAdjustmentOpen(false), handleAddAdjustment }} />
		</CodeEditorView>
	);
}

function AdjustmentDialog({ open, onClose, handleAddAdjustment }) {
	const [amount, setAmount] = useState(0);
	const [details, setDetails] = useState("");
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Adjustment</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<TextField fullWidth variant="outlined" label="Amount" value={amount} onChange={({ target }) => setAmount(target.value)} />
					<TextField fullWidth variant="outlined" label="Details" value={details} onChange={({ target }) => setDetails(target.value)} />
					<Button fullWidth variant="contained" onClick={() => handleAddAdjustment({ amount, details })}>
						Add
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function TopCard({ report }) {
	return report ? (
		<Card>
			<CardContent>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography>{report.id}</Typography>
					{report.status === "PENDING" && <Chip label="UNPAID" sx={{ bgcolor: "orange" }} />}
					{report.status === "PAID" && <Chip label={"Charged On " + new Date(report?.chargedOn).toLocaleDateString()} sx={{ bgcolor: "green" }} />}
				</Stack>
			</CardContent>
		</Card>
	) : (
		<Skeleton />
	);
}

function FinancialCard({ report, handleRemoveAdjustment }) {
	return report ? (
		<Card>
			<CardContent>
				<Stack spacing={2}>
					<Stack direction="row" justifyContent="space-between">
						<Typography>SavedBy Collection ({report.savedByCount})</Typography>
						<Typography>$ {report.savedByTotal}</Typography>
					</Stack>

					<Stack direction="row" justifyContent="space-between">
						<Typography>Claim Refunds ({report.claimRefundCount})</Typography>
						<Typography>-$ {report.claimRefundTotal}</Typography>
					</Stack>

					<Stack direction="row" justifyContent="space-between">
						<Typography>Claim Reorders ({report.claimReorderCount})</Typography>
						<Typography>-$ {report.claimReorderTotal}</Typography>
					</Stack>

					<Stack direction="row" justifyContent="space-between">
						<Typography>Commission</Typography>
						<Typography>-$ {report.commissionTotal}</Typography>
					</Stack>

					<Stack direction="row" justifyContent="space-between">
						<Typography>Adjustments ({report.adjustments.length})</Typography>
						<Typography>$ {report.adjustments.length}</Typography>
					</Stack>

					{report.adjustments.map((a, i) => (
						<Stack key={i} direction="row" justifyContent="space-between">
							<Stack direction="row" alignItems="center">
								<Typography>- {a.details}</Typography>
								<IconButton onClick={() => handleRemoveAdjustment(a)} size="small">
									<Trash size={20} />
								</IconButton>
							</Stack>

							<Typography>{a.amount}</Typography>
						</Stack>
					))}

					<Divider />

					<Stack direction="row" justifyContent="space-between">
						<Typography>Net Owed</Typography>
						<Typography>$ {report.chargeTotal}</Typography>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	) : (
		<Skeleton variant="rectangular" height={250}></Skeleton>
	);
}
