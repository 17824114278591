const merchantColors = {
	INSTALLING: { bgcolor: "cyan", color: "white" },
	REINSTALLING: { bgcolor: "cyan", color: "white" },
	REGISTERING: { bgcolor: "deepskyblue", color: "black" },
	ONBOARDING: { bgcolor: "deepskyblue", color: "black" },
	DEVELOPMENT: { bgcolor: "purple", color: "white" },
	TESTING: { bgcolor: "violet", color: "black" },
	ACTIVE: { bgcolor: "green", color: "white" },
	INACTIVE: { bgcolor: "darkred", color: "white" },
	UNINSTALLED: { bgcolor: "darkred", color: "white" },
	PAUSED: { bgcolor: "orange", color: "black" },
};

export default merchantColors;
