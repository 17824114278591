const { Warning } = require("@mui/icons-material");
const { Card, CardHeader, Stack, Typography, CardContent } = require("@mui/material");

function FallBackCardComponent({ header, message, ...props }) {
	return (
		<Card>
			{header && <CardHeader title={header} />}
			<CardContent>
				<Stack alignItems="center">
					<Warning color="warning" />
					<Typography>{message}</Typography>
				</Stack>
			</CardContent>
		</Card>
	);
}

export default function FallBackCard(header, message) {
	return (props) => FallBackCardComponent({ header, message, ...props });
}
