import { useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import { useDebug } from "src/providers/DebugProvider";
import { IconButton } from "@mui/material";
import { Code } from "@mui/icons-material";

export default function useAlert() {
	const { enqueueSnackbar } = useSnackbar();
	const { setDebug } = useDebug();

	const handleClick = useCallback(
		(error) => {
			setDebug(error);
		},
		[setDebug]
	);

	return useMemo(
		() => ({
			default: (message, opts = {}) => enqueueSnackbar(checkMessage(message), { variant: "default", ...opts }),
			success: (message, opts = {}) => enqueueSnackbar(checkMessage(message), { variant: "success", ...opts }),
			error: (message, opts = {}) =>
				enqueueSnackbar(checkMessage(message), {
					autoHideDuration: 20000,
					variant: "error",
					action: (
						<IconButton onClick={() => handleClick(message)}>
							<Code />
						</IconButton>
					),
					...opts,
				}),
			info: (message, opts = {}) => enqueueSnackbar(checkMessage(message), { variant: "info", ...opts }),
			warning: (message, opts = {}) => enqueueSnackbar(checkMessage(message), { variant: "warning", ...opts }),
		}),
		[enqueueSnackbar, handleClick]
	);
}

function checkMessage(m) {
	if (typeof m !== "string") {
		console.error("ALERT ERROR:", typeof m, m);
		return String(m);
	} else return m;
}
