import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Check, CheckCircleOutline, Close, Replay } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import API from "src/API";
import Money from "src/Money";

export default function ProcessDialog({ open, onClose, handleManualProcess, claim }) {
	const [originalOrder, setOriginalOrder] = useState();
	const [reorder, setReorder] = useState();
	const [reorderId, setReorderId] = useState(claim?.reorderId || "");
	const [storeCredit, setStoreCredit] = useState(0.0);
	const [explanation, setExplanation] = useState("");

	function handleSubmit() {
		if (window.confirm("Process this Claim?")) {
			const data = {
				resolvedAt: reorder?.createdAt || savedByRefunds[0]?.createdAt || Date.now(),
				explanation,
				storeCreditTotal: storeCredit.toFixed(2) || "0.00",
				claimTotal: (+shopifyTotal + +storeCredit).toFixed(2),
				originalShippingTotal: originalShippingAmount.toFixed(2) || "0.00",
			};

			if (reorder) {
				data.reorderId = reorder._id;
				data.reorderName = reorder.name;
			}

			if (isRTSReorder) {
				data.RTSReorderTotal = reorderShippingTotal;
				data.reorderShippingTotal = "0.00";
				data.reorderTotal = "0.00";
			} else {
				data.reorderShippingTotal = reorderShippingTotal || "0.00";
				data.reorderTotal = reorderSubtotal;
				data.RTSReorderTotal = "0.00";
			}

			if (isRTSRefund) {
				data.refundTotal = "0.00";
				data.refundShippingTotal = "0.00";
				data.RTSRefundTotal = originalShippingAmount.toFixed(2);
			} else {
				data.refundTotal = Number(savedByRefundSubtotal).toFixed(2);
				data.refundShippingTotal = Number(originalShippingAmount).toFixed(2);
				data.RTSRefundTotal = "0.00";
			}
			handleManualProcess(data);
		}
	}

	const getOriginalOrder = useCallback(() => {
		if (!claim?.mid || !claim?.orderId) return;
		API.merchants
			.getStoreOrder(claim.mid, claim.orderId)
			.then(({ order }) => setOriginalOrder(order))
			.catch(console.error);
	}, [claim?.mid, claim?.orderId]);

	const getReorder = useCallback(
		(reorderId) => {
			if (!claim?.mid) return;
			API.merchants
				.getStoreOrder(claim.mid, reorderId)
				.then(({ order }) => setReorder(order))
				.catch(console.error);
		},
		[claim?.mid]
	);

	useEffect(() => {
		getOriginalOrder();
		setReorderId(claim?.reorderId || "");
		if (claim?.reorderId) {
			getReorder(claim?.reorderId);
		} else setReorder();
	}, [claim, getOriginalOrder, getReorder]);

	if (!claim || !originalOrder) return null;

	// REFUNDS
	const isRTSRefund = claim.RTSRefundTotal > 0;
	const claimRefundTotal = isRTSRefund ? +claim.RTSRefundTotal : +claim.refundTotal;
	const claimShipping = isRTSRefund ? +claim.RTSRefundTotal : +claim.refundShippingTotal;

	const [originalShipping] = originalOrder.refunds?.map((r) => r.orderAdjustments.filter((orderAdjustment) => orderAdjustment?.kind === "shipping_refund")?.[0]);
	const originalShippingAmount = Math.abs(+originalShipping?.amount + +originalShipping?.tax_amount) || 0;

	const savedByRefunds = originalOrder.refunds?.filter((r) => /SavedBy/i.test(r.description));
	const savedByRefundSubtotal = savedByRefunds
		?.flatMap((r) => r.lineItems)
		.reduce((p, c) => p.add(c.total), new Money())
		.toString();

	const savedByRefundTotal = savedByRefunds.reduce((p, c) => p.add(c.amount), new Money()).toString();

	const restockType = originalOrder.refunds?.map((r) => r.lineItems.map((rr) => rr.restock_type));
	const isRestocked = restockType.filter((restock) => restock !== "no_restock");

	const isRefundShippingVerified = originalShippingAmount === claimShipping;
	const isRefundSubtotalVerified = +claimRefundTotal === +savedByRefundSubtotal;
	const isAllRefundVerified = isRefundSubtotalVerified && isRefundShippingVerified;

	// REORDERS
	const isRTSReorder = claim.reorderCount && claim.reason === "Returned to Sender";
	const claimItems = claim?.items?.filter((i) => i.reorderCount);
	const reorderSubtotal = reorder?.lineItems?.reduce((p, c) => p.add(c.linePrice), new Money()).toString() || 0;

	const isItemsVerified = claimItems?.every((item) => {
		const matched = reorder?.lineItems?.find((li) => +li.variantId === item.variantId);
		return matched?.quantity === item.reorderCount;
	});

	const isTotalVerified = +reorderSubtotal === +claim?.reorderTotal;
	const isAllReorderVerified = isItemsVerified && isTotalVerified;

	// TOTAL
	const reorderShippingTotal = isRTSReorder ? reorder?.totals?.total : +claim.reorderShippingTotal || +reorder?.totals?.shipping + +reorder?.shippingLines[0]?.tax || 0;
	const shopifyTotal = isRTSReorder ? reorder?.totals?.total : +(reorder?.totals?.total || 0) + +savedByRefundTotal;
	const diff = new Money(shopifyTotal).sub(claim.reorderTotal).sub(claim.refundTotal).sub(reorderShippingTotal).toFloat();
	const isAllVerified = isAllReorderVerified && isAllRefundVerified && !diff;

	// STORE CREDIT
	// const claimStoreCreditTotal = +claim.storeCredit;
	// Do this or will Shopify total suffice since the original order total we grab is the total - refunds
	const storeCreditSanityCheck = originalOrder.totals.total - shopifyTotal;
	// const storeCreditSanityCheck = originalOrder.totals.total - reorderSubtotal - reorderShippingTotal - savedByRefundTotal;
	const isStoreCreditVerified = storeCredit < storeCreditSanityCheck;

	return (
		<Dialog open={!!open} onClose={onClose}>
			<DialogTitle variant="h2">Manually Process Claim</DialogTitle>
			<DialogContent>
				<Stack spacing={4}>
					{!!claim?.refundCount && (
						<RefundCheck
							{...{
								getOriginalOrder,
								originalOrder,
								claimRefund: claimRefundTotal,
								claimShipping,
								isRestocked,
								originalShippingAmount,
								isRefundShippingVerified,
								savedByRefundSubtotal,
								isRefundSubtotalVerified,
								isAllRefundVerified,
							}}
						/>
					)}
					{!!claim?.reorderCount && <ReorderCheck {...{ reorder, reorderId, setReorderId, getReorder, isItemsVerified, isTotalVerified, isAllReorderVerified, claim, reorderSubtotal }} />}
					{!!claim?.storeCreditCount && <StoreCreditCheck {...{ storeCredit, setStoreCredit, isStoreCreditVerified }} />}
				</Stack>
			</DialogContent>

			<Collapse in={!isAllVerified}>
				<TextField sx={{ p: 1 }} fullWidth multiline label="Explanation why all checks do not pass" rows={5} value={explanation} onChange={({ target }) => setExplanation(target.value)} />
			</Collapse>
			<DialogActions>
				<Stack direction="row" spacing={2} alignItems="center">
					<Typography>
						<b>Claim:</b> {toCurrency(claim?.claimTotal)}
					</Typography>
					<Typography display={reorderShippingTotal <= 0 && "none"}>
						+ <b>Shipping:</b> {toCurrency(reorderShippingTotal)}
					</Typography>
					<Typography>
						= <b>Shopify:</b> {toCurrency(shopifyTotal)}
					</Typography>
					{!!diff && <Typography color="red">({toCurrency(diff)})</Typography>}
					<Button disabled={!isAllVerified && !explanation} onClick={handleSubmit}>
						Submit
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
}

function RefundCheck({
	getOriginalOrder,
	originalOrder,
	claimRefund,
	claimShipping,
	isRestocked,
	originalShippingAmount,
	isRefundShippingVerified,
	savedByRefundSubtotal,
	isRefundSubtotalVerified,
	isAllRefundVerified,
}) {
	return (
		<Stack direction="row" spacing={2}>
			{isAllRefundVerified ? <CheckCircleOutline fontSize="large" color="success" /> : <Close fontSize="large" color="error" />}
			<Stack>
				<Stack direction="row" alignItems="center" spacing={3}>
					<Typography variant="h3">Refunds</Typography>
					<IconButton onClick={getOriginalOrder} disabled={!originalOrder.refunds.length}>
						<Replay />
					</IconButton>
				</Stack>
				<Stack spacing={1}>
					<Stack direction="row" spacing={2}>
						{isRefundSubtotalVerified ? <Check color="success" /> : <Close color="error" />}
						<Typography>Subtotal</Typography>
						<Typography>{toCurrency(claimRefund)}</Typography>
						{!isRefundSubtotalVerified && <Typography color="red">{toCurrency(savedByRefundSubtotal)}</Typography>}
					</Stack>
					<Stack direction="row" spacing={2}>
						{isRefundShippingVerified ? <Check color="success" /> : <Close color="error" />}
						<Typography>Shipping</Typography>
						<Typography>{toCurrency(claimShipping)}</Typography>
						{!isRefundShippingVerified && <Typography color="red"> {toCurrency(originalShippingAmount)}</Typography>}
					</Stack>
					<Stack direction="row" spacing={2}>
						{isRestocked ? <Check color="success" /> : <Close color="error" />}
						<Typography>Correct Restock Option</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
}

function ReorderCheck({ reorderId, setReorderId, getReorder, isAllReorderVerified, isItemsVerified, isTotalVerified, reorderSubtotal, claim, reorder }) {
	return (
		<Stack direction="row" spacing={2}>
			{isAllReorderVerified && reorder ? <CheckCircleOutline fontSize="large" color="success" /> : <Close fontSize="large" color="error" />}

			<Stack>
				<Stack direction="row" alignItems="center" spacing={3}>
					<Typography variant="h3">Reorders</Typography>
					<TextField size="small" value={reorderId} onChange={({ target }) => setReorderId(target.value)} InputProps={{ startAdornment: <b style={{ paddingRight: 4 }}>ID: </b> }} />
					<IconButton onClick={() => getReorder(reorderId)}>
						<Replay />
					</IconButton>
				</Stack>
				<Stack spacing={1} display={!reorder && "none"}>
					<Stack direction="row" spacing={2}>
						{isItemsVerified ? <Check color="success" /> : <Close color="error" />}
						<Typography>Items</Typography>
					</Stack>
					<Stack direction="row" spacing={2}>
						{isTotalVerified ? <Check color="success" /> : <Close color="error" />}
						<Typography>Subtotal</Typography>
						<Typography>{toCurrency(claim.reorderTotal)}</Typography>
						{!isTotalVerified && <Typography color="red">{toCurrency(reorderSubtotal)}</Typography>}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
}

function StoreCreditCheck({ storeCredit, setStoreCredit, isStoreCreditVerified }) {
	return (
		<Stack direction="row" spacing={2}>
			{isStoreCreditVerified ? <CheckCircleOutline fontSize="large" color="success" /> : <Close fontSize="large" color="error" />}
			<Stack>
				<Stack direction="row" alignItems="center" spacing={3}>
					<Typography variant="h3">Store Credit</Typography>
					<TextField
						size="small"
						type="number"
						inputProps={{ step: 0.01, min: 0 }}
						label="Store Credit Total"
						value={storeCredit.toString()}
						onChange={({ target: { value } }) => setStoreCredit((v) => (value > -1 ? +value : v))}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
}

function toCurrency(value, currency = "USD") {
	return Number(value).toLocaleString("en", { style: "currency", currency });
}
