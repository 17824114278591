import { colors, createTheme } from "@mui/material";
import shadows from "./shadows";
import typography from "./typography";
import components from "./components";

const theme = createTheme({
	palette: {
		background: {
			default: "#F4F6F8",
			paper: colors.common.white,
		},
		primary: {
			contrastText: "#ffffff",
			main: "#5664d2",
		},
		secondary: {
			contrastText: "#ffffff",
			main: colors.red[400],
		},
		sb_blue: {
			main: "#4DB6FF",
		},
		sb_orange: {
			main: "#f4851c",
		},
		good: {
			contrastText: "white",
			main: colors.green[500],
		},
		text: {
			primary: "#172b4d",
			secondary: "#6b778c",
		},
	},
	shadows,
	typography,
	components,
});

export default theme;
