import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

firebase.initializeApp({
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
});

// Initialize Firebase
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
process.env.NODE_ENV === "development" && firebase.functions().useEmulator("10.1.10.24", 5002);

export default firebase;

/**
 * @param {File} file Image File
 * @param {String} mid Merchant ID
 *
 * @returns {Promise}
 */
export async function uploadMerchantLogo(file, mid) {
	const storageRef = firebase.storage().ref();
	const imageRef = storageRef.child(`/logos/merchants/${mid}.png`);
	const r = await imageRef.put(file);
	return r;
}

/**
 *
 * @param {String} path
 * @returns {firebase.storage.Reference[]}
 */
export async function listFiles(path = "") {
	const storageRef = firebase.storage().ref();
	const dirRef = storageRef.child(path);
	const files = await dirRef.listAll();
	return files.items;
}
