import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import API from "src/API";

// material
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableRow, Typography, Chip, Avatar } from "@mui/material";

// components and utils
import useAlert from "../utils/Alert";
import LoadingIndicator from "src/components/LoadingIndicator";
import View from "src/components/View";

export default function SalesBookingsView() {
	const [isLoading, setLoading] = useState(false);
	const [bookings, setBookings] = useState([]);
	const [selected, setSelected] = useState();
	const [salesReps, setSalesReps] = useState([]);
	const { success, error } = useAlert();

	useEffect(() => {
		setLoading(true);
		Promise.all([API.sales.getBookings(), API.sales.getUsers()])
			.then(([bookings, salesReps]) => {
				setBookings(bookings);
				setSalesReps(salesReps);
			})
			.catch(error)
			.finally(() => setLoading(false));
	}, [error]);

	const handleRemoveAssigned = (bookingId) => () => {
		setLoading(true);
		API.sales
			.updateBooking(bookingId, { assignedTo: null })
			.then(() => {
				setBookings((bookings) => {
					const booking = bookings.find((b) => b._id === bookingId);
					booking.assignedTo = null;
					return [...bookings];
				});
				success("Booking Unassigned");
			})
			.catch(error)
			.finally(() => setLoading(false));
	};

	const handleUpdate = async (bookingId, uid) => {
		setLoading(true);
		await API.sales
			.updateBooking(bookingId, { assignedTo: uid })
			.then(() => {
				setBookings((bookings) => {
					const booking = bookings.find((b) => b._id === bookingId);
					booking.assignedTo = uid;
					return [...bookings];
				});
				success("Booked Call updated!");
			})
			.catch(error)
			.finally(() => setLoading(false));
	};

	const noBookings = bookings.length === 0 && !isLoading;

	if (!bookings || !salesReps) return null;

	return (
		<View title="Booked Calls" isLoading={isLoading}>
			<Card>
				<PerfectScrollbar>
					<Table>
						<TableBody>
							<TableRow hover>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
										<Typography variant="h4">Company Name</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
										<Typography variant="h4">Name</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
										<Typography variant="h4">Email</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
										<Typography variant="h4">Phone</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="right">
										<Typography variant="h4">Assigned To</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={2} alignItems="center" justifyContent="right">
										<Typography variant="h4">Created At</Typography>
									</Stack>
								</TableCell>
							</TableRow>
							{bookings?.map((booking, i) => {
								const { companyName, name, email, phone, assignedTo, createdAt, _id } = booking;
								const salesRep = salesReps.find((s) => s.uid === assignedTo);
								return (
									<TableRow hover key={i} onClick={() => setSelected(booking)}>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
												<Typography>{companyName}</Typography>
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
												<Typography>{name}</Typography>
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
												<Typography>{email}</Typography>
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="left">
												<Typography>{phone}</Typography>
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="right">
												{assignedTo && (
													<Chip
														color="primary"
														avatar={<Avatar>{salesRep?.displayName?.split(" ").map((n) => n[0])}</Avatar>}
														label={salesRep?.displayName || assignedTo}
														onDelete={handleRemoveAssigned(_id)}
													/>
												)}
											</Stack>
										</TableCell>
										<TableCell>
											<Stack direction="row" spacing={2} alignItems="center" justifyContent="right">
												<Typography>{new Date(createdAt).toLocaleString()}</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
						{(noBookings || isLoading) && (
							<TableBody>
								<TableRow>
									<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
										<NoResults isLoading={isLoading} label="No Bookings" />
									</TableCell>
								</TableRow>
							</TableBody>
						)}
					</Table>
				</PerfectScrollbar>
			</Card>
			<EditBookingDialog {...{ selected, salesReps, onClose: () => setSelected(), handleUpdate }} />
			<LoadingIndicator isLoading={isLoading} />
		</View>
	);
}

function EditBookingDialog({ selected, salesReps, onClose, handleUpdate }) {
	const [selectedRep, setSelectedRep] = useState();

	const handleSelected = (event) => {
		setSelectedRep(event.target.value);
	};

	return (
		<Dialog fullWidth open={!!selected} onClose={onClose}>
			<DialogTitle>Edit Demo</DialogTitle>

			<DialogContent sx={{ pb: 0 }}>
				<Grid container sx={{ px: 1 }}>
					{/* <Grid item xs={12} md={6}>
						<Stack spacing={2}>
							<TextField label="Company Name" value={booking?.companyName || ""} onChange={({ target }) => setBooking((b) => ({ ...b, companyName: target.value }))} />
							<TextField label="Email" value={booking?.email || ""} disabled={enable} onChange={({ target }) => setBooking((b) => ({ ...b, email: target.value }))} />
							<TextField label="Phone" value={booking?.phone || ""} disabled={enable} onChange={({ target }) => setBooking((b) => ({ ...b, phone: target.value }))} />
							<Button onClick={() => setEnable((e) => !e)} variant="contained" color="info">
								Change Contact Information
							</Button>
							<Stack spacing={2}>
								<Button onClick={} variant="contained" color="error">
									Delete Booking
								</Button>
							</Stack>
						</Stack>
					</Grid> */}
					<Grid item xs={12}>
						<Stack sx={{ mx: 2 }}>
							<Typography>Assign A Sales Rep To This Demo</Typography>
							<Select value={selectedRep || "default"} onChange={(e) => handleSelected(e)}>
								<MenuItem value={"default"} disabled>
									Assign Sales Rep
								</MenuItem>
								{salesReps.map((s, i) => (
									<MenuItem value={s} key={i}>
										{s.displayName}
									</MenuItem>
								))}
							</Select>
						</Stack>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={() => handleUpdate(selected._id, selectedRep.uid) && onClose()} fullWidth sx={{ mx: 5, mb: 1 }}>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function NoResults({ isLoading, label = "No Results", ...other }) {
	return (
		<Paper {...other}>
			<Typography gutterBottom align="center" variant="subtitle1">
				{isLoading ? "Loading..." : label}
			</Typography>
			<Typography variant="body2" align="center"></Typography>
		</Paper>
	);
}
