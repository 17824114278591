import { CheckCircle, Circle, CircleOutlined } from "@mui/icons-material";
import { Button, Stack, List, Grid, Typography, ListItemButton, ListSubheader, Box } from "@mui/material";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import API from "src/API";
import LoadingIndicator from "src/components/LoadingIndicator";
import View from "src/components/View";
import useAlert from "src/utils/Alert";
import PDFView from "./PDFView";
import CreateStatement from "./CreateStatementCard";
import ChargeDialog from "./ChargeDialog";

export default function StatementsView() {
	const { success, error } = useAlert();
	const [selectedStatement, setSelectedStatement] = useState();
	const [isChargeOpen, setChargeOpen] = useState();
	const [statements, setStatements] = useState([]);
	const [loading, setLoading] = useState();

	const { data: merchants, isLoading } = useQuery("merchants", () => API.merchants.getAll().then((r) => r.merchants));
	useQuery("statements", () => API.statements.getAll().then((r) => r.statements), { onSuccess: setStatements });

	const sendPDF = (id) => {
		API.statements
			.send(id)
			.then(() => success("Statement Sent!"))
			.catch(error);
	};

	const handleDelete = (statement) => {
		let confirm = window.confirm(`Are you sure you want to delete this statement and PDF?\n${statement.merchant.name} - ${statement.period.date}`);
		if (confirm != null) {
			API.statements
				.delete(statement._id)
				.then(() => {
					setStatements((statements) => statements.filter((s) => s._id !== statement._id));
					success("Statement and PDF Deleted");
				})
				.catch(error);
		} else return;
	};

	const handleCharge = ({ appChargeId, description, chargeAmount, paymentMethod }) => {
		setLoading(true);
		if (description) {
			description += " " + selectedStatement.period.date;
		}
		return new Promise((resolve, reject) => {
			API.statements
				.charge(selectedStatement._id, {
					paymentMethod,
					description,
					appChargeId,
					amount: Number(chargeAmount),
				})
				.then(({ statement }) => {
					setStatements((s) => {
						Object.assign(selectedStatement, statement);
						return [...s];
					});

					setChargeOpen(false);
					success(`Charge Successful`);
					resolve();
				})
				.catch(reject)
				.finally(setLoading);
		});
	};

	const handleSkipStatement = () => {
		setLoading(true);
		API.statements
			.update(selectedStatement._id, { status: "ROLLING" })
			.then(({ statement }) => {
				setStatements((s) => {
					Object.assign(selectedStatement, statement);
					return [...s];
				});
				setChargeOpen(false);
			})
			.catch(error)
			.finally(setLoading);
	};

	const handleCreate = (mid, month, rate) => {
		API.statements
			.create({ mid, month, rate })
			.then(({ statements: newStatements }) => {
				success("Statement created");
				setStatements((statements) => [...newStatements, ...statements]);
			})
			.catch(error);
	};

	const approveStatement = (s) => {
		API.statements
			.update(s._id, { status: "APPROVED" })
			.then(({ statement }) => {
				success("Statement Approved");
				setStatements((statements) => {
					Object.assign(s, statement);
					return [...statements];
				});
			})
			.catch(error);
	};

	return (
		<View title="Reports" isLoading={loading}>
			<Stack direction="row" sx={{ width: "100%", height: "100vh" }}>
				<Box sx={{ width: "50%", height: "100%" }}>
					<Stack spacing={1}>
						<CreateStatement {...{ merchants, handleCreate }} />
						<List>
							{statements.map((s, i, r) => (
								<Fragment key={s._id}>
									{s.period.date !== r[i - 1]?.period?.date && (
										<ListSubheader sx={{ bgcolor: "transparent" }} disableGutters>
											<Grid container spacing={1} alignItems="center" justifyContent="space-between">
												<Grid item>{s.period.date}</Grid>

												<Grid item xs="auto">
													Claims:
													{r
														.filter((ss) => ss.period.date === s.period.date)
														.reduce((p, c) => p - +c.summary.claims.total, 0)
														.toLocaleString("en", { style: "currency", currency: "USD" })}
												</Grid>
												<Grid item xs="auto">
													Net:
													{r
														.filter((ss) => ss.period.date === s.period.date)
														.reduce((p, c) => p + +c.netTotal, 0)
														.toLocaleString("en", { style: "currency", currency: "USD" })}
												</Grid>
												<Grid item xs={2}></Grid>
											</Grid>
										</ListSubheader>
									)}
									<ListItemButton selected={s === selectedStatement} onClick={() => setSelectedStatement(s)}>
										<Grid container spacing={1} alignItems="center" rowSpacing={1}>
											<Grid item>{statusIcon[s.status]}</Grid>

											<Grid item xs>
												<Typography>{s.merchant.name}</Typography>
											</Grid>
											{s === selectedStatement && (
												<>
													<Grid item xs="auto">
														{s.status === "CREATED" && <Button onClick={() => approveStatement(s)}>Approve</Button>}
													</Grid>
													<Grid item xs="auto">
														{s.status === "APPROVED" && <Button onClick={() => setChargeOpen(true)}>{s.netTotal >= 0 ? "Charge" : "Pay"}</Button>}
													</Grid>
												</>
											)}
											<Grid item xs={2}>
												<Typography textAlign="end" color={s.netTotal < 0 && "red"}>
													{Number(s.netTotal).toLocaleString("en", { style: "currency", currency: "USD" })}
												</Typography>
											</Grid>
										</Grid>
									</ListItemButton>
								</Fragment>
							))}
						</List>
						<LoadingIndicator open={isLoading} />
					</Stack>
				</Box>

				<Box sx={{ width: "50%", height: "100%", position: "absolute", right: 0 }}>
					<Grid container height="100%">
						<Grid item xs={12} container justifyContent="space-around">
							<Grid item xs={12} sm={6} sx={{ p: 1 }}>
								<Button fullWidth onClick={() => sendPDF(selectedStatement._id)}>
									Send Statement
								</Button>
							</Grid>
							<Grid item xs={12} sm={6} sx={{ p: 1 }}>
								<Button color="error" fullWidth onClick={() => handleDelete(selectedStatement)}>
									Delete
								</Button>
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ height: "100%" }}>
							<PDFView statement={selectedStatement} />
						</Grid>
					</Grid>
				</Box>
			</Stack>

			<ChargeDialog
				{...{ isChargeOpen, selectedStatement, merchant: merchants?.find((m) => m._id === selectedStatement?.merchant?._id), onClose: () => setChargeOpen(), handleCharge, handleSkipStatement }}
			/>
		</View>
	);
}

const statusIcon = {
	CREATED: <Circle color="primary"></Circle>,
	APPROVED: <CircleOutlined color="success"></CircleOutlined>,
	BILLED: <CheckCircle color="success"></CheckCircle>,
	ROLLING: <Circle color="warning"></Circle>,
	SKIPPED: <Circle color="disabled"></Circle>,
};
