import { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import API from "src/API";

const merchantContext = createContext();

export default function MerchantLayout() {
	const merchantHook = useProvideMerchant();

	return (
		<merchantContext.Provider value={merchantHook}>
			<Outlet />
		</merchantContext.Provider>
	);
}

export function useMerchant() {
	return useContext(merchantContext);
}

function useProvideMerchant() {
	const [isMutated, setMutated] = useState(false);
	const [merchant, setMerchant] = useState();
	const [merchants, setMerchants] = useState();
	const { mid } = useParams();

	useEffect(() => {
		API.merchants.getAll().then(({ merchants }) => setMerchants(merchants));
	}, []);

	useEffect(() => {
		if (merchants && !merchant) {
			const merchant = merchants.find((m) => m._id === mid) || merchants[0];
			setMutated(false);
			setMerchant({ ...merchant });
		}
	}, [mid, merchants, merchant]);

	useEffect(() => {
		if (merchant && merchant?._id !== mid) {
			const newPath = window.location.pathname.replace(/\/merchants\/([\w]{24})(\/)?/, `/merchants/${merchant._id}$2`);
			window.history.replaceState(null, "", newPath);
		}
	}, [merchant, mid]);

	const save = (data = {}) => {
		return API.merchants.update(merchant._id, data).then(({ merchant }) => {
			setMerchants((merchants) => {
				Object.assign(
					merchants.find((m) => m._id === merchant._id),
					merchant
				);
				return [...merchants];
			});
			if (data) {
				setMutated(false);
				setMerchant((m) => ({ ...m, ...data }));
			}
		});
	};

	const set = (f) => {
		if (typeof f === "function") {
			const m = f(merchant);
			setMerchant({ ...m });
		} else {
			setMerchant((m) => ({ ...m }));
		}
		setMutated(true);
	};

	return { merchant, merchants, set, setMerchant, save, isMutated };
}
