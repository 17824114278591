import { Stack, Typography, Checkbox } from "@mui/material";

export default function CheckBox({ checked = false, onChange = () => null, label, children, disabled, labelClickable = false, ...rest }) {
	return (
		<Stack alignItems="center" spacing={1} direction="row" {...rest} onClick={labelClickable ? () => onChange(!checked) : undefined}>
			<Checkbox disabled={!!disabled} checked={checked} onChange={(e, v) => onChange(v)} />
			<Typography>{label}</Typography>
			{children}
		</Stack>
	);
}
