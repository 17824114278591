import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import API from "src/API";
import View from "src/components/View";

export default function AccountsView() {
	const { data: merchants = [] } = useQuery("merchants", () => API.merchants.getAll().then((r) => r.merchants));
	const { data: accounts = [], isLoading } = useQuery("accounts", () => API.transactions.getAccounts().then((r) => r.balances));

	const rows = accounts.map((t) => ({ ...t, account: merchants.find((m) => m._id === t._id)?.name || t._id, id: t._id }));

	/**
	 * @type {import("@mui/x-data-grid").GridColDef}
	 */
	const columns = [
		{
			field: "account",
			headerName: "Account",
			width: 300,
			// renderCell: ({ value }) => merchants.find((m) => m._id === value)?.name || value,
		},
		{
			field: "balance",
			headerAlign: "right",
			headerName: "Amount",
			align: "right",
			renderCell: ({ value }) => Number(value).toLocaleString("en", { currency: "USD", minimumFractionDigits: 2 }),
		},
	];

	if (!merchants.length) return null;
	return (
		<View open={false} title="Reports" isLoading={isLoading}>
			<DataGrid rows={rows} columns={columns} />
		</View>
	);
}
