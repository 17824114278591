import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Grid, TextField, Avatar, Chip } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { DataGrid } from "@mui/x-data-grid";
import View from "src/components/View";
import useAlert from "src/utils/Alert";
import API from "src/API";
import merchantColors from "src/components/merchantColors";

const UsersView = () => {
	const [isDialogOpen, setDialogOpen] = useState(false);
	const nav = useNavigate();
	const { error } = useAlert();
	const { data: { users = [], merchants = [] } = {}, isLoading } = useQuery("users&merchants", () => {
		return Promise.all([API.users.getAll(), API.merchants.getAll()]).then(([users, { merchants }]) => {
			return { users, merchants };
		});
	});

	const rows = useMemo(
		() =>
			users?.map((user) => {
				return {
					merchants: merchants.filter((m) => m.users.some((u) => u.uid === user.uid)),
					lastSignIn: user.metadata.lastSignInTime,
					...user,
				};
			}),
		[users, merchants]
	);

	const columns = [
		{
			field: "avatar",
			headerName: "",
			flex: 0,
			minWidth: 50,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (u) => (
				<Avatar src={u.row?.photoURL} sx={{ bgcolor: u.row?.disabled ? "red" : "green" }}>
					{u.row?.displayName
						.toUpperCase()
						.split(" ")
						.splice(0, 2)
						.map((c) => c[0])}
				</Avatar>
			),
		},
		{ field: "displayName", headerName: "Name", flex: 1, minWidth: 175 },
		{ field: "email", headerName: "Email", flex: 1, minWidth: 300 },
		{
			field: "merchants",
			headerName: "Merchants",
			flex: 2,
			minWidth: 275,
			renderCell: (params) => (
				<Stack spacing={1} direction="row" width="100%" sx={{ overflow: "scroll" }}>
					{params.value.map((m, i) => (
						<Chip key={i} sx={merchantColors[m.status]} label={m.name} />
					))}
				</Stack>
			),
		},
		{
			field: "lastSignIn",
			headerName: "Last Sign In",
			flex: 0,
			minWidth: 200,
			align: "right",
			headerAlign: "right",
		},
	];

	const addUser = (displayName, email, password) => {
		API.users
			.create({
				displayName,
				email,
				password,
			})
			.then((result) => {
				nav(result.uid);
			})
			.catch(error);
	};

	return (
		<View title="Users" isLoading={isLoading}>
			<Stack sx={{ alignItems: { xs: "center", md: "end" }, mt: 2, width: "100%" }}>
				<Button sx={{ width: { xs: "100%", md: 200 } }} variant="contained" onClick={() => setDialogOpen(true)}>
					Add User
				</Button>
			</Stack>
			<Stack>
				<DataGrid
					getRowId={(r) => r.uid}
					rows={rows}
					columns={columns}
					initialState={{
						sorting: {
							sortModel: [{ field: "displayName", sort: "asc" }],
						},
					}}
					onRowClick={(e) => nav(e.id)}
					pageSizeOptions={[25, 50, 100]}
					sx={{ textOverflow: "clip !important" }}
				/>
			</Stack>
			<NewUserDialog open={isDialogOpen} onClose={() => setDialogOpen(false)} action={addUser} />
		</View>
	);
};

export default UsersView;

function NewUserDialog({ open, onClose, action }) {
	const [displayName, setDisplayName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Add a new user</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} direction="column">
					<Grid item>
						<TextField required label="Display Name" value={displayName} onChange={({ target }) => setDisplayName(target.value)} />
					</Grid>
					<Grid item>
						<TextField required label="email" value={email} onChange={({ target }) => setEmail(target.value)} />
					</Grid>
					<Grid item>
						<TextField label="password" value={password} onChange={({ target }) => setPassword(target.value)} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={!(email && password && displayName)} onClick={() => action(displayName, email, password)}>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
