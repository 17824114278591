import { useState } from "react";
import { Outlet } from "react-router-dom";
import { experimentalStyled } from "@mui/material";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: "flex",
	height: "100%",
	overflow: "hidden",
	width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
	display: "flex",
	flex: "1 1 auto",
	overflow: "hidden",
	width: "100%",
	height: "100%",
	paddingTop: 64,
	[theme.breakpoints.up("lg")]: {
		paddingLeft: 200,
	},
}));

const DashboardLayoutContainer = experimentalStyled("div")({
	display: "flex",
	flex: "1 1 auto",
	overflow: "hidden",
	height: "100%",
	width: "100%",
});

const DashboardLayoutContent = experimentalStyled("div")({
	flex: "1 1 auto",
	height: "100%",
	overflow: "auto",
	width: "100%",
});

const DashboardLayout = () => {
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);

	return (
		<DashboardLayoutRoot>
			<DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
			<DashboardSidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
			<DashboardLayoutWrapper>
				<DashboardLayoutContainer>
					<DashboardLayoutContent>
						<Outlet />
					</DashboardLayoutContent>
				</DashboardLayoutContainer>
			</DashboardLayoutWrapper>
		</DashboardLayoutRoot>
	);
};

export default DashboardLayout;
