import { Button, Card, CardContent, CardHeader, Divider, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import doObjectsMatch from "src/utils/doObjectsMatch";

export default function CustomerCard({ customer, shippingAddress, billingAddress }) {
	const { address1, address2, phone, city, country, province, name, zipCode } = shippingAddress;
	const shippingAndBillingMatch = doObjectsMatch(shippingAddress, billingAddress);
	return customer ? (
		<Card>
			<CardHeader title="Customer" />
			<CardContent>
				<ListItemText primary={customer.firstName + " " + customer.lastName} secondary={customer.type} />
			</CardContent>
			<Divider />
			<CardContent>
				<Typography variant="h6">CONTACT INFORMATION</Typography>
				<Button onClick={() => null}>{customer.email}</Button>
			</CardContent>
			<Divider />
			<CardContent>
				<Typography variant="h6">SHIPPING {shippingAndBillingMatch && "AND BILLING"} ADDRESS</Typography>
				<Stack>
					<Typography variant="body2">
						{[name, address1, address2, city + " " + province + " " + zipCode, country, phone].filter(Boolean).map((t, i) => (
							<Fragment key={i}>
								{t}
								<br />
							</Fragment>
						))}
					</Typography>
				</Stack>
			</CardContent>
			{!shippingAndBillingMatch && billingAddress && (
				<>
					<Divider />
					<CardContent>
						<Typography variant="h6">BILLING ADDRESS</Typography>
						<Stack>
							<Typography variant="body2">
								{[
									billingAddress.name,
									billingAddress.address1,
									billingAddress.address2,
									billingAddress.city + " " + billingAddress.province + " " + billingAddress.zipCode,
									billingAddress.country,
									billingAddress.phone,
								]
									.filter(Boolean)
									.map((l) => (
										<>
											{l}
											<br />
										</>
									))}
							</Typography>
						</Stack>
					</CardContent>
				</>
			)}
		</Card>
	) : (
		<Skeleton variant="rectangular" />
	);
}
