import "react-perfect-scrollbar/dist/css/styles.css";
import { ThemeProvider } from "@mui/material";
import GlobalStyles from "src/components/GlobalStyles";
import "src/mixins/chartjs";
import theme from "src/theme";
import Routes from "src/routes";
import { SnackbarProvider } from "notistack";
import { DebugProvider } from "./providers/DebugProvider";

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "center" }} maxSnack={3}>
				<DebugProvider>
					<GlobalStyles />
					<Routes />
				</DebugProvider>
			</SnackbarProvider>
		</ThemeProvider>
	);
};

export default App;
