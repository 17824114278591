import { Button, Card, CardHeader, CardContent, Typography, Checkbox, Grid, Stack, TextField, IconButton, ListItem, List, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import API from "src/API";
import View from "src/components/View";
import useAlert from "src/utils/Alert";
import { Delete, Edit, Close, Save } from "@mui/icons-material";

export default function PlansView() {
	const [isLoading, setLoading] = useState(false);
	const [isEditing, setEditing] = useState(false);
	const [plans, setPlans] = useState();
	const [properties, setProperties] = useState();
	const { error, success } = useAlert();
	const nav = useNavigate();

	useEffect(() => {
		setLoading(true);
		API.plans
			.getPlans()
			.then(({ plans, properties }) => {
				setPlans(plans);
				setProperties(properties);
			})
			.catch(error)
			.finally(setLoading);
	}, [error]);

	const handleSave = (plan) => {
		API.plans.updatePlan(plan).then(() => {
			setPlans((p) => {
				plan._mutated = false;
				return [...p];
			});
		});
		setEditing(false);
	};

	const handleDeletePlan = (plan) => {
		if (window.confirm(`Delete ${plan.name} plan?`)) {
			API.plans.deletePlan(plan.id).then(() => {
				setPlans((plans) => plans.filter((p) => p !== plan));
				success(`${plan.name} plan deleted`);
			});
		}
	};

	const handleCreatePlan = () => {
		setLoading(true);
		API.plans
			.createPlan()
			.then((plan) => {
				setPlans((plans) => [plan, ...plans]);
				success("Plan Created");
			})
			.finally(setLoading);
	};

	const handleTitleChange = (plan, name) => {
		setPlans((plans) => {
			plan._mutated = true;
			plan.name = name;
			return [...plans];
		});
	};

	if (!(plans && properties)) return null;

	return (
		<View
			title="Plans"
			isLoading={isLoading}
			toolbar={
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Button onClick={() => nav(-1)} variant="outlined">
						Back
					</Button>
					<Typography>Plans</Typography>
					<Button onClick={handleCreatePlan} variant="outlined">
						Create Plan
					</Button>
				</Stack>
			}
		>
			<Grid container spacing={2}>
				{plans.map((plan) => (
					<Plan {...{ key: plan.id, plan, handleDeletePlan: () => handleDeletePlan(plan), handleSave, handleTitleChange, properties, isEditing, setEditing }} />
				))}
			</Grid>
		</View>
	);
}

function Plan({ plan: p, handleDeletePlan, handleSave, handleTitleChange, properties, isEditing, setEditing }) {
	const [plan, setPlan] = useState();

	useEffect(() => {
		if (p) setPlan({ ...p, properties: { ...p.properties } });
	}, [p]);

	const handleCancel = () => {
		setPlan({ ...p, properties: { ...p.properties } });
		setEditing(false);
	};

	const handleDeleteProperty = (property) => {
		setPlan((plan) => {
			plan._mutated = true;
			plan.properties = Object.fromEntries(Object.entries(plan.properties).filter(([p]) => p !== property.key));

			return { ...plan };
		});
	};

	const handlePropChange = (key, value) => {
		setPlan((plan) => {
			plan._mutated = true;
			plan.properties[key] = value;
			return { ...plan };
		});
	};

	const handleAddProperty = ({ target }) => {
		setPlan((plan) => {
			plan.properties = { ...plan.properties, [target.value.key]: target.value.default };
			return { ...plan };
		});
	};

	if (!plan) return null;
	return (
		<Grid item xs={12} lg={6}>
			<Card>
				<CardHeader
					title={
						<Stack spacing={3} direction="row">
							<TextField disabled={!isEditing} variant="standard" value={plan.name} onChange={({ target }) => handleTitleChange(plan, target.value)} />
							{isEditing && (
								<IconButton onClick={handleDeletePlan}>
									<Delete />
								</IconButton>
							)}
						</Stack>
					}
					action={<PlanActions {...{ isEditing, setEditing, handleSave: () => handleSave(plan), handleCancel }} />}
				/>
				<CardContent>
					<Stack spacing={2}>
						<List dense>
							{Object.entries(plan.properties)
								.sort()
								.map((property, i) => (
									<Property {...{ key: i, property, handlePropChange, isEditing, setEditing, handleDeleteProperty, properties }} />
								))}
							<ListItem>
								{isEditing && (
									<Select value={"default"} onChange={handleAddProperty}>
										<MenuItem value={"default"} disabled>
											Add Property
										</MenuItem>
										{properties
											.filter((p) => !plan.properties.hasOwnProperty(p.key))
											.map((p) => (
												<MenuItem value={p} key={p.key}>
													{p.label}
												</MenuItem>
											))}
									</Select>
								)}
							</ListItem>
						</List>
					</Stack>
				</CardContent>
			</Card>
		</Grid>
	);
}

function Property({ property: [key, value], isEditing, setEditing, handlePropChange, handleDeleteProperty, properties }) {
	const property = properties.find((p) => p.key === key);

	if (!property) return null;
	return (
		<ListItem>
			<Grid container alignItems="center">
				<Grid item xs={6}>
					<Typography>{property?.label}</Typography>
				</Grid>
				<Grid item xs={5}>
					{property.type === "boolean" && <Checkbox disabled={!isEditing} checked={value} onChange={(e, v) => handlePropChange(key, v)} />}
					{property.type === "number" && <TextField disabled={!isEditing} value={value} variant="standard" type="number" min={property.min} max={property.max} onChange={({ target }) => handlePropChange(key, target.value)} />}
				</Grid>
				<Grid item xs={1}>
					<IconButton disabled={!isEditing} onClick={() => handleDeleteProperty(property)}>
						<Close />
					</IconButton>
				</Grid>
			</Grid>
		</ListItem>
	);
}

function PlanActions({ isEditing, setEditing, handleSave, handleCancel }) {
	return isEditing ? (
		<Stack spacing={1} direction="row">
			<IconButton onClick={handleSave}>
				<Save />
			</IconButton>
			<IconButton onClick={handleCancel}>
				<Close />
			</IconButton>
		</Stack>
	) : (
		<IconButton onClick={() => setEditing(true)}>
			<Edit />
		</IconButton>
	);
}
