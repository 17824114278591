import PropTypes from "prop-types";
import { Button, Card, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, Stack, TextField } from "@mui/material";
import * as Icons from "@mui/icons-material";
import { useState } from "react";

const ListToolbar = ({ actions = [], filterOptions = [], filter, setFilter = () => null, searchFunc, clearFunc }) => {
	const [filterOpen, setFilterOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [existingSearch, setExistingSearch] = useState();

	const handleFilterOptionClick = (f) => () => {
		setFilter((filter) => {
			if (!filter) return filterOptions.filter((ff) => ff !== f);
			const found = filter?.find((ff) => f === ff);
			if (found) {
				return filter.filter((ff) => ff !== f);
			} else {
				return [...(filter || []), f];
			}
		});
	};

	const handleAllClick = () => {
		setFilter((filter) => {
			if (!filter) return [];
		});
	};

	const handleSearch = () => {
		searchFunc(search);
		setExistingSearch(true);
	};

	const handleClear = () => {
		// setSearch("");
		setExistingSearch(false);
		clearFunc();
	};

	return (
		<Card sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
			{!!filterOptions.length && (
				<IconButton onClick={() => setFilterOpen(true)}>
					<Icons.FilterList />
				</IconButton>
			)}

			{actions.map((a, i) => (
				<Button key={i} color="primary" variant="contained" onClick={a.onClick}>
					{a.label}
				</Button>
			))}

			{searchFunc && (
				<TextField
					disabled={existingSearch}
					value={search}
					onChange={({ target }) => setSearch(target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Icons.Search />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">{existingSearch ? <Button onClick={() => handleClear()}>Clear</Button> : <Button onClick={() => handleSearch()}>Search</Button>}</InputAdornment>
						),
					}}
					placeholder="Search"
				/>
			)}

			<Dialog open={filterOpen} onClose={() => setFilterOpen(false)}>
				<DialogTitle sx={{ alignSelf: "center" }}>
					<Icons.FilterList />
				</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						{filterOptions.map((f, i) => (
							<ListItem key={i} onClick={handleFilterOptionClick(f)}>
								<ListItemIcon>{filter?.includes(f) || !filter ? <Icons.RadioButtonChecked /> : <Icons.RadioButtonUnchecked />}</ListItemIcon>
								<ListItemText primary={f} />
							</ListItem>
						))}
						<ListItem onClick={handleAllClick}>
							<ListItemIcon>{filter?.length === filterOptions.length || !filter ? <Icons.RadioButtonChecked /> : <Icons.RadioButtonUnchecked />}</ListItemIcon>
							<ListItemText primary="All" />
						</ListItem>
					</Stack>
				</DialogContent>
			</Dialog>
		</Card>
	);
};

ListToolbar.propTypes = {
	searchFunc: PropTypes.func,
	searchLabel: PropTypes.string,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		})
	),
};

export default ListToolbar;
