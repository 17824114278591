import { Check, Circle, Edit } from "@mui/icons-material";
import { Button, Card, CardHeader, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import API from "src/API";
import View from "src/components/View";
import { useAuth } from "src/providers/AuthProvider";
import useAlert from "src/utils/Alert";
import { DataGrid } from "@mui/x-data-grid";
import EditAdjustmentDialog from "./EditAdjustmentDialog";
import AddAdjustmentDialog from "./AddAdjustmentDialog";

export default function AdjustmentsView() {
	const [isLoading, setLoading] = useState(true);
	const [selectedAdjustment, setSelectedAdjustment] = useState();
	const [merchants, setMerchants] = useState([]);
	const [open, setOpen] = useState(false);
	const { success, error } = useAlert();
	const { userInfo } = useAuth();

	const [adjustments, setAdjustments] = useState([]);

	useEffect(() => {
		setLoading(true);
		Promise.all([API.adjustments.getAll(), API.merchants.getAll()])
			.then(([{ adjustments }, { merchants }]) => {
				setAdjustments(adjustments);
				setMerchants(merchants);
			})
			.catch(error)
			.finally(setLoading);
	}, [error]);

	const handleAddAdjustment = (adjustment) => {
		setLoading(true);
		setOpen(false);
		adjustment.createdBy = userInfo.uid;
		API.adjustments
			.create(adjustment)
			.then(({ adjustment }) => {
				success("Adjustment Created!");
				setAdjustments((adjustments) => [adjustment, ...adjustments]);
			})
			.catch(error)
			.finally(() => setLoading(false));
	};

	const handleUpdateAdjustment = (adjustment) => {
		setLoading(true);
		setSelectedAdjustment();

		API.adjustments
			.update(adjustment._id, adjustment)
			.then(() => {
				setAdjustments((adjustments) => {
					Object.assign(selectedAdjustment, adjustment);

					return [...adjustments];
				});
				success("Adjustment Updated!");
			})
			.catch(error)
			.finally(() => setLoading(false));
	};

	const handleDelete = (id) => {
		if (!window.confirm("Delete Adjustment?")) return;
		setLoading(true);

		setSelectedAdjustment();
		API.adjustments
			.delete(id)
			.then(() => success("Adjustment Deleted!"))
			.catch(error)
			.finally(() => setLoading(false));
		setAdjustments((adjustments) => adjustments.filter((a) => a._id !== id));
	};

	const activeMerchants = merchants?.filter((m) => ["TESTING", "ACTIVE", "ONBOARDING"].includes(m.status));

	if (!adjustments || !merchants) return null;

	/** @type {import("@mui/x-data-grid").GridColDef} */
	const columns = [
		{
			field: "status",
			headerName: "",
			align: "center",
			renderCell: ({ value }) => statusIcon[value],
		},
		{
			field: "month",
			headerName: "Month",
		},
		{
			field: "mid",
			headerName: "Merchant",
			width: 250,
			renderCell: ({ value }) => merchants.find((m) => m._id === value)?.name,
		},
		{
			field: "description",
			headerName: "Description",
			flex: true,
		},
		{
			field: "amount",
			headerName: "Amount",
		},
		{
			field: "",
			renderCell: ({ row }) => (
				<IconButton disabled={row.status === "COMPLETE"} onClick={() => setSelectedAdjustment(row)}>
					<Edit />
				</IconButton>
			),
		},
	];

	return (
		<View title="Reports">
			<Card>
				<CardHeader
					title="Adjustments"
					action={
						<Button size="medium" color="primary" variant="contained" onClick={() => setOpen(true)}>
							Add Adjustment
						</Button>
					}
				/>
			</Card>
			<DataGrid rows={adjustments} columns={columns} editMode="row" getRowId={(row) => row._id} loading={isLoading} />

			<AddAdjustmentDialog open={open} onClose={() => setOpen(false)} addAdjustment={handleAddAdjustment} merchants={activeMerchants} />
			<EditAdjustmentDialog selected={selectedAdjustment} onClose={() => setSelectedAdjustment()} update={handleUpdateAdjustment} deleteAdjustment={handleDelete} merchants={activeMerchants} />
		</View>
	);
}

const statusIcon = {
	COMPLETED: <Check color="success" />,
	PENDING: <Circle color="warning" />,
};
