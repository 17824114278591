import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useAuth } from "src/providers/AuthProvider";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function CliLogin() {
	const { signIn, userInfo } = useAuth();
	const [params] = useSearchParams();

	useEffect(() => {
		if (userInfo.roles.includes("ADMIN")) {
			userInfo.token().then((token) => {
				window.location.href = params.get("redirect") + "?token=" + token;
			});
		}
	}, [userInfo, params]);

	const handleLogin = ({ email, password }, form) => {
		signIn(email, password)
			.then((isAdmin) => {
				if (!isAdmin) {
					form.setErrors({ email: "Account Does Not Exist" });
				}
			})
			.catch((e) => form.setErrors({ password: e.message }))
			.finally(() => form.setSubmitting(false));
	};

	return (
		<>
			<Helmet>
				<title>Login | SavedBy Admin</title>
			</Helmet>
			<Box sx={{ backgroundColor: "background.default", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
				<Container maxWidth="sm">
					<Formik
						initialValues={{ email: "", password: "" }}
						validationSchema={Yup.object().shape({
							email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
							password: Yup.string().max(255).required("Password is required"),
						})}
						onSubmit={handleLogin}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
							<form onSubmit={handleSubmit}>
								<Box sx={{ mb: 3 }}>
									<Typography color="textPrimary" variant="h2">
										Sign in
									</Typography>
								</Box>
								<TextField
									error={Boolean(touched.email && errors.email)}
									fullWidth
									helperText={touched.email && errors.email}
									label="Email Address"
									margin="normal"
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									type="email"
									value={values.email}
									variant="outlined"
								/>
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									label="Password"
									margin="normal"
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.password}
									variant="outlined"
								/>
								<Box sx={{ py: 2 }}>
									<Button color="primary" disabled={!!isSubmitting} fullWidth size="large" type="submit" variant="contained">
										Sign in
									</Button>
								</Box>
							</form>
						)}
					</Formik>
				</Container>
			</Box>
		</>
	);
}
