import React, { useEffect, useRef, useState } from "react";
import { Paper, Card, CardContent, Typography, MenuItem, Select, Box, Button, Stack, CardHeader } from "@mui/material";
import { uploadMerchantLogo } from "src/firebase";
import MuiImage from "mui-image";
import EditableTypography from "src/EditableTypography";
import { Circle } from "@mui/icons-material";
import merchantColors from "../merchantColors";

export default function MerchantInfo({ merchant, saveMerchant }) {
	const handleChange = ({ target }) => {
		saveMerchant({ status: target.value });
	};

	const handleNameChange = (name) => {
		saveMerchant({ name });
	};

	const { shop_owner, email, phone, plan_display_name, has_discounts, has_gift_cards, has_storefront, checkout_api_supported, timezone } = merchant.shopInfo;

	return (
		<Card component={Paper}>
			<CardContent>
				<Stack spacing={2}>
					<EditableTypography inputProps={{ style: { fontSize: 24 } }} textProps={{ variant: "h3" }} variant="standard" value={merchant.name} useOnClick onSave={handleNameChange} />
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="caption">{plan_display_name}</Typography>
						<Typography component="a" target="_blank" href={"https://" + merchant.domain} variant="caption">
							{merchant.domain}
						</Typography>
					</Stack>

					<UploadImage mid={merchant._id} />

					<StatusSelector onChange={handleChange} merchant={merchant} />

					<CardContent>
						<CardHeader titleTypographyProps={{ textAlign: "center" }} title="Contact Info" />

						<Stack spacing={1}>
							<Typography>{shop_owner}</Typography>
							<Typography>{email}</Typography>
							<Typography>{phone}</Typography>
						</Stack>
					</CardContent>

					<CardContent>
						<CardHeader titleTypographyProps={{ textAlign: "center" }} title="Shopify Info" />

						<Stack spacing={1}>
							<Stack direction="row" justifyContent="space-between">
								<Typography>
									<b>Timezone</b>
								</Typography>
								<Typography>{timezone?.toString()}</Typography>
							</Stack>

							<Stack direction="row" justifyContent="space-between">
								<Typography>
									<b>Has Discounts</b>
								</Typography>
								<Typography>{has_discounts?.toString()}</Typography>
							</Stack>

							<Stack direction="row" justifyContent="space-between">
								<Typography>
									<b>Has Gift Cards</b>
								</Typography>
								<Typography>{has_gift_cards?.toString()}</Typography>
							</Stack>

							<Stack direction="row" justifyContent="space-between">
								<Typography>
									<b>Has Storefront</b>
								</Typography>
								<Typography>{has_storefront?.toString()}</Typography>
							</Stack>

							<Stack direction="row" justifyContent="space-between">
								<Typography>
									<b>Checkout API Supported</b>
								</Typography>
								<Typography>{checkout_api_supported?.toString()}</Typography>
							</Stack>
						</Stack>
					</CardContent>
				</Stack>
			</CardContent>
		</Card>
	);
}

function StatusSelector({ merchant, onChange }) {
	return (
		<Select sx={{ height: 30 }} value={merchant.status} onChange={onChange}>
			<MenuItem value="ACTIVE">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.ACTIVE?.bgcolor }} />
					<Typography>ACTIVE</Typography>
				</Stack>
			</MenuItem>

			<MenuItem value="PAUSED">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.PAUSED?.bgcolor }} />
					<Typography>PAUSED</Typography>
				</Stack>
			</MenuItem>

			<MenuItem value="INACTIVE">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.INACTIVE?.bgcolor }} />
					<Typography>INACTIVE</Typography>
				</Stack>
			</MenuItem>

			<MenuItem value="TESTING">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.TESTING?.bgcolor }} />
					<Typography>TESTING</Typography>
				</Stack>
			</MenuItem>

			<MenuItem value="DEVELOPMENT">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.DEVELOPMENT?.bgcolor }} />
					<Typography>DEVELOPMENT</Typography>
				</Stack>
			</MenuItem>

			<MenuItem value="ONBOARDING">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.ONBOARDING?.bgcolor }} />
					<Typography>ONBOARDING</Typography>
				</Stack>
			</MenuItem>

			<MenuItem disabled value="REGISTERING">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.REGISTERING?.bgcolor }} />
					<Typography>REGISTERING</Typography>
				</Stack>
			</MenuItem>

			<MenuItem disabled value="REINSTALLING">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.REINSTALLING?.bgcolor }} />
					<Typography>REINSTALLING</Typography>
				</Stack>
			</MenuItem>

			<MenuItem disabled value="INSTALLING">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.INSTALLING?.bgcolor }} />
					<Typography>INSTALLING</Typography>
				</Stack>
			</MenuItem>

			<MenuItem disabled value="UNINSTALLED">
				<Stack direction="row" spacing={1}>
					<Circle sx={{ color: merchantColors.UNINSTALLED?.bgcolor }} />
					<Typography>UNINSTALLED</Typography>
				</Stack>
			</MenuItem>
		</Select>
	);
}

function UploadImage({ mid }) {
	const [src, setSrc] = useState("");
	const [file, setFile] = useState();
	const fileInput = useRef();

	useEffect(() => {
		if (file) return;
		setSrc(`https://api.savedby.io/cdn/logos/merchants/${mid}.png`);
	}, [mid, file]);

	const handleUpload = ({ target }) => {
		const file = target.files[0];
		setFile(file);
		const objectUrl = URL.createObjectURL(file);
		setSrc(objectUrl);
		uploadMerchantLogo(file);
	};

	return (
		<Box>
			<MuiImage onError={console.error} src={src} />
			<input type="file" style={{ display: "none" }} accept="image/*" ref={fileInput} onChange={handleUpload} />

			<Button fullWidth type="file" onClick={() => fileInput.current.click()}>
				Upload Logo
			</Button>
		</Box>
	);
}
