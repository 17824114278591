import { Circle } from "@mui/icons-material";
import { Button, MenuItem, Stack, Card, CardHeader, CardContent, Select, TextField, Typography, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import merchantColors from "src/components/merchantColors";

/**
 *
 * @param {*} param0
 * @returns
 */
export default function CreateStatement({ merchants, handleCreate: create }) {
	const [mid, setMid] = useState("");
	const startMonth = new Date();
	startMonth.setMonth(startMonth.getMonth() - 1);
	const [month, setMonth] = useState(startMonth.toISOString().slice(0, 7));

	useEffect(() => {
		merchants && setMid(merchants[0]._id);
	}, [merchants]);

	const handleCreate = () => {
		const merchant = merchants.find((m) => m._id === mid);

		if (merchant.shopInfo.currency !== "USD") {
			const rate = window.prompt(`Enter currency exchange rate for ${merchant.shopInfo.currency} to USD`);
			if (!rate) return;
			return create(mid, month, rate);
		}

		return create(mid, month);
	};

	if (!merchants) return null;
	return (
		<Card>
			<CardHeader title="Create Statement" />
			<CardContent>
				<Stack spacing={2} direction="row">
					<TextField type="month" onChange={({ target }) => setMonth(target.value)} value={month} />

					<Select value={mid} onChange={({ target }) => setMid(target.value)}>
						{merchants
							.filter((m) => m.status !== "INACTIVE")
							.map((m) => (
								<MenuItem key={m._id} value={m._id}>
									<Stack spacing={1} direction="row">
										<Tooltip title={m.status} placement="left">
											<Circle style={{ color: merchantColors[m.status].bgcolor }} />
										</Tooltip>
										<Typography>{m.name}</Typography>
									</Stack>
								</MenuItem>
							))}
					</Select>

					<Button onClick={handleCreate}>create</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}
