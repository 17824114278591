import React, { useEffect, useState } from "react";
import { Stack, Button, Card, CardContent, CardHeader, TextField, Select, MenuItem, Box, Chip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import API from "src/API";
import useAlert from "src/utils/Alert";
import { useNavigate } from "react-router";
import View from "src/components/View";

export default function RolesView() {
	const [isLoading, setLoading] = useState(false);
	const [roles, setRoles] = useState();
	const [scopes, setScopes] = useState([]);
	const nav = useNavigate();
	const { success } = useAlert();

	useEffect(() => {
		setLoading(true);
		Promise.all([API.settings.getRoles(), API.scopes.get()])
			.then(([roles, scopes]) => {
				setRoles(roles);
				setScopes(scopes);
			})
			.finally(setLoading);
	}, []);

	const handleCreateRole = () => {
		API.settings.createRole().then((role) => {
			setRoles((roles) => [role, ...roles]);
			success("Role Created");
		});
	};

	const handleDeleteRole = (role) => {
		if (window.confirm(`Delete role ${role.title}?`)) {
			API.roles.delete(role.id).then(() => {
				setRoles((roles) => roles.filter((r) => r !== role));
				success(`Role ${role.title} deleted`);
			});
		}
	};

	const handleSave = (role) => {
		API.settings.updateRole(role).then(() => {
			setRoles((rs) => {
				role._mutated = false;
				return [...rs];
			});
		});
	};

	const handleAddScope = (role, scope) => {
		setRoles((roles) => {
			role._mutated = true;
			role.scopes.push(scope);
			return [...roles];
		});
	};

	const handleDeleteScope = (role, scope) => {
		setRoles((roles) => {
			role._mutated = true;
			role.scopes = role.scopes.filter((s) => s !== scope);
			return [...roles];
		});
	};

	const handleTitleChange = (role, title) => {
		setRoles((roles) => {
			role._mutated = true;
			role.title = title;
			return [...roles];
		});
	};

	if (!roles) return null;

	return (
		<View
			title="Roles | Admin"
			isLoading={isLoading}
			toolbar={
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Button onClick={() => nav(-1)} variant="outlined">
						Back
					</Button>
				</Stack>
			}
		>
			<Stack spacing={2}>
				<CardHeader title="Roles" action={<Button onClick={handleCreateRole}>Create Role</Button>} />
				<Card>
					<CardContent>
						<Stack spacing={2}>
							{roles.map((role) => (
								<Role {...{ key: role.id, role, scopes, handleDeleteRole, handleSave, handleAddScope, handleDeleteScope, handleTitleChange }} />
							))}
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</View>
	);
}

function Role({ role, scopes, handleDeleteRole, handleSave, handleAddScope, handleDeleteScope, handleTitleChange }) {
	return (
		<Card>
			<CardHeader
				title={<TextField variant="standard" value={role.title} onChange={({ target }) => handleTitleChange(role, target.value)} />}
				action={<Delete onClick={() => handleDeleteRole(role)} />}
			/>
			<CardContent>
				<Stack spacing={2}>
					<Box>
						{role.scopes.map((r) => (
							<Chip sx={{ m: 1 }} key={r} label={r} variant="outlined" onDelete={() => handleDeleteScope(r)} />
						))}
					</Box>

					<Select value="Add Scope" onChange={({ target }) => handleAddScope(role, target.value)}>
						<MenuItem value="Add Scope" disabled>
							Add Scope
						</MenuItem>
						{scopes
							?.filter((s) => !role.scopes.includes(s))
							.map((s) => (
								<MenuItem key={s} value={s}>
									{s}
								</MenuItem>
							))}
					</Select>

					<Button disabled={!role._mutated} onClick={() => handleSave(role)}>
						Save
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}
