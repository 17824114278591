import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import CodeEditor from "src/components/CodeEditor";
import View from "src/components/View";
import useAlert from "src/utils/Alert";
import testJSON from "src/utils/testJson";

export default function CodeEditorView({ title, isLoading, children, code, toolbar, leftAction, rightAction, setValue, setOpen = () => null, open, save }) {
	const { error } = useAlert();

	const t = useTheme();
	const isXl = useMediaQuery(`@media (min-width:${t.breakpoints.values.xl + 800}px)`, { noSsr: true });

	useEffect(() => {
		const listener = (e) => {
			if (e.ctrlKey && e.key === "s" && save) {
				e.preventDefault();
				if (testJSON(code)) {
					save();
				} else {
					error("Bad JSON format");
				}
			} else if (e.ctrlKey && e.key === "e") {
				setOpen((o) => !o);
			}
		};
		window.addEventListener("keyup", listener);
		return () => window.removeEventListener("keyup", listener);
	}, [save, error, code, setOpen]);

	if (toolbar) {
		leftAction = toolbar;
		rightAction = undefined;
	}

	return (
		<View {...{ title, isLoading, leftAction, rightAction }}>
			{open ? (
				<Stack spacing={2} direction={isXl ? "row" : "column"} sx={{ height: "100%" }}>
					<Box flex={isXl && 1}>{children}</Box>
					<Box sx={{ [`@media (min-width:${t.breakpoints.values.xl + 800}px)`]: { width: 800, height: "100%" } }}>
						<CodeEditor value={code} setValue={setValue} />
					</Box>
				</Stack>
			) : (
				children
			)}
		</View>
	);
}
