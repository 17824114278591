import { Stack, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Typography, Card, Grid, CardContent, TextField, CardHeader, Select, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "src/providers/AuthProvider";
import CodeEditorView from "src/components/CodeEditorView";
import API from "src/API";
import useAlert from "src/utils/Alert";
import CheckBox from "src/components/CheckBox";
import useEdit from "src/useEdit";
import { useQuery } from "react-query";

const UserView = () => {
	const [isLoading, setLoading] = useState(true);
	const [user, setUser] = useState();
	const [anchorEl, setAnchorEl] = useState(false);
	const [userMerchants, setUserMerchants] = useState([]);
	const [open, setOpen] = useState();
	const { id } = useParams();
	const nav = useNavigate();
	const { error, success } = useAlert();
	const { refresh } = useAuth();
	const { data: { merchants = [] } = {} } = useQuery("merchants", API.merchants.getAll);

	useEffect(() => {
		API.users
			.get(id)
			.then(({ user, merchants }) => {
				setUser(user);
				setUserMerchants(merchants);
			})
			.finally(setLoading);
	}, [id]);

	const saveUser = async (user) => {
		return await API.users
			.update(id, user)
			.then(() => {
				setUser({ ...user });
				success("User Saved");
				return user;
			})
			.catch(error)
			.finally(() => refresh());
	};

	const deleteUser = () => {
		setAnchorEl();
		if (window.confirm("Delete User?")) {
			API.users
				.delete(id)
				.then(() => {
					success("User Deleted");
					nav(-1);
				})
				.catch(error);
		}
	};

	const sendPassReset = () => {
		// setLoading(true);
		API.users
			.sendPassReset(id)
			.then(() => success("Password Reset Email Sent"))
			.catch(error);
		// .finally(setLoading);
	};

	if (!user) return null;
	return (
		<CodeEditorView title="User" isLoading={isLoading} open={true} code={user}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 2 }}>
				<Button onClick={() => nav(-1)} variant="outlined">
					Back
				</Button>
				<Typography>{user?.displayName}</Typography>
				<Button onClick={({ target }) => setAnchorEl(target)} variant="outlined">
					menu
				</Button>
				<Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl()}>
					<MenuItem disabled color="secondary" onClick={deleteUser}>
						Delete
					</MenuItem>
				</Menu>
			</Stack>

			<UserDetails user={user} sendPassReset={sendPassReset} onSave={saveUser} />

			<UserMerchants {...{ user, setUser, userMerchants: userMerchants, setUserMerchants: setUserMerchants }} />
			<Stack mt={2}>
				<Button color="primary" variant="contained" onClick={() => setOpen(true)}>
					Add Merchant
				</Button>
			</Stack>
			<AddMerchantDialog {...{ open: open, onClose: () => setOpen(), merchants, setUser, user, setUserMerchants, userMerchants }} />
		</CodeEditorView>
	);
};

export default UserView;

function UserDetails({ sendPassReset, user, onSave }) {
	const [data, setData, { CardAction, isEditing }] = useEdit(user, onSave);

	const handleChange = ({ target }) => {
		setData((d) => {
			d[target.name] = target.value;
			return { ...d };
		});
	};

	const handleRoleToggle = (role) => (v) => {
		setData((d) => {
			let roles = [...(d.roles || [])];
			if (v) {
				roles.push(role);
			} else {
				roles = roles.filter((r) => r !== role);
			}

			d.roles = roles.sort();
			return { ...d };
		});
	};

	if (!data) return null;
	return (
		<Card>
			<CardHeader
				action={CardAction}
				title={
					<Stack direction="row" spacing={2} alignItems="center">
						<Avatar src={data.photoURL}>
							{data.displayName?.split(" ").reduce((p, c) => {
								if (!c) return p;
								return p + c.toUpperCase();
							})}
						</Avatar>
						<Typography>{data.email}</Typography>
					</Stack>
				}
			/>
			<CardContent>
				<Stack spacing={1} justifyContent="space-between">
					<TextField label="Display Name" name="displayName" value={data?.displayName || ""} onChange={handleChange} disabled={!isEditing} />
					<TextField label="Photo Url" name="photoURL" value={data?.photoURL || ""} onChange={handleChange} disabled={!isEditing} />
					<Button disabled={true} variant="outlined" onClick={sendPassReset}>
						Send password reset
					</Button>
				</Stack>

				<Box sx={{ mt: 1, border: 1, borderRadius: 1, borderColor: "grey.400" }}>
					<Stack spacing={2} sx={{ m: 2 }}>
						<Typography variant="h5">Roles</Typography>
						<Grid container>
							<Grid item xs={6}>
								<CheckBox checked={data.roles?.includes("AGENT")} label="AGENT" onChange={handleRoleToggle("AGENT")} disabled={!isEditing} />
							</Grid>
							<Grid item xs={6}>
								<CheckBox checked={data.roles?.includes("DEV")} label="DEV" onChange={handleRoleToggle("DEV")} disabled={!isEditing} />
							</Grid>
							<Grid item xs={6}>
								<CheckBox checked={data.roles?.includes("OWNER")} label="OWNER" onChange={handleRoleToggle("OWNER")} disabled={!isEditing} />
							</Grid>
							<Grid item xs={6}>
								<CheckBox checked={data.roles?.includes("SALES")} label="SALES" onChange={handleRoleToggle("SALES")} disabled={!isEditing} />
							</Grid>
						</Grid>
					</Stack>
				</Box>
			</CardContent>
		</Card>
	);
}

function UserMerchants({ user, userMerchants, setUserMerchants }) {
	return userMerchants?.map((merchant) => <UserMerchant {...{ merchant, key: merchant?._id, user, setUserMerchants }} />);
}

function UserMerchant({ merchant, user, setUserMerchants }) {
	const { error, success } = useAlert();
	const handleSave = async (roles) => {
		await API.merchants
			.updateUser(merchant._id, user.uid, {
				roles,
			})
			.then(() => {
				setUserMerchants((merchants) => {
					const m = merchants.find((m) => m._id === merchant._id);
					m.roles = roles;
					return [...merchants];
				});
				success("User updated!");
			})
			.catch(error);
	};
	const [roles, handleUpdate, { isEditing, CardAction }] = useEdit(merchant?.roles, handleSave);

	const handleDelete = async (merchant) => {
		if (window.confirm(`Remove user from ${merchant.name}?`)) {
			await API.merchants
				.deleteUser(merchant._id, user.uid)
				.then(() => {
					setUserMerchants((merchants) => {
						const updatedMerchants = merchants.filter((m) => m._id !== merchant._id);
						return [...updatedMerchants];
					});
					success("Merchant deleted from user!");
				})
				.catch(error);
		}
	};

	return (
		<Card sx={{ mt: 2 }}>
			<CardHeader action={CardAction} title={`${!merchant?.name ? "" : merchant?.name}` + (merchant?._id !== "ADMIN" ? ` (${merchant?._id})` : merchant?._id)} />

			<CardContent>
				<Grid container>
					<Grid item xs={6}>
						<CheckBox
							disabled={!isEditing}
							checked={roles?.includes("ACCOUNT")}
							label="Account"
							onChange={(v) => handleUpdate((roles) => (v ? [...roles, "ACCOUNT"] : roles.filter((r) => r !== "ACCOUNT")))}
						/>
					</Grid>
					<Grid item xs={6}>
						<CheckBox
							disabled={!isEditing}
							checked={roles?.includes("BILLING")}
							label="Billing"
							onChange={(v) => handleUpdate((roles) => (v ? [...roles, "BILLING"] : roles.filter((r) => r !== "BILLING")))}
						/>
					</Grid>
					<Grid item xs={6}>
						<CheckBox
							disabled={!isEditing}
							checked={roles?.includes("CLAIMS")}
							label="Claims"
							onChange={(v) => handleUpdate((roles) => (v ? [...roles, "CLAIMS"] : roles.filter((r) => r !== "CLAIMS")))}
						/>
					</Grid>
					<Grid item xs={6}>
						<CheckBox
							disabled={!isEditing}
							checked={roles?.includes("DASH")}
							label="Dashboard"
							onChange={(v) => handleUpdate((roles) => (v ? [...roles, "DASH"] : roles.filter((r) => r !== "DASH")))}
						/>
					</Grid>
					<Grid item xs={6}>
						<CheckBox
							disabled={!isEditing}
							checked={roles?.includes("USERS")}
							label="Users"
							onChange={(v) => handleUpdate((roles) => (v ? [...roles, "USERS"] : roles.filter((r) => r !== "USERS")))}
						/>
					</Grid>
				</Grid>

				{isEditing && (
					<Button color="error" variant="text" onClick={() => handleDelete(merchant)}>
						Remove from merchant
					</Button>
				)}
			</CardContent>
		</Card>
	);
}

function AddMerchantDialog({ open, onClose, merchants, setUser, user, setUserMerchants, userMerchants }) {
	const [selected, setSelected] = useState("default");
	const [roles, setRoles] = useState([]);
	const { error, success } = useAlert();

	const handleToggleRole = (role) => (v) => {
		setRoles((roles) => {
			return (v ? [role, ...roles] : roles.filter((r) => r !== role)).sort();
		});
	};

	const handleSelected = ({ target }) => {
		setSelected(target.value);
	};

	const handleAddMerchant = () => {
		API.merchants
			.updateUser(selected._id, user?.uid, { roles })
			.then(() => {
				setUserMerchants((merchants) => {
					merchants.push({ _id: selected._id, name: selected.name, roles: roles });
					return [...merchants];
				});
				success("Merchant added to user!");
			})
			.catch(error);
		setSelected();
		onClose();
	};

	const filteredMerchants = merchants.filter((m) => !["INACTIVE", "UNINSTALLED"].includes(m.status) && !userMerchants.some((um) => um._id === m._id));

	return (
		<Dialog open={!!open} onClose={onClose}>
			<DialogTitle>Add Merchant</DialogTitle>
			<DialogContent>
				<Stack spacing={2} sx={{ p: 2 }}>
					<Select value={selected || "default"} onChange={(e) => handleSelected(e)}>
						<MenuItem disabled value={"default"}>
							Add Merchant
						</MenuItem>
						{filteredMerchants.map((m, i) => (
							<MenuItem value={m} key={i}>
								{m.name}
							</MenuItem>
						))}
					</Select>
					<Typography>Permissions</Typography>
					<Stack>
						<Box>
							<CheckBox checked={roles?.includes("ACCOUNT")} onChange={handleToggleRole("ACCOUNT")} label="Account MGMT" />
						</Box>
						<Box>
							<CheckBox checked={roles?.includes("BILLING")} onChange={handleToggleRole("BILLING")} label="Billing" />
						</Box>
						<Box>
							<CheckBox checked={roles?.includes("CLAIMS")} onChange={handleToggleRole("CLAIMS")} label="Claims" />
						</Box>
						<Box>
							<CheckBox checked={roles?.includes("DASH")} onChange={handleToggleRole("DASH")} label="Dashboard" />
						</Box>
						<Box>
							<CheckBox checked={roles?.includes("USERS")} onChange={handleToggleRole("USERS")} label="Users" />
						</Box>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button fullWidth onClick={handleAddMerchant}>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
