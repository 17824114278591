/**
 * @type {import("@mui/styles/components").Components}
 */
const components = {
	MuiButton: {
		defaultProps: {
			variant: "outlined",
		},
	},
	MuiChip: {
		variants: [
			{
				props: {
					label: "DRAFT",
				},
				style: {
					backgroundColor: "orange",
					color: "black",
				},
			},
			{
				props: {
					label: "IN REVIEW",
				},
				style: {
					backgroundColor: "blue",
					color: "white",
				},
			},
			{
				props: {
					label: "APPROVED",
				},
				style: {
					backgroundColor: "green",
					color: "white",
				},
			},
			{
				props: {
					label: "DENIED",
				},
				style: {
					backgroundColor: "red",
					color: "white",
				},
			},
			{
				props: {
					label: "TEST",
				},
				style: {
					backgroundColor: "black",
					color: "green",
				},
			},
		],
	},
};

export default components;
