import { Card, CardContent, CardHeader, Skeleton, Typography } from "@mui/material";

export default function NotesCard({ notes, customer }) {
	return customer ? (
		<Card>
			<CardHeader title="Notes" />
			<CardContent>
				<Typography>{notes || "No notes from customer"}</Typography>
			</CardContent>
		</Card>
	) : (
		<Skeleton variant="rectangular" />
	);
}
