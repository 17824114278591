import { Box } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import View from "src/components/View";
import ListResults from "src/components/list/ListResults";
import { useNavigate } from "react-router";
import useAlert from "src/utils/Alert";
import ListToolbar from "src/components/list/ListToolbar";
import API from "src/API";
import { useMerchant } from "../../layouts/merchant/MerchantLayout";

export default function OrdersView() {
	const [orders, setOrders] = useState([]);

	const [cursor, setCursor] = useState({ next: null, prev: null });
	const [isLoading, setLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const { merchant } = useMerchant();
	const { error } = useAlert();
	const nav = useNavigate();

	const searchOrder = (terms) => {
		setLoading(true);
		API.merchants
			.queryStoreOrders(merchant._id, terms)
			.then(({ orders }) => {
				setOrders(orders);
			})
			.catch(console.error)
			.finally(setLoading);
	};

	const headers = [
		{
			id: "name",
			label: "Order Number",
			onClick: (r) => nav(String(r._id)),
			render: (n, o) => <b>{n}</b>,
		},
		{
			id: "customer.email",
			label: "Customer Email",
		},
		{
			id: "createdAt",
			label: "Order Date",
			render: (v) => new Date(v).toLocaleString(),
		},
		{
			id: "totals.total",
			label: "Total",
			render: (n, o) => "$" + n,
		},
		{
			id: "lineItems",
			label: "SavedBy",
			onClick: (r) => window.alert(JSON.stringify(r.lineItems, null, 2)),
			render: (n, o) => o.hasSavedBy && "$" + o.totals.savedBy,
		},
	];

	const getResults = useCallback(
		(cursor = {}) => {
			setLoading(true);

			API.merchants
				.getStoreOrders(merchant._id, new URLSearchParams(cursor))
				.then(({ orders, count, next, prev }) => {
					setTotalCount(count);
					setOrders(orders);
					setCursor({ next, prev });
				})
				.catch(error)
				.finally(setLoading);
		},
		[merchant._id, error]
	);

	useEffect(() => {
		getResults();
	}, [getResults]);

	return merchant ? (
		<View.Merchant title="Orders" isLoading={isLoading}>
			<ListToolbar searchLabel="Search Orders" searchFunc={searchOrder} clearFunc={() => getResults()} />
			<Box sx={{ pt: 3 }}>
				<ListResults
					{...{
						rowsPerPage: 20,
						results: orders,
						totalCount,
						isLoading,
						headers: headers,
						getResults: (direction) => getResults(cursor[direction]),
					}}
				/>
			</Box>
		</View.Merchant>
	) : null;
}
