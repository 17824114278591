import { Box, Chip, Stack, Typography } from "@mui/material";
import View from "src/components/View";
import { useNavigate } from "react-router";
import API from "src/API";
import { BiCircle } from "react-icons/bi";
import { SiShopify, SiWix, SiWordpress } from "react-icons/si";
import MuiImage from "mui-image";
import { useQuery } from "react-query";
import useAlert from "src/utils/Alert";
import merchantColors from "src/components/merchantColors";
import { DataGrid } from "@mui/x-data-grid";
import SearchBar from "src/components/SearchBar";
import { useCallback, useState } from "react";
import StatusHeader from "src/components/StatusHeader";

const MerchantsView = () => {
	const [filteredMerchants, setFilteredMerchants] = useState([]);
	const [statusFilters, setStatusFilters] = useState(["ACTIVE", "PAUSED"]);
	const nav = useNavigate();
	const { error } = useAlert();

	const { data: merchants, isLoading } = useQuery("merchants", () =>
		API.merchants
			.getAll()
			.then(({ merchants }) => merchants)
			.catch(error)
	);

	const platformIcons = {
		WOOCOMMERCE: <SiWordpress />,
		SHOPIFY: <SiShopify />,
		WIX: <SiWix />,
		undefined: <BiCircle />,
	};

	/**
	 * @type {import("@mui/x-data-grid").GridColDef}
	 */
	const columns = [
		{
			field: "status",
			headerName: "Status",
			renderCell: ({ value }) => <Chip label={value} sx={merchantColors[value]} />,
			minWidth: 150,
			disableColumnMenu: true,
			sortable: false,
			renderHeader: ({ colDef }) => <StatusHeader {...{ colDef, statusFilters, setStatusFilters, options }} />,
		},
		{
			field: "name",
			headerName: "Merchant",
			flex: 1,
			minWidth: 200,
			renderCell: ({ value, id }) => (
				<Stack direction="row" spacing={{ xs: 0, sm: 2 }} alignItems="center">
					<Box display={{ xs: "none", md: "block" }}>
						<MuiImage
							height="100%"
							wrapperStyle={{ width: 150, height: 45 }}
							style={{ objectFit: "contain" }}
							errorIcon={null}
							src={`https://cdn.savedby.io/logos/merchants/${id}.png`}
							alt={`${value} logo`}
						/>
					</Box>
					<Typography noWrap>{value}</Typography>
				</Stack>
			),
		},
		{
			field: "platform",
			headerName: "Platform",
			minWidth: 200,
			filterable: false,
			renderCell: ({ value, row }) => (
				<Stack spacing={1} direction="row" alignItems="center">
					{platformIcons[value]}
					<Typography>{row.shopInfo.plan_display_name}</Typography>
				</Stack>
			),
		},
	];

	const onResults = useCallback(
		(results) => {
			setFilteredMerchants((m) => {
				if (statusFilters?.length) {
					return results.filter((m) => statusFilters.includes(m.status));
				}
				return results;
			});
		},
		[statusFilters]
	);

	return (
		<View title="Merchants" isLoading={isLoading}>
			<Box sx={{ pt: 3, width: "100%" }}>
				<SearchBar sx={{ pb: 2 }} data={merchants} onResults={onResults} searchFunctions={searchFunctions} />
				<DataGrid onRowClick={({ id }) => nav(id)} rows={filteredMerchants} columns={columns} getRowId={({ _id }) => _id} />
			</Box>
		</View>
	);
};

export default MerchantsView;

const searchFunctions = [(search, row) => row.name.toLowerCase().includes(search.toLowerCase())];

const options = ["ACTIVE", "PAUSED", "TESTING", "DEVELOPMENT", "ONBOARDING", "INACTIVE", "UNINSTALLED"];
