import { Card, CardContent, CardHeader, Skeleton, Stack, Table, TableRow, TableCell, TableBody, TableHead } from "@mui/material";
import { withErrorBoundary } from "@sentry/react";
import FallBackCard from "../../components/FallBackCard";
import withLoading from "../../utils/withLoading";
import currency from "src/utils/currency";

function RefundCard({ refunds }) {
	if (!refunds) return null;
	return (
		<Card sx={{ display: refunds.length === 0 ? "none" : "block" }}>
			<CardHeader title="Refunds" />

			<CardContent>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Description</TableCell>
							<TableCell align="right">Amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{refunds?.map(({ amount, description, createdAt }, i) => (
							<TableRow key={i}>
								<TableCell>{new Date(createdAt).toLocaleString("en", { dateStyle: "short", timeStyle: "short" })}</TableCell>
								<TableCell>{description}</TableCell>
								<TableCell align="right">{currency(amount)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
}

function Loading() {
	return (
		<Card>
			<CardHeader title="Refunds" />
			<CardContent>
				<Stack>
					<Skeleton width={500} />
					<Skeleton width={500} />
					<Skeleton width={500} />
					<Skeleton width={500} />
					<Skeleton width={500} />
				</Stack>
			</CardContent>
		</Card>
	);
}

export default withErrorBoundary(withLoading(RefundCard, Loading), { fallback: FallBackCard("Refunds", "Error loading claim refunds") });
