import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { AuthProvider } from "./providers/AuthProvider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClientProvider, QueryClient } from "react-query";
import { NotificationProvider } from "./providers/NotificationProvider";

Sentry.init({
	dsn: "https://38b3ab06b0bf4e96b1259568ec9b1e1d@o1209587.ingest.sentry.io/6617624",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
	enabled: !!process.env.NODE_ENV === "production",
});

console.log(process.env.REACT_APP_VERSION);

const client = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity,
		},
	},
});

ReactDOM.render(
	<BrowserRouter>
		<QueryClientProvider client={client}>
			<AuthProvider>
				<NotificationProvider>
					<App />
				</NotificationProvider>
			</AuthProvider>
		</QueryClientProvider>
	</BrowserRouter>,
	document.getElementById("root")
);

serviceWorker.unregister();
