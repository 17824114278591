import { Grid, Skeleton, Typography } from "@mui/material";

export default function OrderHeader({ merchant, order }) {
	return order ? (
		<Grid item xs={12}>
			<Typography variant="subtitle2" textAlign="center">
				{merchant?.name || merchant?.mid}
			</Typography>
			<Typography variant="subtitle2" textAlign="center">
				{new Date(order.createdAt).toLocaleString()}
			</Typography>
		</Grid>
	) : (
		<Skeleton variant="text" />
	);
}
