import { Button, ButtonGroup, ClickAwayListener, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles({
	input: {
		"& input[type=number]": {
			"-moz-appearance": "textfield",
		},
		"& input[type=number]::-webkit-outer-spin-button": {
			"-webkit-appearance": "none",
			margin: 0,
		},
		"& input[type=number]::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			margin: 0,
		},
	},
});

export default function QuantityInput({ value, onChange, label, disabled, max, icon }) {
	const [open, setOpen] = useState(false);

	const handleChange = (v) => {
		onChange(Math.min(v, max));
	};

	const handlePartialUpdate = (v) => {
		onChange(+(Math.floor(value) + v).toFixed(2));
		setOpen(false);
	};

	const classes = useStyles();

	return (
		<Stack alignItems="center">
			<Stack direction="row" spacing={1} alignItems="center">
				{icon}
				<Typography variant="caption">{label}</Typography>
			</Stack>
			<ButtonGroup>
				<Button variant="contained" onClick={() => onChange(Math.max(0, value - 1))} disabled={disabled || value === 0}>
					-
				</Button>

				<Tooltip
					arrow
					title={<PartialQuantity onClick={handlePartialUpdate} onClose={() => setOpen(false)} max={max - Math.floor(value)} />}
					placement="bottom"
					open={open}
					disableHoverListener
					componentsProps={{ tooltip: { sx: { bgcolor: "#D3D3D3" } }, arrow: { sx: { color: "#D3D3D3" } } }}
				>
					<Button variant="text" disableRipple disableFocusRipple sx={{ cursor: "default", bgcolor: value > Math.max(max, 0) && "red" }}>
						<TextField
							disabled={disabled}
							autoComplete="false"
							variant="standard"
							inputProps={{ min: "0", style: { textAlign: "center" } }}
							onClick={() => disabled || value >= max || setOpen(true)}
							InputProps={{ disableUnderline: true }}
							type="number"
							value={String(value)}
							onChange={({ target }) => handleChange(+target.value)}
							className={classes.input}
							sx={{ width: 50 }}
						/>
					</Button>
				</Tooltip>

				<Button variant="contained" onClick={() => onChange(value + 1)} disabled={disabled || value + 1 > max}>
					+
				</Button>
			</ButtonGroup>
		</Stack>
	);
}

function PartialQuantity({ onClick, onClose, max }) {
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);

	const handleClick = () => {
		onClick(x / y);
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Stack direction="row" alignItems="center" spacing={1}>
				<PartialInput value={x} onChange={({ target }) => setX(Math.min(+target.value, y))} disabled={!y} />

				<Typography color="black">/</Typography>
				<PartialInput value={y} onChange={({ target }) => setY(+target.value)} />

				<Button disabled={!x || !y || x / y > max} variant="contained" onClick={handleClick}>
					ADD
				</Button>
			</Stack>
		</ClickAwayListener>
	);
}

function PartialInput({ value, onChange, disabled }) {
	const c = useStyles();

	return (
		<TextField
			size="small"
			disabled={disabled}
			onFocus={(e) => e.target.select()}
			type="number"
			inputProps={{ min: "0", style: { textAlign: "center" } }}
			className={c.input}
			value={String(value)}
			onChange={onChange}
			autoComplete="false"
		/>
	);
}
