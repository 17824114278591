import { Stack, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "src/providers/AuthProvider";
import API from "src/API";
import useAlert from "src/utils/Alert";
import CustomerCard from "src/components/order/CustomerCard";
import NotesCard from "src/components/order/NotesCard";
import OrderHeader from "src/components/order/OrderHeader";
import FinancialCard from "src/components/order/FinancialCard";
import ItemsCard from "src/components/order/ItemsCard";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import Money from "src/Money";
import View from "src/components/View";

const OrderView = () => {
	const [isLoading, setLoading] = useState(true);
	const [order, setOrder] = useState();
	const { oid } = useParams();
	const { merchant } = useMerchant();
	const nav = useNavigate();
	const { displayName } = useAuth().userInfo;
	const { error } = useAlert();
	const [claim, setClaim] = useState();

	useEffect(() => {
		if (!merchant?._id) return;
		setLoading(true);
		Promise.all([
			API.merchants
				.getStoreOrder(merchant._id, oid)
				.then(({ order }) => setOrder(order))
				.catch(error),
			API.claims
				.find(merchant._id, oid)
				.then(setClaim)
				.catch((e) => {
					if (!/404/.test(e)) error(e);
				}),
		]).finally(setLoading);
		setClaim();
	}, [merchant?._id, error, oid]);

	const startClaim = () => {
		setLoading(true);
		API.claims
			.create({
				mid: merchant._id,
				merchantName: merchant.name,
				email: order.customer.email,
				orderId: Number(oid),
				orderName: order.name,
				source: "ADMIN",
				createdBy: displayName,
				customer: order.customer,
				shippingAddress: order.shippingAddress,
				shippingTotal: order.shippingLines.reduce((p, c) => p.sum(c.price, c.tax), new Money()).toString(),
			})

			.then(({ id }) => (id ? nav("/claims/" + id) : error("No claim was returned from creation")))
			.catch(error)
			.finally(setLoading);
	};

	return (
		<View.Merchant title={order?.name + " - " + merchant?.name} isLoading={!(order && merchant) || isLoading} rightMenu={[{ label: claim ? "Go to claim" : "Start Claim", onClick: claim ? () => nav(`/claims/${claim._id}`) : startClaim }]}>
			{order && (
				<Grid item container spacing={1}>
					{/* TOP */}
					<OrderHeader {...{ order, merchant }} />

					{/* LEFT */}
					<Grid container direction="column" item xs={12} md={9} spacing={1}>
						{/* ITEMS */}
						<Grid item>
							<ItemsCard {...order} />
						</Grid>

						{/* FINANCIAL */}
						<Grid item>
							<FinancialCard {...order} />
						</Grid>
					</Grid>

					{/* RIGHT */}
					<Grid item xs={12} md={3}>
						<Stack spacing={1}>
							{/* NOTES */}
							<NotesCard {...order} />

							{/* CUSTOMER */}
							<CustomerCard {...order} />
						</Stack>
					</Grid>
				</Grid>
			)}
		</View.Merchant>
	);
};

export default OrderView;
