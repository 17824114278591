/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import API from "src/API";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import LoadingIndicator from "../components/LoadingIndicator";

const CustomerFaqView = () => {
	const [isLoading, setLoading] = useState(false);
	const [topics, setTopics] = useState([]);
	const theme = useTheme();

	const handleChange = (FAQ, update) => {
		setTopics((FAQs) => {
			Object.assign(FAQ, update, { mutated: true });
			return [...FAQs];
		});
	};

	useEffect(() => {
		if (API.customer_faqs) {
			setLoading(true);
			API.customer_faqs
				.getAll()
				.then(setTopics)
				.catch(console.error)
				.finally(() => setLoading(false));
		}
	}, []);

	const handleSave = (topic, f) => {
		setLoading(true);
		API.customer_faqs
			.update(topic.id, f)
			.then((faq) => {
				setTopics((topics) => {
					Object.assign(f, faq, { mutated: false });
					return [...topics];
				});
			})
			.catch(console.error)
			.finally(() => setLoading(false));
	};

	const handleNewTopic = () => {
		// TODO -- endpoint for new topic
		API.customer_faqs.createTopic({ label: "New Topic", index: topics.length }).then((topic) => {
			setTopics((topics) => {
				topics.push(topic);
				return [...topics];
			});
		});
	};

	const handleDeleteFaq = (topic, faq) => {
		if (window.confirm(`Are you sure you want to delete FAQ "${faq.question}"?`)) {
			setLoading(true);
			API.customer_faqs
				.delete(topic.id, faq.id)
				.then(() => {
					setTopics((topics) => {
						topic.faqs = topic.faqs.filter((f) => f !== faq);
						return [...topics];
					});
				})
				.finally(() => setLoading(false));
		}
	};

	const handleNewFaq = (label) => {
		setTopics((topics) => {
			const foundTopic = topics.find((t) => t.label === label);
			if (foundTopic) {
				const newFaq = {
					question: "",
					answer: "",
					mutated: true,
				};
				newFaq.index = foundTopic.faqs.push(newFaq) - 1;
			}
			return [...topics];
		});
	};

	const handleChangeTopicName =
		(topic) =>
		({ target }) => {
			setTopics((topics) => {
				topic.label = target.value;
				topic.mutated = true;
				return [...topics];
			});
		};

	const handleDeleteTopic = (topic) => {
		if (window.confirm(`Are you sure you want to delete ${topic.label}?`)) {
			setLoading(true);
			API.customer_faqs
				.deleteTopic(topic.id)
				.then(() => {
					setTopics((topics) => topics.filter((t) => t !== topic));
				})
				.finally(() => setLoading(false));
		}
	};

	const handleSaveTopic = (topic) => {
		setLoading(true);
		API.customer_faqs
			.updateTopic(topic)
			.then(() => {
				setTopics((topics) => {
					topic.mutated = false;
					return [...topics];
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<Box p={2}>
			<List>
				<Box>
					{topics.map((t, i) => (
						<Fragment key={i}>
							<TextField variant="standard" value={t.label} onChange={handleChangeTopicName(t)} />
							<IconButton onClick={() => handleDeleteTopic(t)}>
								<Delete />
							</IconButton>
							<IconButton onClick={() => handleSaveTopic(t)} disabled={!t.mutated}>
								<SaveIcon />
							</IconButton>

							{t.faqs.map((f) => (
								<ListItem key={f.id}>
									<Box
										component={Accordion}
										padding={1}
										borderRadius={`${theme.spacing(1)} !important`}
										width="100%"
										sx={{
											"&::before": {
												display: "none",
											},
										}}
									>
										<Box component={AccordionSummary} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={`panel1a-header--${i}`}>
											<TextField fullWidth label="Question" variant="standard" value={f.question} onChange={({ target }) => handleChange(f, { question: target.value })} />
										</Box>

										<AccordionDetails>
											<Stack>
												<TextField multiline label="Answer" variant="standard" value={f.answer} style={{ p: 1 }} onChange={({ target }) => handleChange(f, { answer: target.value })} />
											</Stack>
											<IconButton onClick={() => handleDeleteFaq(t, f)}>
												<Delete />
											</IconButton>
											<IconButton onClick={() => handleSave(t, f)} disabled={!f.mutated}>
												<SaveIcon />
											</IconButton>
										</AccordionDetails>
									</Box>
								</ListItem>
							))}
							<ListItem sx={{ mb: 2 }}>
								<Button onClick={() => handleNewFaq(t.label)}>New FAQ</Button>
							</ListItem>
						</Fragment>
					))}
					<Button onClick={handleNewTopic}>New Topic</Button>
				</Box>
			</List>
			<LoadingIndicator open={isLoading} />
		</Box>
	);
};

export default CustomerFaqView;
