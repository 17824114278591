import { Stack, Button, TableContainer, Table, TableHead, ListSubheader, TableRow, TableCell, TableBody, Paper, TextField, Checkbox, Card, IconButton, Skeleton, CardContent, CardHeader, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import API from "src/API";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import { Delete, WarningAmber } from "@mui/icons-material";
import View from "src/components/View";

const WidgetConfigView = () => {
	const [isLoading, setLoading] = useState();
	const { merchant } = useMerchant();
	const [widgetConfig, setWidgetConfig] = useState();
	const [feeTiers, setFeeTiers] = useState();
	const [product, setProduct] = useState();

	useEffect(() => {
		if (merchant) {
			setWidgetConfig(merchant.widgetConfig);
			setFeeTiers(merchant.feeTiers);

			API.merchants.product.get(merchant._id).then(setProduct).catch(console.error).finally(setLoading);
		}
	}, [merchant]);

	const handleSave = () => {
		API.merchants.widget.update(merchant._id, widgetConfig);
	};

	return (
		<View.Merchant title="Merchant" isLoading={isLoading} rightMenu={[{ onClick: handleSave, label: "Save" }]}>
			<Stack spacing={1}>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={2}>
									<ListSubheader disableSticky>Widget Configuration</ListSubheader>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Exclusions</TableCell>
								<TableCell>
									<TextField value={widgetConfig?.exclusions || ""} onChange={({ target }) => setWidgetConfig((w) => ({ ...w, exclusions: target.value }))} />
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell>Max Coverage</TableCell>
								<TableCell>
									<TextField type="number" step="0.1" min={0} value={widgetConfig?.maxCoverage || ""} onChange={({ target }) => setWidgetConfig((w) => ({ ...w, maxCoverage: target.value }))} />
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell>Auto Opt-in</TableCell>
								<TableCell>
									<Checkbox checked={!!widgetConfig?.optInCond} onChange={(e, v) => setWidgetConfig((w) => ({ ...w, optInCond: v }))} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<FeeTiersCard {...{ feeTiers, product, merchant }} />

				<ContentEditor {...{ widgetConfig, setWidgetConfig }} />
			</Stack>
		</View.Merchant>
	);
};

function ContentEditor({ widgetConfig, setWidgetConfig }) {
	if (!widgetConfig) return <Skeleton />;
	return (
		<Card>
			<CardContent>
				<TextField fullWidth label="Popup Content" value={widgetConfig.popupContent || ""} multiline rows={10} onChange={({ target }) => setWidgetConfig((w) => ({ ...w, popupContent: target.value }))} />
			</CardContent>
		</Card>
	);
}

function FeeTiersCard({ feeTiers, product, merchant }) {
	const [tiers, setTiers] = useState([]);

	useEffect(() => {
		if (product && feeTiers) {
			const tiers = [];

			for (const variant of product.variants) {
				const tier = feeTiers.find((t) => t.variantId === variant.id && !t._) || {};

				tiers.push({
					...tier,
					max: tier.max,
					price: tier.price || variant.price, // ? what if they mismatch
					variantId: tier.variantId || variant.id,
					variant: variant,
				});
				if (tier) tier._ = true;
			}
			tiers.push(...feeTiers.filter((t) => !t._));

			setTiers(tiers.sort((a, b) => a.price - b.price));
		}
	}, [feeTiers, product]);

	const addTier = () => {
		setTiers((t) => {
			if (t.length < 2) {
				return [...t, { price: 0, max: 0, variant: { sku: "savedby" } }];
			}
			const max = +t
				.slice(-2)
				.reduce((p, c) => 2 * c.max - p.max)
				.toFixed(2);
			const price = t
				.slice(-2)
				.reduce((p, c) => 2 * c.price - p.price)
				.toFixed(2);
			return [...t, { price, max, variant: { sku: "savedby" } }];
		});
	};

	const deleteFeeTier = (tier) => {
		return new Promise((resolve, reject) => {
			if (tier.variantId) {
				if (window.confirm("Delete Tier?")) {
					API.merchants.widget
						.deleteFeeTier(merchant._id, tier.variantId)
						.then(() => {
							setTiers((tiers) => tiers.filter((t) => t !== tier));
							resolve();
						})
						.catch(reject);
				}
			} else {
				setTiers((tiers) => tiers.filter((t) => t !== tier));
				resolve();
			}
		});
	};

	const updateFeeTier = (tier) => {
		return API.merchants.widget.updateFeeTier(merchant._id, tier);
	};

	return (
		<TableContainer component={Card}>
			<CardHeader title="Fee Tiers" />
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Fee</TableCell>
						<TableCell>Total</TableCell>
						<TableCell>SKU</TableCell>
						<TableCell colSpan={3}>Variant ID</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tiers?.map((feeTier, key) => (
						<FeeTier {...{ key, feeTier, deleteFeeTier, updateFeeTier }} />
					))}
					<TableRow>
						<TableCell>
							<Button onClick={addTier}>Add Tier</Button>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function FeeTier({ feeTier, deleteFeeTier, updateFeeTier }) {
	const [mutated, setMutated] = useState(!feeTier.variantId || feeTier.inventoryManagement || feeTier.variantIdError);
	const [tier, setTier] = useState(feeTier);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTier(feeTier);
	}, [feeTier]);

	const handleChange =
		(key) =>
		({ target }) => {
			setMutated(true);
			setTier((t) => ({ ...t, [key]: target.value }));
		};

	const handleChangeSKU = ({ target }) => {
		setMutated(true);
		setTier((t) => ({ ...t, variant: { ...t.variant, sku: target.value } }));
	};

	const handleUpdate = () => {
		setLoading(true);
		updateFeeTier(tier)
			.then(setTier)
			.then(() => setMutated(false))
			.catch(console.error)
			.finally(setLoading);
	};

	const handleDelete = () => {
		setTier((t) => ({ ...t, deleting: true }));
		deleteFeeTier(tier);
	};

	const issues = [];
	if (tier.variant) {
		if (tier.variant.inventory_policy !== "continue") issues.push("• Inventory Policy is not set to 'continue'");
		if (tier.variant.inventory_management !== null) issues.push("• Inventory Management is not set to 'null'");
	}
	if (!tier.variant) issues.push("• Does not exist in Shopify");
	if (tier.max === null) issues.push("• This tier does not exist on config");

	let bgcolor;
	if (loading) {
		bgcolor = "lightblue";
	} else if (!tier.variantId) {
		bgcolor = "lightgreen";
	} else if (tier.deleting) {
		bgcolor = "red";
	} else if (issues.length) {
		bgcolor = "orange";
	}

	return (
		<TableRow sx={{ bgcolor }} disabled={loading}>
			<TableCell>
				<TextField size="small" value={tier.price} onChange={handleChange("price")} />
			</TableCell>
			<TableCell>
				<Stack spacing={1} alignItems="center" direction="row">
					<TextField size="small" type="number" inputProps={{ min: "0" }} value={tier.max} onChange={({ target }) => handleChange("max")({ target: { ...target, value: +target.value } })} />
				</Stack>
			</TableCell>
			<TableCell>
				<TextField size="small" value={tier.variant?.sku || ""} onChange={handleChangeSKU} />
			</TableCell>
			<TableCell>
				<Stack spacing={1} alignItems="center" direction="row">
					{tier.variantId}
					{!!issues.length && (
						<Tooltip title={issues.join("\n")} PopperProps={{ sx: { whiteSpace: "break-spaces" } }}>
							<WarningAmber />
						</Tooltip>
					)}
				</Stack>
			</TableCell>
			<TableCell>
				<Button onClick={handleUpdate} disabled={!(issues.length || mutated)}>
					{feeTier.variantId ? "Update" : "Create"}
				</Button>
			</TableCell>
			<TableCell>
				<IconButton onClick={handleDelete}>
					<Delete />
				</IconButton>
			</TableCell>
		</TableRow>
	);
}

export default WidgetConfigView;
