export default class Money {
	constructor(amount = 0) {
		const [dollars, cents = "0"] = String(amount).split(".");
		this.#cents = dollars * 100;
		this.#cents += cents / Math.pow(10, cents.length - 2);
	}
	#cents = 0;

	valueOf() {
		return this.#cents;
	}

	toString() {
		return (Math.floor(this.#cents) / 100).toFixed(2);
	}

	toFloat() {
		return Math.floor(this.#cents) / 100;
	}

	add(amount) {
		if (amount instanceof Money) {
			this.#cents += amount;
		} else {
			this.#cents += new Money(amount);
		}
		return this;
	}

	sum(...amounts) {
		for (const amount of amounts) {
			if (amount instanceof Money) {
				this.#cents += amount;
			} else {
				this.#cents += new Money(amount);
			}
		}
		return this;
	}

	sub(amount) {
		if (amount instanceof Money) {
			this.#cents -= amount;
		} else {
			this.#cents -= new Money(amount);
		}
		return this;
	}

	divide(value) {
		this.#cents /= value || 0;

		return this;
	}

	multiply(value) {
		this.#cents *= value || 0;

		return this;
	}
}
