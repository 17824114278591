import { InfoOutlined } from "@mui/icons-material";
import { Button, MenuItem, Stack, Select, TextField, Dialog, DialogTitle, DialogContent, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

export default function AddAdjustmentDialog({ open, onClose, addAdjustment, merchants }) {
	const [merchantId, setMerchantId] = useState("");
	const [amount, setAmount] = useState(0);
	const [month, setMonth] = useState("");
	const [description, setDescription] = useState("");

	const handleSubmit = () => {
		addAdjustment({ mid: merchantId, amount, description, month });
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<DialogTitle>
				<Stack direction="row" justifyContent="space-between">
					<Typography>Add Adjustment</Typography>
					<Tooltip
						title={
							<>
								Negative amounts are credited on the statement.
								<br />
								Positive amounts are charged on the statement
							</>
						}
					>
						<InfoOutlined />
					</Tooltip>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Select value={merchantId || "default"} onChange={({ target }) => setMerchantId(target.value)}>
						<MenuItem value={"default"} disabled>
							Select Merchant
						</MenuItem>
						{merchants.map((m, i) => {
							return (
								<MenuItem value={m._id} key={i}>
									{m.name}
								</MenuItem>
							);
						})}
					</Select>

					<TextField type="month" value={month} InputLabelProps={{ shrink: true }} label="Billing Month" onChange={({ target }) => setMonth(target.value)} />
					<TextField type="number" value={amount || ""} label="Amount" onChange={({ target }) => setAmount(+target.value)} inputProps={{ step: 0.01 }} onWheel={(e) => e.target.blur()} onFocus={(e) => e.target.select()} onBlur={(e) => setAmount((a) => +a.toFixed(2))} />
					<TextField multiline minRows={3} value={description} label="Description" onChange={({ target }) => setDescription(target.value)} />
					<Button onClick={handleSubmit} disabled={!(amount && description && merchantId && month) || amount === "0.00"}>
						Submit
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
