import { useEffect, useState } from "react";
import {
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	Menu,
	MenuItem,
	Paper,
	Stack,
	TextField,
	ListItemText,
	ListSubheader,
} from "@mui/material";
import { AccountBalance, CalendarMonth, CreditCard, MoreVert, Store, Tag } from "@mui/icons-material";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import { usePaymentInputs, PaymentInputsWrapper } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import API from "src/API";
import View from "src/components/View";
import useAlert from "src/utils/Alert";

const font13 = { fontSize: { xs: "13px !important", md: "1rem !important" } };
const font14 = { fontSize: { xs: "14px !important", md: "1rem !important" } };

const PaymentMethodsView = () => {
	const [isLoading, setLoading] = useState(true);
	const [appCharges, setAppCharges] = useState([]);
	const [createAppChargeOpen, setCreateAppChargeOpen] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [addOpen, setAddOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const { merchant } = useMerchant();
	const { success, error } = useAlert();

	useEffect(() => {
		setPaymentMethods([]);
		setAppCharges([]);
		if (merchant) {
			setLoading(true);
			API.merchants.paymentMethods
				.get(merchant._id)
				.then(async ({ paymentMethods }) => {
					setPaymentMethods(paymentMethods);
					await API.merchants.getAppCharges(merchant._id).then((r) => setAppCharges(r.appCharges));
				})
				.catch(error)
				.finally(setLoading);
		}
	}, [merchant, error]);

	const addACH = () => {
		setLoading(true);
		API.merchants.paymentMethods
			.add(merchant._id, { ACH: true })
			.then(({ paymentMethods }) => {
				success("ACH Payment Added");
				setPaymentMethods(paymentMethods);
			})
			.catch(error)
			.finally(setLoading);
	};

	const deleteACH = () => {
		setLoading(true);
		API.merchants.paymentMethods
			.delete(merchant._id)
			.then(() => {
				success("ACH Payment Deleted");
				setPaymentMethods((paymentMethods) => {
					delete paymentMethods.ACH;
					return { ...paymentMethods };
				});
			})
			.catch(error)
			.finally(setLoading);
	};

	/**
	 *
	 * @param {String} newDefault The new default payment method
	 * @param {Object} data
	 * @param {String} data.Shopify App Charge ID
	 * @param {Boolean} data.ACH If ACH is active
	 * @param {Boolean} data.other If other should be active
	 */
	const changeDefaultPaymentMethod = (newDefault, data = {}) => {
		setLoading(true);
		API.merchants.paymentMethods
			.makeDefault(merchant._id, newDefault, data)
			.then(({ paymentMethods }) => {
				success("Default Payment Method Updated");
				setPaymentMethods(paymentMethods);
			})
			.catch(error)
			.finally(setLoading);
	};

	const handleCreateAppCharge = (cappedAmount) => {
		setLoading(true);
		return API.merchants
			.createAppCharge(merchant._id, cappedAmount)
			.then(({ appCharge }) => {
				success("App Charge Created");
				setCreateAppChargeOpen(false);
				setAppCharges((appCharges) => {
					appCharges.splice(0, 0, appCharge);
					return [...appCharges];
				});
			})
			.catch(error)
			.finally(setLoading);
	};

	const addCard = (cardInfo) =>
		new Promise((resolve, reject) => {
			setLoading(true);
			API.merchants.paymentMethods
				.add(merchant._id, { card: cardInfo })
				.then(({ paymentMethods }) => {
					setPaymentMethods(paymentMethods);
					resolve();
				})
				.catch(reject)
				.finally(setLoading);
		});

	const activeAppCharges = appCharges.filter((appCharge) => appCharge.status === "active" && !appCharge.test);

	const options = [
		{
			id: 1,
			name: "ACH",
			onClick: () => {
				addACH();
				setAnchorEl(null);
			},
		},
		{
			id: 2,
			name: "Card",
			onClick: () => {
				setAddOpen(true);
				setAnchorEl(null);
			},
		},
		{
			id: 3,
			name: "Shopify",
			onClick: () => {
				setCreateAppChargeOpen(true);
				setAnchorEl(null);
			},
		},
	];

	if (!paymentMethods || !merchant) return null;

	return (
		<View.Merchant
			title="Merchant"
			isLoading={isLoading}
			rightAction={
				<Stack sx={{ mt: { xs: 2, sm: 0 } }}>
					<Button onClick={({ target }) => setAnchorEl(target)} variant="contained">
						Add Payment
					</Button>
					<Menu width="100%" anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
						{options.map((option) => (
							<MenuItem key={option.id} onClick={option?.onClick}>
								{option.name}
							</MenuItem>
						))}
					</Menu>
				</Stack>
			}
		>
			<Stack sx={{ my: "32px !important" }}>
				<Paper>
					<List sx={{ width: "100%", pb: 0 }}>
						<ListSubheader sx={{ fontSize: "24px", color: "black" }}>Payment methods</ListSubheader>
						<Divider />
						{activeAppCharges?.map((activeAppCharge, i) => (
							<AppChargeRow key={i} {...{ activeAppCharge, changeDefaultPaymentMethod, paymentMethods, error, success, i }} />
						))}
						{paymentMethods.cards?.map((card, i) => (
							<CardRow key={i} {...{ card, changeDefaultPaymentMethod, paymentMethods, i }} />
						))}
						{paymentMethods.ACH && <ACHRow {...{ changeDefaultPaymentMethod, deleteACH, paymentMethods }} />}
					</List>
				</Paper>
			</Stack>
			<AddCardDialog {...{ open: !!addOpen, onClose: () => setAddOpen(false), addCard }} />
			<CreateAppChargeDialog {...{ open: createAppChargeOpen, onClose: () => setCreateAppChargeOpen(false), handleCreateAppCharge }} />
		</View.Merchant>
	);
};

export default PaymentMethodsView;

function AppChargeRow({ activeAppCharge, changeDefaultPaymentMethod, paymentMethods, error, success, i }) {
	const [anchorEl, setAnchorEl] = useState(null);

	const copyLink = () => {
		if (activeAppCharge.confirmation_url) {
			navigator.clipboard.writeText(activeAppCharge.confirmation_url);
			success("Billing Link Copied!");
		} else {
			error("No Billing Link Available");
		}
		setAnchorEl(null);
	};

	const isDefault = paymentMethods.default === "Shopify" && paymentMethods.Shopify === activeAppCharge.id;

	return (
		<Stack key={i}>
			<ListItem>
				<Grid container sx={{ alignItems: "center" }}>
					<Grid item xs={1}>
						<ListItemIcon sx={{ color: isDefault ? "#5664d2" : "black" }}>
							<Store />
						</ListItemIcon>
					</Grid>
					<Grid item xs={5} sm={3}>
						<Stack direction="row">
							<ListItemText primaryTypographyProps={font13}>Shopify App Charge</ListItemText>
						</Stack>
					</Grid>
					<Grid item xs={6} sm={3}>
						<ListItemText primaryTypographyProps={font13}>{activeAppCharge.name}</ListItemText>
					</Grid>
					<Grid item xs={5} sm={2}>
						<ListItemText primaryTypographyProps={font14}>{"$".concat(activeAppCharge.capped_amount)}</ListItemText>
					</Grid>
					<Grid item xs={5} sm={2}>
						{isDefault && <Chip color="primary" label="Default" sx={{ width: "100%" }} />}
					</Grid>
					<Grid item xs={2} sm={1}>
						<Stack direction="row" sx={{ justifyContent: "end" }}>
							<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
								<MoreVert />
							</IconButton>
							<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)} key={i}>
								<MenuItem
									onClick={() => {
										changeDefaultPaymentMethod("Shopify", { Shopify: activeAppCharge.id });
										setAnchorEl(null);
									}}
								>
									Make Default
								</MenuItem>
								<MenuItem onClick={copyLink}>Copy Link</MenuItem>
							</Menu>
						</Stack>
					</Grid>
				</Grid>
			</ListItem>
			<Divider />
		</Stack>
	);
}

function CardRow({ card, changeDefaultPaymentMethod, paymentMethods, i }) {
	const [anchorEl, setAnchorEl] = useState(null);

	const formatDate = (date) => {
		const newDate = new Date(date);
		const month = (newDate.getMonth() + 1).toString();
		const year = newDate.getFullYear().toString().slice(2);
		date = month.concat("/").concat(year);
		return date;
	};

	const isDefault = paymentMethods.default === card.id;

	return (
		<Stack key={i}>
			<ListItem>
				<Grid container sx={{ alignItems: "center" }}>
					<Grid item xs={1}>
						<ListItemIcon sx={{ color: isDefault ? "#5664d2" : "black" }}>
							<CreditCard />
						</ListItemIcon>
					</Grid>
					<Grid item xs={5} sm={3}>
						<ListItemText primaryTypographyProps={font13}>{card ? card?.name : "N/A"}</ListItemText>
					</Grid>
					<Grid item xs={3} sm={3}>
						<Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
							<Tag sx={{ color: isDefault && "#5664d2" }} />
							<ListItemText sx={{ whiteSpace: "break-spaces" }} primaryTypographyProps={font14}>
								{card ? card?.number : "N/A"}
							</ListItemText>
						</Stack>
					</Grid>
					<Grid item xs={3} sm={2}>
						<Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
							<CalendarMonth sx={{ color: isDefault && "#5664d2" }} />
							<ListItemText sx={{ whiteSpace: "break-spaces" }} primaryTypographyProps={font14}>
								{card ? formatDate(card?.exp) : "N/A"}
							</ListItemText>
						</Stack>
					</Grid>
					<Grid item xs={5} sm={2}>
						{isDefault && <Chip color="primary" label="Default" sx={{ width: "100%" }} />}
					</Grid>
					<Grid item xs={7} sm={1}>
						<Stack direction="row" sx={{ justifyContent: "end" }}>
							<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
								<MoreVert />
							</IconButton>
							<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
								<MenuItem
									onClick={() => {
										changeDefaultPaymentMethod(card.id);
										setAnchorEl(null);
									}}
								>
									Make Default
								</MenuItem>
							</Menu>
						</Stack>
					</Grid>
				</Grid>
			</ListItem>
			<Divider />
		</Stack>
	);
}

function ACHRow({ changeDefaultPaymentMethod, deleteACH, paymentMethods }) {
	const [anchorEl, setAnchorEl] = useState(null);

	const isDefault = paymentMethods.default === "ACH";
	return (
		<ListItem key="ACH">
			<Grid container sx={{ alignItems: "center" }}>
				<Grid item xs={1}>
					<ListItemIcon sx={{ color: isDefault ? "#5664d2" : "black" }}>
						<AccountBalance />
					</ListItemIcon>
				</Grid>
				<Grid item xs={2} md={3}>
					<ListItemText primaryTypographyProps={font14}>ACH</ListItemText>
				</Grid>
				<Grid item xs={1} md={3}>
					<ListItemText />
				</Grid>
				<Grid item xs={2}>
					<ListItemText />
				</Grid>
				<Grid item xs={5} md={2}>
					{isDefault && <Chip color="primary" label="Default" sx={{ width: "100%" }} />}
				</Grid>
				<Grid item xs={1}>
					<Stack direction="row" sx={{ justifyContent: "end" }}>
						<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
							<MoreVert />
						</IconButton>
						<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
							<MenuItem
								onClick={() => {
									changeDefaultPaymentMethod("ACH");
									setAnchorEl(null);
								}}
							>
								Make Default
							</MenuItem>
							<MenuItem
								onClick={() => {
									deleteACH();
									setAnchorEl(null);
								}}
							>
								Delete
							</MenuItem>
						</Menu>
					</Stack>
				</Grid>
			</Grid>
		</ListItem>
	);
}

function CreateAppChargeDialog({ open, onClose, handleCreateAppCharge }) {
	const [cappedAmount, setCappedAmount] = useState(0.0);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Create App Charge</DialogTitle>
			<DialogContent>
				<Stack spacing={2} style={{ paddingTop: 8 }}>
					<TextField type="number" label="Capped Amount" value={cappedAmount} onChange={({ target }) => setCappedAmount(Math.max(target.value, 0))} />

					<Button disabled={!cappedAmount > 0} fullWidth variant="contained" onClick={() => handleCreateAppCharge(cappedAmount)}>
						Create
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function AddCardDialog({ open, onClose, addCard }) {
	const [name, setName] = useState("");
	const [isLoading, setLoading] = useState();
	const { success, error } = useAlert();
	const { wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps, meta } = usePaymentInputs();

	const handleSubmit = (e) => {
		e.preventDefault();
		const { cardNumber, cvc, expiryDate } = e.target.elements;
		setLoading(true);
		addCard({
			name,
			number: cardNumber.value.replaceAll(" ", ""),
			cvv: +cvc.value,
			exp: expiryDate.value.replaceAll(" ", ""),
		})
			.then(() => {
				success("Payment Method Added!");
				onClose();
			})
			.catch((e) => console.error(e) || error("Unable to add payment method."))
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Dialog open={!!open} onClose={onClose} fullWidth>
			<DialogTitle>Add Payment Method</DialogTitle>
			<DialogContent sx={{ px: { xs: 1, sm: 2, md: 3 } }}>
				<Stack spacing={2} sx={{ p: { xs: 0, sm: 2 } }}>
					<form onSubmit={handleSubmit}>
						<Stack spacing={2}>
							<TextField label="Name On Card" value={name} disabled={isLoading} onChange={({ target }) => setName(target.value)} />
							<PaymentInputsWrapper {...wrapperProps}>
								<svg {...getCardImageProps({ images })} />
								<input {...getCardNumberProps()} />
								<input {...getExpiryDateProps()} />
								<input {...getCVCProps()} />
							</PaymentInputsWrapper>
							<Button variant="contained" disabled={!!(meta.error || isLoading || !name)} type="submit">
								Submit
								{isLoading && <CircularProgress sx={{ ml: 3 }} size={20} />}
							</Button>
						</Stack>
					</form>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
