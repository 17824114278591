import { Card, CardContent, CardHeader, Skeleton, Stack, Typography } from "@mui/material";
import { withErrorBoundary } from "@sentry/react";
import FallBackCard from "../../components/FallBackCard";
import withLoading from "../../utils/withLoading";

function CustomerInfo({ customer }) {
	if (!customer) return null;
	return (
		<Card>
			<CardHeader title="Customer" />
			<CardContent>
				<Stack>
					<Typography>
						{customer.firstName} {customer.lastName}
					</Typography>
					<Typography>{customer.email}</Typography>
					<Typography>{customer.phone}</Typography>
				</Stack>
			</CardContent>
		</Card>
	);
}

function Loading() {
	return (
		<Card>
			<CardHeader title="Customer" />

			<CardContent>
				<Stack>
					<Skeleton width={80} />
					<Skeleton width={200} />
				</Stack>
			</CardContent>
		</Card>
	);
}

export default withErrorBoundary(withLoading(CustomerInfo, Loading), { fallback: FallBackCard("Customer", "Error loading customer details") });
