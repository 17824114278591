import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import API from "src/API";
import View from "src/components/View";

export default function TransactionsView() {
	const { data: merchants = [] } = useQuery("merchants", () => API.merchants.getAll().then((r) => r.merchants));
	const { data: transactions = [], isLoading } = useQuery("transactions", () => API.transactions.getAll().then((r) => r.transactions));
	console.log(transactions);

	const rows = transactions.map((t) => ({ ...t, id: t._id }));

	/**
	 * @type {import("@mui/x-data-grid").GridColDef}
	 */
	const columns = [
		{
			field: "createdAt",
			headerName: "Date",
			width: 200,
			renderCell: ({ value, ...a }) => new Date(value).toLocaleString(),
		},
		{
			field: "description",
			headerName: "Description",
			flex: true,
		},
		{
			field: "account",
			headerName: "Account",
			width: 300,
			renderCell: ({ value }) => merchants.find((m) => m._id === value.mid)?.name || value.mid,
		},
		{
			field: "type",
			headerName: "Type",
			width: 200,
		},
		{
			field: "provider",
			headerName: "Provider",
			width: 200,
		},
		{
			field: "amount",
			headerAlign: "right",
			headerName: "Amount",
			align: "right",
			renderCell: ({ value }) => Number(value).toLocaleString("en", { currency: "USD", minimumFractionDigits: 2 }),
		},
	];

	return (
		<View open={false} title="Reports" isLoading={isLoading}>
			<DataGrid rows={rows} columns={columns} />
		</View>
	);
}
