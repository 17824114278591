/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import API from "src/API";
import List from "@mui/material/List";
import { ListItem, ListItemIcon } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import LoadingIndicator from "../components/LoadingIndicator";
import { useAuth } from "src/providers/AuthProvider";
import { useNavigate } from "react-router";

const TosListView = () => {
	const [isLoading, setLoading] = useState(false);
	const [terms, setTerms] = useState([]);
	const { userInfo } = useAuth();
	const nav = useNavigate();

	useEffect(() => {
		if (API.terms_of_service) {
			setLoading(true);
			API.terms_of_service
				.getAll()
				.then(setTerms)
				.catch(console.error)
				.finally(() => setLoading(false));
		}
	}, []);

	const handleNewTos = () => {
		API.terms_of_service.create({ who: userInfo.displayName }).then((id) => {
			nav(`${id}`);
		});
	};

	return (
		<Box p={2}>
			<List>
				<Box>
					<List>
						{terms.map((t, i) => (
							<ListItem key={i} button onClick={() => nav(t.id)} divider>
								<ListItemIcon>
									<CircleIcon sx={{ color: t.published ? "green" : "red" }} />
								</ListItemIcon>

								<ListItemText primary={new Date(t.createdAt).toLocaleString()} />
							</ListItem>
						))}
					</List>

					<Button onClick={handleNewTos}>New Tos</Button>
				</Box>
			</List>
			<LoadingIndicator open={isLoading} />
		</Box>
	);
};

export default TosListView;
