import { Skeleton, Stepper, Step, StepLabel } from "@mui/material";

export default function ProgressBar({ status, subStatus }) {
	if (!status) return <Skeleton variant="rectangular" height={100} animation="wave" />;

	let level = 0; // DRAFT CLAIM
	if (status === "REVIEWING") {
		level = 1;
	} else if (["DENIED", "CLOSED"].includes(status)) {
		level = 2;
	} else if (subStatus === "PENDING") {
		level = 3;
	} else if (subStatus === "PROCESSING") {
		level = 4;
	} else if (subStatus === "PROCESSED") {
		level = 5;
	}

	return (
		<Stepper alternativeLabel activeStep={level} sx={{ width: "100%" }}>
			<Step>
				<StepLabel>{level > 0 ? "SUBMITTED" : "DRAFT"}</StepLabel>
			</Step>

			<Step>
				<StepLabel>{level > 1 ? "REVIEWED" : "REVIEWING"}</StepLabel>
			</Step>

			<Step>
				<StepLabel error={status === "DENIED"}>{level >= 2 ? status : "APPROVED/DENIED"}</StepLabel>
			</Step>

			{!["DENIED", "CLOSED"].includes(status) && (
				<Step>
					<StepLabel>INTERNAL REVIEW</StepLabel>
				</Step>
			)}

			{!["DENIED", "CLOSED"].includes(status) && (
				<Step>
					<StepLabel>{level !== 4 ? "PROCESSED" : "PROCESSING"} </StepLabel>
				</Step>
			)}
		</Stepper>
	);
}
