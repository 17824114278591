import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-json";
import "prismjs/components/prism-clike";
import "prismjs/themes/prism-funky.css";
import { useEffect, useRef, useState } from "react";
import testJSON from "src/utils/testJson";

export default function CodeEditor({ disabled = false, value, setValue }) {
	const [code, setCode] = useState(JSON.stringify(value, null, "  "));
	const initialRender = useRef(true);

	useEffect(() => {
		setCode(JSON.stringify(value, null, "  "));
	}, [value]);

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			const parsed = testJSON(code);
			if (parsed && setValue) {
				setValue(parsed);
			}
		}
	}, [code, setValue]);

	return (
		<Editor
			value={code}
			onValueChange={(code) => setCode(code)}
			highlight={(code) => highlight(code, languages.json)}
			padding={10}
			tabSize={1}
			style={{
				color: "white",
				fontSize: 14,
				fontFamily: '"Fira code", "Fira Mono", monospace',
				backgroundColor: "black",
				width: "100%",
				borderStyle: "solid",
				borderWidth: 6,
				borderColor: testJSON(code) ? "green" : "red",
				borderRadius: "20px",
				height: "100%",
			}}
		/>
	);
}
