import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, ListItem, Collapse, ListItemText } from "@mui/material";

const NavItem = ({ href = "", onClick = undefined, icon: Icon, title, secondary, subLinks = [], ...rest }) => {
	const location = useLocation();
	const active = Boolean(matchPath(href, location.pathname) || subLinks.some((l) => matchPath(l.href, location.pathname)));
	const [open, setOpen] = useState();

	useEffect(() => {
		setOpen(active);
	}, [active]);

	// NAV PROPS
	let navProps = { onClick };
	if (subLinks.length) {
		navProps.onClick = () => setOpen((t) => !t);
	} else if (!onClick) {
		navProps = { to: href, component: RouterLink };
	}

	return (
		<>
			<ListItem
				disableGutters
				sx={{
					display: "flex",
					py: 0,
				}}
				{...rest}
			>
				<Button
					variant="default"
					sx={{
						color: active ? "primary.main" : "text.secondary",
						fontWeight: "medium",
						justifyContent: "flex-start",
						letterSpacing: 0,
						py: 1.25,
						textTransform: "none",
						width: "100%",
						"& svg": {
							mr: 1,
						},
					}}
					{...navProps}
				>
					{Icon && <Icon size="20" />}
					<span>{title}</span>
				</Button>
				{secondary}
			</ListItem>
			<Collapse in={open}>
				{subLinks.map((s, i) => {
					const active = matchPath({ path: s.href }, location.pathname);
					return (
						<ListItem key={i} dense sx={{ color: active ? "primary.main" : "text.secondary", pl: 4 }} button to={s.href} component={RouterLink}>
							<ListItemText primary={s.title} />
						</ListItem>
					);
				})}
			</Collapse>
		</>
	);
};

NavItem.propTypes = {
	href: PropTypes.string,
	icon: PropTypes.elementType,
	title: PropTypes.string,
	secondary: PropTypes.node,
};

export default NavItem;
