import { Card, CardContent, Skeleton, Stack, Typography, Chip, CardMedia, Button } from "@mui/material";
import { withErrorBoundary } from "@sentry/react";
import FallBackCard from "../../components/FallBackCard";
import withLoading from "../../utils/withLoading";
import { useState } from "react";
import { Slack } from "react-feather";
import { Email } from "@mui/icons-material";

function MerchantCard({ _id, name, plans, claimCreatedAt, claimPolicies, ...a }) {
	const [notes, setNotes] = useState(claimPolicies?.notes?.slice(0, 500) || "");

	const handleShowMore = () => {
		if (claimPolicies?.notes?.length > notes.length) {
			setNotes(claimPolicies?.notes || "");
		} else setNotes(claimPolicies?.notes?.slice(0, 500) || "");
	};

	if (!_id) return null;
	const { reorders, refunds, giftCards, customerService, contacts } = claimPolicies || {};
	return (
		<Card width="100%">
			<Stack spacing={1}>
				<Stack direction={{ xs: "row", md: "column", lg: "row" }} spacing={2} alignItems="center">
					<CardMedia component="img" src={`https://cdn.savedby.io/logos/merchants/${_id}.png`} sx={{ width: { xs: "50%", md: "100%", lg: "50%" }, maxHeight: 200, objectFit: "contain" }} />
					<CardContent sx={{ width: { xs: "50%", md: "100%", lg: "50%" } }}>
						<Stack spacing={1}>
							<Typography textAlign="center">
								<b>{name}</b>
							</Typography>

							<Chip size="small" color={colors[+customerService]} label="Customer Service" />
							<Chip size="small" color={colors[+reorders]} label="Reorders" />
							<Chip size="small" color={colors[+refunds]} label="Refunds" />
							<Chip size="small" color={colors[+giftCards]} label="Gift Cards" />
						</Stack>
					</CardContent>
				</Stack>

				<CardContent sx={{ minWidth: 0, width: "100%" }}>
					{contacts?.map((c, i) => (
						<Stack direction="row" key={i}>
							{icons[c.type]}
							<Typography sx={{ whiteSpace: "break-spaces" }}> {c.value}</Typography>
						</Stack>
					))}

					<Typography sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word", width: "100%", mt: 1 }} dangerouslySetInnerHTML={{ __html: notes }} />
					<Button fullWidth variant="text" onClick={handleShowMore}>
						{claimPolicies?.notes?.length > 500 && (claimPolicies?.notes?.length > notes.length ? "Show More" : "Show Less")}
					</Button>
				</CardContent>
			</Stack>
		</Card>
	);
}

const icons = {
	SLACK: <Slack />,
	EMAIL: <Email />,
	OTHER: null,
};

const colors = ["error", "success", "info"];

function Loading() {
	return (
		<Card>
			<Stack direction="row" spacing={1}>
				<Skeleton variant="rectangular" width={151} height={151} />
				<CardContent sx={{ flex: 1 }}>
					<Stack alignItems="center" spacing={1}>
						<Skeleton width={150} />

						<Skeleton variant="rounded" width="100%" height={20} />
						<Skeleton variant="rounded" width="100%" height={20} />
						<Skeleton variant="rounded" width="100%" height={20} />
					</Stack>
				</CardContent>
			</Stack>
		</Card>
	);
}

export default withErrorBoundary(withLoading(MerchantCard, Loading), { fallback: FallBackCard("", "Error loading merchant") });
