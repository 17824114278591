import { Fragment, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { useAuth } from "src/providers/AuthProvider";

export default function AccountProfileDetails() {
	const [open, setOpen] = useState(false);
	const [, setLoading] = useState(false);
	//TODO, IMPLEMENT SAVE DETAILS BUTTON
	const { userInfo, changePassword } = useAuth();
	const { displayName, email } = userInfo;

	const handleChange = (event) => {};

	return (
		<Fragment>
			<form autoComplete="off" noValidate>
				<Card>
					<CardHeader subheader="The information can be edited" title="Profile" />
					<Divider />
					<CardContent>
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<TextField disabled fullWidth label="Name" onChange={handleChange} required value={displayName} variant="outlined" placeholder={displayName} />
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField disabled fullWidth label="Email Address" name="email" onChange={handleChange} required value={email} variant="outlined" />
							</Grid>
						</Grid>
					</CardContent>
					<Divider />
					<Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
						<Button color="primary" variant="contained" onClick={() => setOpen(true)}>
							Change Password
						</Button>
					</Box>
				</Card>
			</form>
			<PasswordChangeDialog open={open} setOpen={setOpen} onClose={() => setOpen(false)} changePassword={changePassword} setLoading={setLoading} />
		</Fragment>
	);
}

function PasswordChangeDialog({ open, onClose, setOpen, changePassword, setLoading }) {
	const [pass1, setPass1] = useState("");
	const [pass2, setPass2] = useState("");
	const [error, setError] = useState();

	useEffect(() => {
		if (open) {
			setPass1("");
			setPass2("");
		}
	}, [open]);

	const handleChangePassword = (newPass) => {
		setError();
		setLoading(true);
		changePassword(newPass)
			.then(() => setOpen(false))
			.catch((e) => setError(e.message))
			.finally(() => {
				setLoading(false);
				setOpen(false);
			});
	};

	return (
		<Dialog open={!!open} onClose={onClose}>
			<DialogTitle>New Password</DialogTitle>
			<DialogContent>
				{error && <Typography color="red">{error}</Typography>}
				<Stack spacing={3} sx={{ p: 2 }}>
					<TextField type="password" label="New Password" value={pass1} onChange={({ target }) => setPass1(target.value)} />
					<TextField type="password" label="Confirm Password" value={pass2} onChange={({ target }) => setPass2(target.value)} />
					<Button onClick={() => handleChangePassword(pass2)} disabled={!(pass1 === pass2 && pass1.length > 6)}>
						Update Password
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
