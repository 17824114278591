import { useEffect, useState, createContext, useContext, useCallback } from "react";
import API from "src/API";
import { useAuth } from "./AuthProvider";

const notificationContext = createContext({
	notifications: [],
});

export function NotificationProvider({ children }) {
	const value = useProvideNotification();
	return <notificationContext.Provider value={value}>{children}</notificationContext.Provider>;
}

export const useNotifications = () => {
	return useContext(notificationContext);
};

function useProvideNotification() {
	const [notifications, setNotifications] = useState([]);
	const { isSignedIn } = useAuth();

	useEffect(() => {
		if (!isSignedIn) return;
		API.notifications.get().then(({ notifications }) => setNotifications(notifications));
	}, [isSignedIn]);

	const markAsRead = useCallback((_id) => {
		API.notifications.update(_id, { isRead: true }).then(({ notification }) => {
			setNotifications((notifications) => {
				const found = notifications.find((n) => n._id === notification._id);
				Object.assign(found, notification);
				return [...notifications];
			});
		});
	}, []);

	return { notifications, markAsRead };
}
