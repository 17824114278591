import { Box, CardContent, IconButton, Menu, Stack, Typography } from "@mui/material";
import CheckBox from "./CheckBox";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import { useRef, useState } from "react";

export default function StatusHeader({ colDef, statusFilters, setStatusFilters, options }) {
	const [open, setOpen] = useState();
	const anchorRef = useRef();

	const handleChange = (status) => (v) => {
		setStatusFilters((filters) => (v ? [...filters, status] : filters.filter((s) => s !== status)));
	};

	return (
		<Box sx={{ minWidth: colDef.minWidth - 20 }} ref={anchorRef}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography>Status</Typography>
				<IconButton onClick={() => setOpen(true)}>{statusFilters.length ? <FilterAlt /> : <FilterAltOutlined />}</IconButton>
			</Stack>
			<Menu open={!!open} onClose={() => setOpen()} anchorEl={anchorRef.current}>
				<CardContent>
					<Stack>
						{options.map((o) => (
							<CheckBox key={o} label={o} checked={statusFilters.includes(o)} onChange={handleChange(o)} />
						))}
					</Stack>
				</CardContent>
			</Menu>
		</Box>
	);
}
