import { useState, createContext, useContext } from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";

const debugContext = createContext({
	content: "",
});

export function DebugProvider({ children }) {
	const value = useProvideDebug();
	return (
		<debugContext.Provider value={value}>
			<DebugDialog content={value.debug} onClose={() => value.setDebug(null)} />
			{children}
		</debugContext.Provider>
	);
}

export const useDebug = () => {
	return useContext(debugContext);
};

function useProvideDebug() {
	const [debug, setDebug] = useState(null);

	return { debug, setDebug };
}

function DebugDialog({ content, onClose }) {
	return (
		<Dialog open={!!content} onClose={onClose} sx={{ zIndex: 99999 }}>
			<DialogContent>
				<Typography whiteSpace="break-spaces">{JSON.stringify(content, null, 2)}</Typography>
			</DialogContent>
		</Dialog>
	);
}
