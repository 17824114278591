import { Avatar, AvatarGroup, Card, Chip, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import API from "src/API";
import View from "src/components/View";
import { useAuth } from "src/providers/AuthProvider";
import { DataGrid } from "@mui/x-data-grid";
import currency from "src/utils/currency";
import { ChevronDown, ChevronUp } from "react-feather";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function SalesView() {
	const [isLoading, setLoading] = useState(true);
	const [month, setMonth] = useState(new Date().toISOString().slice(0, 7));
	const [summary, setSummary] = useState([]);
	const [users, setUsers] = useState();

	const { userInfo } = useAuth();
	const theme = useTheme();
	const isLg = useMediaQuery(`@media (min-width:${theme.breakpoints.values.lg}px)`, { noSsr: true });

	useEffect(() => {
		setLoading(true);
		API.sales
			.getUsers()
			.then(setUsers)
			.catch(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (!month || !users) return;
		setLoading(true);
		API.sales
			.getSummary(month)
			.then((summary) => {
				const allSales = users.flatMap((u) => u.sales.map((s) => ({ ...s, uid: u._id })));

				setSummary(
					summary
						.sort((a, b) => b.savedByCollected - a.savedByCollected || b.totalOrdersMonth - a.totalOrdersMonth)
						.map((s) => {
							const sales = allSales
								.filter((ss) => ss.mid === s._id)
								.map((ss) => {
									const user = users.find((u) => u.uid === ss.uid);
									return {
										...ss,
										displayName: user.displayName,
										photoURL: user.photoURL,
									};
								});
							const salesCommissionPercentage = sales.reduce((p, c) => p + +c.commissionPercentage, 0);
							const totalSalesCommission = s.savedByTotalRev * (salesCommissionPercentage / 100);

							return {
								...s,
								optInRateDay: ((s.savedByOrdersDay / (s.totalOrdersDay || 1)) * 100).toFixed(0),
								optInRateMonth: ((s.savedByOrdersMonth / (s.totalOrdersMonth || 1)) * 100).toFixed(0),
								sales,
								totalSalesCommission,
								potentialNet: s.potentialNet - totalSalesCommission,
							};
						})
				);
			})
			.catch(console.log)
			.finally(setLoading);
	}, [month, users]);

	const totalSavedByRev = summary.reduce((p, c) => p + +c.savedByTotalRev, 0);
	const yourSavedByRev = summary
		.filter((m) => {
			const ownSales = m.sales?.find((s) => s.uid === userInfo.uid);
			if (!ownSales) return false;
			return ownSales && new Date(ownSales?.startMonth) <= Date.now() && new Date(ownSales?.endMonth) >= Date.now();
		})
		.reduce((p, c) => {
			const totalOwnCommission = c.sales.filter((s) => s.uid === userInfo.uid).reduce((p, c) => p + +c.commissionPercentage, 0) / 100;
			return p + +c.savedByTotalRev * totalOwnCommission;
		}, 0)
		.toLocaleString("en", { style: "currency", currency: "USD" });
	// MONTH
	const totalSavedByOrdersMonth = summary.reduce((p, c) => p + c.savedByOrdersMonth, 0);
	const totalOrdersMonth = summary.reduce((p, c) => p + c.totalOrdersMonth, 0);
	const optInRateMonth = ((totalSavedByOrdersMonth / (totalOrdersMonth || 1)) * 100).toFixed(0);
	// DAY
	const totalSavedByOrdersDay = summary.reduce((p, c) => p + c.savedByOrdersDay, 0);
	const totalOrdersDay = summary.reduce((p, c) => p + c.totalOrdersDay, 0);
	const optInRateDay = ((totalSavedByOrdersDay / (totalOrdersDay || 1)) * 100).toFixed(0);

	const revShareTotal = summary.reduce((p, c) => p + +(c.revShare || 0), 0);
	const claimsTotal = summary.reduce((p, c) => p + +(c.potentialClaimsTotal || 0), 0);
	const commissionTotal = summary.reduce((p, c) => p + +(c.commission || 0), 0);
	const merchantIncentives = revShareTotal + commissionTotal;
	const potentialNet = summary.reduce((p, c) => p + +c.potentialNet, 0);

	/**@type {import("@mui/x-data-grid").GridColDef} */
	const columns = [
		{
			field: "sales",
			headerName: "",
			disableColumnMenu: true,
			sortable: false,
			renderCell: ({ value }) => (
				<AvatarGroup>
					{value.map((v) => (
						<Avatar key={v.uid} src={v.photoURL}>
							{v.displayName?.split(" ")?.map((c) => c[0])}
						</Avatar>
					))}
				</AvatarGroup>
			),
		},
		{ field: "merchantName", headerName: "Merchant", width: 200 },
		{
			field: "optInMonth",
			align: "center",
			headerName: "Opt-In (Month)",
			flex: "auto",
			width: 105,
			renderCell: ({ row }) => (
				<Stack alignItems="center">
					{row.optInRateMonth}%
					<Typography variant="caption">
						({row.savedByOrdersMonth} / {row.totalOrdersMonth})
					</Typography>
				</Stack>
			),
		},
		{
			field: "optInToday",
			headerName: "Opt-In (Today)",
			align: "center",
			width: 100,
			renderCell: ({ row }) => (
				<Stack justifyContent="center" alignItems="center" height="100%" width="100%" sx={{ bgcolor: +row.optInRateDay === 0 ? false : row.optInRateMonth !== row.optInRateDay ? (+row.optInRateMonth > +row.optInRateDay ? `lightcoral` : "lightgreen") : false }}>
					{row.optInRateDay}%
					<Typography variant="caption">
						({row.savedByOrdersDay} / {row.totalOrdersDay})
					</Typography>
				</Stack>
			),
		},
		{ field: "savedByCollected", headerName: "Collected", renderCell: ({ value }) => !!value && currency(value), align: "right", width: 95, headerAlign: "right" },
		{ field: "revShare", headerName: "Rev Share", renderCell: ({ value }) => !!value && currency(value), align: "right", width: 95, headerAlign: "right" },
		{ field: "savedByTotalRev", headerName: "Revenue", renderCell: ({ value }) => !!+value && currency(value), align: "right", width: 95, headerAlign: "right" },
		{
			field: "claimsTotal",
			headerName: "Claims",
			align: "center",
			headerAlign: "center",
			width: 110,
			renderCell: ({ row }) =>
				!!(row.reviewingClaims + row.approvedClaims) && (
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
						<Chip size="small" color={row.reviewingClaims ? "warning" : "default"} label={row.reviewingClaims} />
						<Chip size="small" color={row.approvedClaims ? "success" : "default"} label={row.approvedClaims} />
					</Stack>
				),
		},
		{ field: "potentialClaimsTotal", headerName: "Potential Claims Total", renderCell: ({ value }) => !!+value && currency(value), align: "right", width: 150, headerAlign: "right" },
		{ field: "commission", headerName: "Merchant Commission", renderCell: ({ value }) => !!value && currency(value), align: "right", width: 160, headerAlign: "right" },
		{ field: "totalSalesCommission", headerName: "Sales Commission", renderCell: ({ value }) => !!value && currency(value), align: "right", width: 140, headerAlign: "right" },
		{
			field: "potentialNet",
			headerName: "Potential Net",
			renderCell: ({ value }) => !!value && <Typography color={value < 0 && "red"}>{currency(value)}</Typography>,
			align: "right",
			width: 110,
			headerAlign: "right",
		},
	];

	return (
		<View title="Sales" isLoading={isLoading}>
			<Stack>
				{!isLg ? (
					<>
						<MonthSelector value={month} onChange={setMonth} sx={{ mb: 2 }} />
						<MobileSummarySection
							{...{
								claimsTotal,
								merchantIncentives,
								optInRateDay,
								optInRateMonth,
								potentialNet,
								totalOrders: totalOrdersMonth,
								totalSavedByOrders: totalSavedByOrdersMonth,
								totalSavedByRev,
								yourSavedByRev,
							}}
						/>
					</>
				) : (
					<Stack direction="row" spacing={2} mb={1} alignItems="center">
						<CashCard value={totalSavedByRev} title="Total SavedBy Revenue" />
						<CashCard value={merchantIncentives} title="Merchant Incentives" />
						<CashCard value={claimsTotal} title="Potential Claims" />
						<CashCard value={potentialNet} title="Potential Net" />
						<Card sx={{ maxHeight: 125, p: 1, px: 2, height: "100%" }}>
							<Typography sx={{ fontSize: "16px !important", mb: 1 }}>Your Commission</Typography>
							<Typography textAlign="center" variant="h2">
								~{yourSavedByRev}
							</Typography>
						</Card>
						<Card sx={{ maxHeight: 125, minWidth: 200, p: 1, px: 2, height: "100%" }}>
							<Typography sx={{ fontSize: "16px !important", mb: 1 }}>Opt In Rate</Typography>
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Typography variant="h2">{optInRateMonth}%</Typography>
								<Typography variant="h2">
									{optInRateMonth !== optInRateDay && (optInRateDay > optInRateMonth ? <ChevronUp color="green" /> : <ChevronDown color="red" />)}
									{optInRateDay}%
								</Typography>
							</Stack>
						</Card>
						<MonthSelector value={month} onChange={setMonth} />
					</Stack>
				)}
				<DataGrid getRowId={(r) => r._id} rows={summary} columns={columns} sx={{ textOverflow: "clip" }} />
			</Stack>
		</View>
	);
}

function CashCard({ title, value }) {
	return (
		<Card sx={{ maxHeight: 125, p: 1, px: 2 }}>
			<Typography sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, mb: 1 }}>{title}</Typography>
			<Typography variant="h2" textAlign="center" color={value < 0 && "red"}>
				{currency(value)}
			</Typography>
		</Card>
	);
}

function MobileSummarySection({ claimsTotal, merchantIncentives, optInRateDay, optInRateMonth, potentialNet, totalSavedByRev, yourSavedByRev }) {
	return (
		<Grid container spacing={2} mb={2}>
			<Grid item xs={6}>
				<CashCard value={totalSavedByRev} title="Total SavedBy Revenue" />
			</Grid>
			<Grid item xs={6}>
				<CashCard value={merchantIncentives} title="Merchant Incentives" />
			</Grid>
			<Grid item xs={6}>
				<CashCard value={claimsTotal} title="Potential Claims" />
			</Grid>
			<Grid item xs={6}>
				<CashCard value={potentialNet} title="Potential Net" />
			</Grid>
			<Grid item xs={6}>
				<Card sx={{ maxHeight: 125, p: 1, px: 2 }}>
					<Stack>
						<Typography sx={{ fontSize: "12px !important", mb: 1 }}>Your Estimated Commission</Typography>
					</Stack>
					<Typography variant="h2" textAlign="center">
						~{yourSavedByRev}
					</Typography>
				</Card>
			</Grid>
			<Grid item xs={6}>
				<Card sx={{ maxHeight: 125, p: 1, px: 2, height: "100%" }}>
					<Typography sx={{ fontSize: "12px !important", mb: 1 }}>Opt In Rate</Typography>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Typography variant="h2">{optInRateMonth}%</Typography>
						<Typography variant="h2">
							{optInRateMonth !== optInRateDay && (optInRateDay > optInRateMonth ? <ChevronUp color="green" /> : <ChevronDown color="red" />)}
							{optInRateDay}%
						</Typography>
					</Stack>
				</Card>
			</Grid>
		</Grid>
	);
}

function MonthSelector({ onChange, sx = {} }) {
	const max = new Date().toISOString().slice(0, 7);
	const [value, setValue] = useState();

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				views={["year", "month"]}
				openTo="month"
				disableFuture
				onAccept={(e) => onChange(e.toISOString().slice(0, 7))}
				inputFormat="YYYY-MM"
				onChange={setValue}
				value={value}
				renderInput={(params) => <TextField {...params} sx={sx} />}
				minDate="2022-10"
				maxDate={max}
				showToolbar={false}
			/>
		</LocalizationProvider>
	);
}
