import { Stack, Button, TableContainer, Table, TableHead, ListSubheader, TableRow, TableCell, TableBody, Paper, Dialog, DialogContent, DialogTitle, TextField, ListItem, ListItemText, Card, List } from "@mui/material";
import { useEffect, useState } from "react";
import useAlert from "src/utils/Alert";
import API from "src/API";
import { Circle } from "@mui/icons-material";
import { HiQuestionMarkCircle } from "react-icons/hi";
import View from "src/components/View";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";

const AppChargesView = () => {
	const [isLoading, setLoading] = useState(true);
	const [link, setLink] = useState();
	const [appCharges, setAppCharges] = useState([]);
	const [appCharge, setAppCharge] = useState(false);
	const [createAppChargeOpen, setCreateAppChargeOpen] = useState(false);
	const [editAppCharge, setEditAppCharge] = useState(false);
	const { merchant } = useMerchant();
	const { success, error } = useAlert();

	useEffect(() => {
		if (merchant) {
			setLoading(true);
			API.merchants
				.getAppCharges(merchant._id)
				.then((r) => setAppCharges(r.appCharges))
				.catch(error)
				.finally(setLoading);
		}
	}, [merchant, error]);

	const handleCharge = (appChargeId, description, amount) => {
		setLoading(true);
		return API.merchants.createUsageCharge(merchant._id, appChargeId, description, amount).catch(error).finally(setLoading);
	};

	const handleCreateAppCharge = (cappedAmount) => {
		setLoading(true);
		return API.merchants
			.createAppCharge(merchant._id, cappedAmount)
			.then(() => {
				success("App Charge Created");
				setCreateAppChargeOpen(false);
			})
			.catch(error)
			.finally(setLoading);
	};

	const handleEditAppCharge = (appChargeId, cappedAmount) => {
		setLoading(true);
		return API.merchants
			.updateAppCharge(merchant._id, appChargeId, cappedAmount)
			.then(() => setEditAppCharge(false))
			.catch(error)
			.finally(setLoading);
	};

	const deleteAppCharge = (appChargeId) => {
		setLoading(true);
		if (window.confirm("Delete App Charge?")) {
			return API.merchants.deleteRecurringAppCharge(merchant._id, appChargeId).finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	};

	return (
		<View.Merchant title="Merchant" isLoading={isLoading} rightMenu={[{ onClick: () => setCreateAppChargeOpen(true), label: "Create App Charge" }]}>
			<Stack spacing={1}>
				<Card>
					<List dense>
						<ListSubheader>Usage Charges</ListSubheader>
						{appCharges
							?.flatMap((c) => c.usageCharges)
							.map((usageCharge) => (
								<ListItem key={usageCharge.id}>
									<ListItemText primary={usageCharge.billing_on} secondary={usageCharge.description} />
									<ListItemText primary={"$" + usageCharge.price} />
								</ListItem>
							))}
					</List>
				</Card>

				{/* RECURRING APP CHARGES */}
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={5} style={{ padding: 0, borderWidth: 0 }}>
									<ListSubheader disableSticky>Recurring App Charges</ListSubheader>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell />
								<TableCell>Name</TableCell>
								<TableCell>Capped Amount</TableCell>
								<TableCell>Balance Remaining</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{appCharges
								.filter((ac) => !ac.test)
								.map((appCharge, key) => (
									<AppChargeRow {...{ appCharge, key, mid: merchant._id, setAppCharge, setEditAppCharge, setLink, deleteAppCharge }} />
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
			<CreateUsageChargeDialog {...{ appCharge, onClose: () => setAppCharge(false), handleCharge }} />
			<CreateAppChargeDialog {...{ open: createAppChargeOpen, onClose: () => setCreateAppChargeOpen(false), handleCreateAppCharge }} />
			<EditAppChargeDialog {...{ appCharge: editAppCharge, onClose: () => setEditAppCharge(false), handleEditAppCharge }} />
			<ConfirmationLink {...{ link, onClose: () => setLink(false) }} />
		</View.Merchant>
	);
};

export default AppChargesView;

function ConfirmationLink({ link, onClose }) {
	console.log(link);
	return (
		<Dialog fullWidth open={!!link} onClose={onClose}>
			<DialogContent>
				<TextField onFocus={({ target }) => target.select()} fullWidth value={link} />
			</DialogContent>
		</Dialog>
	);
}

function AppChargeRow({ appCharge, setAppCharge, setEditAppCharge, setLink, deleteAppCharge }) {
	return (
		<TableRow>
			<TableCell>
				<RenderStatus status={appCharge.status} />
			</TableCell>
			<TableCell>{appCharge.name || "<name>"}</TableCell>
			<TableCell>{"$" + appCharge.capped_amount}</TableCell>
			<TableCell>{"$" + appCharge.balance_remaining}</TableCell>
			<TableCell>
				<Stack direction="row" spacing={1}>
					<Button onClick={() => setEditAppCharge(appCharge)}>Edit</Button>
					<Button disabled={appCharge.status !== "active"} onClick={(e) => setAppCharge(appCharge)}>
						Charge
					</Button>
					{appCharge.confirmation_url && <Button onClick={() => setLink(appCharge.confirmation_url)}>Link</Button>}
					<Button color="error" onClick={() => deleteAppCharge(appCharge.id)}>
						Delete
					</Button>
				</Stack>
			</TableCell>
		</TableRow>
	);
}

function CreateUsageChargeDialog({ appCharge, onClose, handleCharge }) {
	const [open, setOpen] = useState(false);
	const [chargeAmount, setChargeAmount] = useState(0.0);
	const [description, setDescription] = useState("SavedBy Fee Remittance ");

	useEffect(() => {
		if (appCharge) {
			setOpen(true);
		} else {
			setOpen(false);
			setChargeAmount(0.0);
			setDescription("SavedBy Fee Remittance ");
		}
	}, [appCharge]);

	const charge = (e) => {
		handleCharge(appCharge.id, description, chargeAmount).then(() => onClose());
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md">
			<DialogTitle>Create Usage Charge</DialogTitle>
			<DialogContent>
				<Stack spacing={2} style={{ paddingTop: 8 }}>
					<TextField label="Description" value={description} onChange={({ target }) => setDescription(target.value)} />
					<TextField inputProps={{ step: 0.01, min: 0, max: appCharge.balance_remaining }} label={`Amount to charge`} type="number" value={chargeAmount} onChange={({ target }) => setChargeAmount(Math.max(Math.min(appCharge.balance_remaining, +target.value), 0))} />

					<Button disabled={!(description && chargeAmount > 0)} fullWidth variant="contained" onClick={charge}>
						Charge
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function CreateAppChargeDialog({ open, onClose, handleCreateAppCharge }) {
	const [cappedAmount, setCappedAmount] = useState(0.0);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Create App Charge</DialogTitle>
			<DialogContent>
				<Stack spacing={2} style={{ paddingTop: 8 }}>
					<TextField type="number" label="Capped Amount" value={cappedAmount} onChange={({ target }) => setCappedAmount(Math.max(target.value, 0))} />

					<Button disabled={!cappedAmount > 0} fullWidth variant="contained" onClick={() => handleCreateAppCharge(cappedAmount)}>
						Create
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function EditAppChargeDialog({ appCharge, onClose, handleEditAppCharge }) {
	const [cappedAmount, setCappedAmount] = useState(0.0);

	return (
		<Dialog open={!!appCharge} onClose={onClose}>
			<DialogTitle>Edit App Charge</DialogTitle>
			<DialogContent>
				<Stack spacing={2} style={{ paddingTop: 8 }}>
					<TextField type="number" label="Capped Amount" value={cappedAmount} onChange={({ target }) => setCappedAmount(Math.max(target.value, 0))} />

					<Button disabled={!cappedAmount > 0} fullWidth variant="contained" onClick={() => handleEditAppCharge(appCharge.id, cappedAmount)}>
						Update
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function RenderStatus({ status }) {
	if (status === "active") return <Circle sx={{ color: "green" }} />;
	if (status === "pending") return <Circle sx={{ color: "orange" }} />;
	if (status === "expired") return <Circle sx={{ color: "red" }} />;
	if (status === "cancelled") return <Circle sx={{ color: "red" }} />;
	return <HiQuestionMarkCircle />;
}
