import { useEffect, useState } from "react";
import { Paper, Card, CardContent, CardHeader, Skeleton, Stack, IconButton, Grid, Select, MenuItem, Checkbox, TextField, FormControlLabel, FormGroup } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import API from "src/API";
import useAlert from "src/utils/Alert";

export default function MerchantPlan({ merchant, saveMerchant }) {
	const [isEditing, setEditing] = useState(false);
	const [plan, setPlan] = useState();
	const [plans, setPlans] = useState();
	const [properties, setProperties] = useState();
	const { error } = useAlert();
	const [mutated, setMutated] = useState(false);

	useEffect(() => {
		if (API.plans) {
			API.plans
				.getPlans()
				.then(({ plans, properties }) => {
					setPlans(plans);
					setProperties(properties);
				})
				.catch(error);
		}
	}, [error]);

	useEffect(() => {
		if (merchant?.plan) {
			setPlan({ ...merchant.plan, properties: { ...merchant.plan.properties } });
		} else {
			setPlan({});
		}
	}, [merchant?.plan]);

	const handlePlanChange = (key) => {
		const newPlan = plans.find((k) => k.id === key);
		const properties = { ...newPlan.properties };

		for (const prop in properties) {
			if (plan.properties && plan.properties.hasOwnProperty(prop)) {
				properties[prop] = plan.properties[prop];
			}
		}
		setPlan({ ...newPlan, properties });
		setMutated(true);

		return { newPlan };
	};

	const handlePropUpdate = (key, value) => {
		setMutated(true);
		setPlan((plan) => {
			plan.properties[key] = value;

			return { ...plan };
		});
	};

	const handleOnSave = () => {
		if (plan) {
			saveMerchant({ plan });
			setEditing(false);
			setMutated(false);
		}
	};

	const handleOnCancel = () => {
		if (merchant.plan) {
			setPlan({ ...merchant.plan, properties: { ...merchant.plan.properties } });
		} else {
			setPlan({});
		}
		setMutated(false);
		setEditing(false);
	};

	if (!(plan && plans && properties)) return <Skeleton variant="rectangular" height={200} />;

	return (
		<Card component={Paper}>
			<CardHeader
				title="Plan"
				action={
					isEditing ? (
						<Stack spacing={1} direction="row">
							<IconButton onClick={handleOnCancel}>
								<CloseIcon color="error" />
							</IconButton>
							<IconButton disabled={!mutated} onClick={handleOnSave}>
								<CheckIcon color={mutated ? "success" : "disabled"} />
							</IconButton>
						</Stack>
					) : (
						<IconButton onClick={() => setEditing(true)}>
							<EditIcon color="primary" />
						</IconButton>
					)
				}
			/>
			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<Select value={plan.id || 0} onChange={({ target }) => handlePlanChange(target.value)} disabled={!isEditing} variant="filled" fullWidth>
							<MenuItem disabled value={0}>
								Select A Plan
							</MenuItem>
							{plans.map((p) => (
								<MenuItem key={p.id} value={p.id}>
									<b>{p.name}</b> {"  - " + p.description}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid container item xs={12} lg={12} direction="row" justifyContent="space-evenly" sx={{ pt: 3 }}>
						{plan.properties && properties.map((property, i) => <Property {...{ key: i, property, handlePropUpdate, isEditing, value: plan.properties[property.key] ?? property.default }} />)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function Property({ property, value, isEditing, handlePropUpdate }) {
	if (property.type === "boolean")
		return (
			<FormGroup>
				<FormControlLabel control={<Checkbox disabled={!isEditing} checked={value} onChange={(e, v) => handlePropUpdate(property.key, v)} />} label={property.label} />
			</FormGroup>
		);

	return <TextField disabled={!isEditing} value={value} label={property.label} onChange={({ target }) => handlePropUpdate(property.key, target.value)} />;
}
