import { Button, ButtonGroup, Grid, Stack, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import TimelineCard from "./TimelineCard";
import API from "src/API";
import ProgressBar from "./ProgressBar";
import ItemsCard from "./ItemsCard";
import CustomerInfo from "./CustomerInfo";
import ShippingCard from "./ShippingCard";
import InfoCard from "./InfoCard";
import RefundCard from "./RefundCard";
import View from "src/components/View";
import { useAuth } from "src/providers/AuthProvider";
import StatusCard from "./StatusCard";
import { useQuery } from "react-query";
import MerchantCard from "./MerchantCard";
import { useClaims } from "src/layouts/claim/ClaimsLayout";
import ProcessDialog from "./ProcessDialog";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { TabContext, TabPanel } from "@mui/lab";
import StatusChip from "src/components/StatusChip";
import CodeEditor from "src/components/CodeEditor";

export default function ClaimView() {
	const { id } = useParams();
	const { displayName, roles } = useAuth().userInfo;
	const isDev = roles.includes("DEV");

	const { claim, save, isLoading, setClaim, nextClaimId, prevClaimId, processClaim, manualProcess } = useClaims();
	const [processOpen, setProcessOpen] = useState();

	const t = useTheme();
	const isSm = useMediaQuery(t.breakpoints.down("md"), { noSsr: true });

	const { isIdle, isLoading: orderIsLoading, data: { order } = {} } = useQuery(["orderId", claim?.orderId], () => API.merchants.getStoreOrder(claim?.mid, claim?.orderId), { enabled: !!claim?.orderId });
	const { isIdle: isMerchantIdle, isLoading: isMerchantLoading, data: merchant } = useQuery(["mid", claim?.mid], () => API.merchants.get(claim?.mid), { enabled: !!claim?.mid });
	const inventories = useQuery(
		["inventory", id],
		() =>
			API.merchants.product.getInventory(
				claim?.mid,
				order.lineItems.map((i) => i.variantId)
			),
		{ enabled: !!order?.lineItems && !!claim?.mid && ["DRAFT", "REVIEWING", "PENDING"].includes(claim?.status), retry: false }
	);

	const nav = useNavigate();

	const saveClaim = (data = {}) => {
		if (data.newEvent) {
			claim.events.unshift({
				...data.newEvent,
				ts: Date.now(),
				who: displayName,
			});
		}
		delete data.newEvent;
		Object.assign(claim, data);
		return save();
	};

	const approveClaim = () =>
		new Promise((resolve) => {
			API.claims
				.approve(id)
				.then((claim) => {
					setClaim(claim);
					resolve();
				})
				.catch(console.error);
		});

	const handleManualProcess = (data) => {
		manualProcess(claim._id, data).then(() => {
			setProcessOpen();
		});
	};

	const editDisabled = !["REVIEWING", "DRAFT"].includes(claim?.status);

	const merchantIsReady = isMerchantIdle || isMerchantLoading;
	const orderIsReady = isIdle || orderIsLoading;
	return (
		<View title="Claim" isLoading={isLoading} open={false}>
			{isSm ? (
				<MobileView {...{ claim, prevClaimId, nextClaimId, nav, saveClaim, editDisabled, order, merchantIsReady, orderIsReady, isLoading, displayName, approveClaim, merchant, setProcessOpen, processClaim, inventories }} />
			) : (
				<DesktopView {...{ claim, prevClaimId, nextClaimId, nav, saveClaim, editDisabled, order, merchantIsReady, orderIsReady, isLoading, displayName, approveClaim, merchant, setProcessOpen, processClaim, inventories }} />
			)}
			{isDev && claim && (
				<Grid item xs={12}>
					<CodeEditor value={claim} setValue={setClaim} />
					<Button sx={{ mt: 2 }} fullWidth onClick={() => save()}>
						Save
					</Button>
				</Grid>
			)}
			<ProcessDialog {...{ open: processOpen, onClose: () => setProcessOpen(), handleManualProcess, claim }} />
		</View>
	);
}

function MobileView({ claim, prevClaimId, nextClaimId, nav, saveClaim, editDisabled, order, displayName, merchantIsReady, orderIsReady, isLoading, approveClaim, merchant, setProcessOpen, processClaim }) {
	const [tab, setTab] = useState(0);

	return (
		<Grid container>
			<Grid item xs={12}>
				<MobileHeaderCard {...{ claim, prevClaimId, nextClaimId, nav }} />
			</Grid>
			<Grid item xs={12}>
				<StatusCard {...{ saveClaim, isLoading, approveClaim, processClaim, setProcessOpen, ...claim }} />
			</Grid>

			<Grid item xs={12}>
				<TabContext value={tab}>
					<Tabs value={tab} onChange={(e, v) => setTab(v)} variant="fullWidth">
						<Tab label="Claim" value={0} />
						<Tab label="Items" value={1} />
						<Tab label="Customer" value={2} />
						<Tab label="Timeline" value={3} />
					</Tabs>
					<SwipeableViews index={tab} onChangeIndex={(v) => setTab(v)} style={{ margin: "0 -16px" }}>
						<TabPanel value={tab} index={0} sx={{ p: 2 }}>
							<Grid container rowSpacing={2}>
								<Grid item xs={12}>
									<InfoCard {...{ claim, saveClaim, isLoading, editDisabled, merchant }} />
								</Grid>
								<Grid item xs={12}>
									<MerchantCard {...{ isLoading: merchantIsReady || orderIsReady, claimCreatedAt: claim?.createdAt, ...merchant }} />
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={1} sx={{ p: 2 }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<ItemsCard {...{ saveClaim, editDisabled, order, isLoading: merchantIsReady || orderIsReady, claim }} />
								</Grid>
								<Grid item xs={12}>
									<RefundCard {...{ isLoading, refunds: order?.refunds }} />
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={2} sx={{ p: 2 }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<CustomerInfo {...{ isLoading, editDisabled, ...claim }} />
								</Grid>
								<Grid item xs={12}>
									<ShippingCard {...{ editDisabled, saveClaim, isLoading, ...claim }} />
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={3} sx={{ p: 2 }}>
							<TimelineCard {...{ saveClaim, isLoading, displayName, ...claim }} />
						</TabPanel>
					</SwipeableViews>
				</TabContext>
			</Grid>
		</Grid>
	);
}

function MobileHeaderCard({ claim, prevClaimId, nextClaimId, nav }) {
	if (!claim) return null;
	return (
		<Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
			<StatusChip status={claim.status} subStatus={claim.subStatus} variant="claim" sx={{ width: 180 }} />
			<ButtonGroup size="small">
				<Button size="small" disabled={!prevClaimId} onClick={() => nav("/claims/" + prevClaimId)} sx={{ width: 80, height: 40, fontSize: "28px" }}>
					{"<"}
				</Button>
				<Button size="small" disabled={!nextClaimId} onClick={() => nav("/claims/" + nextClaimId)} sx={{ width: 80, height: 40, fontSize: "28px" }}>
					{">"}
				</Button>
			</ButtonGroup>
		</Stack>
	);
}

function DesktopView({ claim, prevClaimId, nextClaimId, nav, saveClaim, editDisabled, order, merchantIsReady, orderIsReady, isLoading, displayName, approveClaim, merchant, setProcessOpen, processClaim, inventories }) {
	return (
		<Grid container spacing={2} sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<Stack direction="row">
					<ProgressBar status={claim?.status} subStatus={claim?.subStatus} />
					<ButtonGroup>
						<Button disabled={!prevClaimId} onClick={() => nav("/claims/" + prevClaimId)} sx={{ width: "5%", height: "fit-content", fontSize: "28px" }}>
							{"<"}
						</Button>
						<Button disabled={!nextClaimId} onClick={() => nav("/claims/" + nextClaimId)} sx={{ width: "5%", height: "fit-content", fontSize: "28px" }}>
							{">"}
						</Button>
					</ButtonGroup>
				</Stack>
			</Grid>

			<Grid container item direction="column" xs={12} sm={8} spacing={2}>
				<Grid item>
					<ItemsCard {...{ saveClaim, editDisabled, order, isLoading: merchantIsReady || orderIsReady, claim, inventories }} />
				</Grid>

				<Grid item>
					<RefundCard {...{ isLoading, refunds: order?.refunds }} />
				</Grid>

				<Grid item>
					<TimelineCard {...{ saveClaim, isLoading, displayName, ...claim }} />
				</Grid>
			</Grid>

			<Grid container item direction="column" xs={12} sm={4} spacing={2}>
				<Grid item>
					<StatusCard {...{ saveClaim, isLoading, approveClaim, processClaim, setProcessOpen, ...claim }} />
				</Grid>

				<Grid item>
					<MerchantCard {...{ isLoading: merchantIsReady || orderIsReady, claimCreatedAt: claim?.createdAt, ...merchant }} />
				</Grid>

				<Grid item>
					<InfoCard {...{ claim, saveClaim, isLoading, editDisabled, merchant }} />
				</Grid>

				<Grid item>
					<CustomerInfo {...{ isLoading, editDisabled, ...claim }} />
				</Grid>

				<Grid item>
					<ShippingCard {...{ editDisabled, saveClaim, isLoading, ...claim }} />
				</Grid>
			</Grid>
		</Grid>
	);
}
