import { Avatar, Grid, ListItemText, Typography } from "@mui/material";

export default function LineItem({ price, originalPrice, discountTotal, discounts, quantity, title, variantTitle }) {
	return (
		<Grid container spacing={2} wrap="nowrap" alignItems="center">
			<Grid item>
				<Avatar sx={{ color: "black", bgcolor: "white", border: "solid 2px blue" }}>{quantity}</Avatar>
			</Grid>

			<Grid container item xs>
				<Grid item xs={12} sm>
					<ListItemText
						primary={title}
						secondary={
							<>
								{variantTitle}
								{discounts.map((d) => (
									<>
										<br />
										{`• ${d.code || "Custom"} (${d.value}${d.valueSuffix}) - $${d.amount}`}
									</>
								))}
							</>
						}
					/>
				</Grid>

				<Grid container item xs={6} md={2} alignItems="center" justifyContent="space-between">
					{discountTotal > 0 && (
						<Grid item xs>
							<Typography component="strike">${originalPrice}</Typography>
						</Grid>
					)}
					<Grid item>
						<Typography noWrap>
							${price} x {quantity}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={2}>
				<Typography textAlign="right">${(price.replace(".", "") * quantity) / 100}</Typography>
			</Grid>
		</Grid>
	);
}
