import { Box, Button, Container, Grid, Menu, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";
import LoadingIndicator from "./LoadingIndicator";
import { Circle } from "@mui/icons-material";
import merchantColors from "./merchantColors";

function View({ title, isLoading, children, toolbar }) {
	const containerRef = useRef();
	return (
		<Box height="100%">
			<Helmet>
				<title>{title + " | Admin"}</title>
			</Helmet>
			<Box ref={containerRef} sx={{ backgroundColor: "background.default", height: "100%", minHeight: "100%", position: "relative" }}>
				<Container id="view" maxWidth={false} sx={{ overflowX: "hidden", height: "100%" }}>
					<Stack spacing={2} sx={{ height: "100%" }}>
						{toolbar}
						{children}
					</Stack>
				</Container>
				<LoadingIndicator open={isLoading} container={containerRef.current} />
			</Box>
		</Box>
	);
}

View.Merchant = function ViewMerchant({ title, isLoading, children, leftAction, rightAction, rightMenu }) {
	const { merchants, merchant, setMerchant } = useMerchant();
	const nav = useNavigate();
	const [anchorEl, setAnchorEl] = useState(false);
	const containerRef = useRef();

	const leftActionDefault = (
		<Button onClick={() => nav(-1)} variant="outlined">
			Back
		</Button>
	);

	if (rightMenu) {
		rightAction = (
			<>
				<Button onClick={({ target }) => setAnchorEl(target)} variant="outlined">
					menu
				</Button>
				<Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl()}>
					{rightMenu.map(({ onClick, label }, i) => (
						<MenuItem {...{ onClick, key: i }}>{label}</MenuItem>
					))}
				</Menu>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{title + " | Admin"}</title>
			</Helmet>
			<Box sx={{ backgroundColor: "background.default", minHeight: "100%" }} ref={containerRef}>
				<Container maxWidth={false} sx={{ overflowX: "hidden", mt: 2 }}>
					<Stack spacing={2}>
						<Grid container alignItems="center">
							<Grid item>{leftAction ?? leftActionDefault}</Grid>
							<Grid item xs sx={{ textAlign: "center" }}>
								<Select value={merchant?._id || ""} onChange={({ target }) => setMerchant(merchants.find((m) => m._id === target.value))}>
									{merchants?.map((m) => (
										<MenuItem key={m._id} value={m._id}>
											<Stack direction="row" spacing={1}>
												<Tooltip placement="left" title={m.status}>
													<Circle sx={{ color: merchantColors[m.status]?.bgcolor }} />
												</Tooltip>
												<Typography>{m.name}</Typography>
											</Stack>
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item>{rightAction}</Grid>
						</Grid>

						{children}
					</Stack>
				</Container>
			</Box>
			<LoadingIndicator open={isLoading} container={containerRef.current} />
		</>
	);
};
export default View;
