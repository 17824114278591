import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "src/layouts/dashboard/DashboardLayout";
import MainLayout from "src/layouts/main/MainLayout";
import {
	AccountView,
	AutomationsView,
	ClaimsView,
	ClaimView,
	CustomerFaqView,
	FaqView,
	LoginView,
	MerchantsPaths,
	NotFoundView,
	OldStatementsView,
	PlansView,
	RegisterView,
	RolesView,
	SalesView,
	SalesBookingsView,
	SettingsView,
	StatementsView,
	TermsOfServiceListView,
	TermsOfServiceView,
	UsersView,
	UserView,
	TransactionsView,
	AdjustmentsView,
	CdnView,
} from "src/pages";
import MerchantLayout from "./layouts/merchant/MerchantLayout";
import ClaimsLayout from "./layouts/claim/ClaimsLayout";
import { useAuth } from "./providers/AuthProvider";
import AccountsView from "./pages/AccountsView";
import CliLogin from "./pages/Login";

export default function Routes() {
	const { isSignedIn, userInfo: user } = useAuth();

	const links = [
		// { path: "dashboard", element: <DashboardView />, roles: ["AGENT", "DEV", "OWNER", "SALES"] },
		{ path: "sales", element: <SalesView />, roles: ["DEV", "OWNER", "SALES"] },
		{ path: "sales/bookings", element: <SalesBookingsView />, roles: ["DEV", "OWNER", "SALES"] },
		{ path: "merchants", element: <MerchantsPaths.MerchantsView />, roles: ["AGENT", "DEV", "OWNER", "SALES_MGR"] },
		{ path: "account", element: <AccountView /> },
		{ path: "users/:id", element: <UserView />, roles: ["DEV", "OWNER"] },
		{ path: "users", element: <UsersView />, roles: ["DEV", "OWNER"] },
		{ path: "settings", element: <SettingsView />, roles: ["DEV"] },
		{ path: "settings/roles", element: <RolesView />, roles: ["DEV"] },
		{ path: "plans", element: <PlansView />, roles: ["DEV", "OWNER"] },
		{ path: "automations", element: <AutomationsView />, roles: ["DEV"] },
		{ path: "cdn", element: <CdnView />, roles: ["DEV", "OWNER"] },
		{
			path: "billing",
			roles: ["DEV", "OWNER"],
			children: [
				{ path: "accounts", element: <AccountsView />, roles: ["DEV", "OWNER"] },
				{ path: "statements", element: <StatementsView />, roles: ["DEV", "OWNER"] },
				{ path: "old-statements", element: <OldStatementsView />, roles: ["DEV", "OWNER"] },
				{ path: "adjustments", element: <AdjustmentsView />, roles: ["DEV", "OWNER"] },
				{ path: "transactions", element: <TransactionsView />, roles: ["DEV", "OWNER"] },
			],
		},
		{ path: "cms/merchant-faq", element: <FaqView />, roles: ["DEV", "OWNER"] },
		{ path: "cms/customer-faq", element: <CustomerFaqView />, roles: ["DEV", "OWNER"] },
		{ path: "cms/terms-of-service", element: <TermsOfServiceListView />, roles: ["DEV", "OWNER"] },
		{ path: "cms/terms-of-service/:id", element: <TermsOfServiceView />, roles: ["DEV", "OWNER"] },
		{ path: "*", element: <Navigate to="/claims" replace />, roles: ["AGENT", "DEV", "OWNER"] },
		{
			path: "claims",
			roles: ["DEV", "AGENT", "OWNER"],
			element: <ClaimsLayout />,
			children: [
				{ path: "", element: <ClaimsView />, roles: ["AGENT", "DEV", "OWNER"] },
				{ path: ":id", element: <ClaimView />, roles: ["AGENT", "DEV", "OWNER"] },
			].filter((route) => !route.roles || route.roles?.some((rr) => user.roles?.includes(rr))),
		},
		{
			path: "merchants/:mid",
			roles: ["AGENT", "DEV", "SALES_MGR", "OWNER"],
			element: <MerchantLayout />,
			children: [
				{ path: "", element: <MerchantsPaths.MerchantView />, roles: ["AGENT", "DEV", "OWNER", "SALES_MGR"] },
				{ path: "orders", element: <MerchantsPaths.OrdersView />, roles: ["AGENT", "DEV", "OWNER"] },
				{ path: "orders/:oid", element: <MerchantsPaths.OrderView />, roles: ["AGENT", "DEV", "OWNER"] },
				{ path: "reports", element: <MerchantsPaths.ReportsView />, roles: ["DEV", "OWNER"] },
				{ path: "reports/:id", element: <MerchantsPaths.ReportView />, roles: ["DEV", "OWNER"] },
				{ path: "billing", element: <MerchantsPaths.AppChargesView />, roles: ["DEV", "OWNER"] },
				{ path: "widget-config", element: <MerchantsPaths.WidgetConfigView />, roles: ["DEV", "OWNER", "SALES_MGR"] },
				{ path: "product", element: <MerchantsPaths.ProductView />, roles: ["DEV"] },
				{ path: "onboarding", element: <MerchantsPaths.OnboardView />, roles: ["DEV", "OWNER", "SALES_MGR"] },
				{ path: "dev", element: <MerchantsPaths.DevView />, roles: ["DEV"] },
				{ path: "users", element: <MerchantsPaths.UsersView />, roles: ["DEV", "OWNER"] },
				{ path: "claim-policies", element: <MerchantsPaths.ClaimPoliciesView />, roles: ["DEV", "OWNER", "AGENT", "SALES_MGR"] },
				{ path: "sms", element: <MerchantsPaths.SMSView />, roles: ["DEV", "OWNER"] },
				{ path: "payment-methods", element: <MerchantsPaths.PaymentMethodsView />, roles: ["DEV", "OWNER"] },
			].filter((route) => !route.roles || route.roles?.some((rr) => user.roles?.includes(rr))),
		},
	].filter((route) => !route.roles || route.roles?.some((rr) => user.roles?.includes(rr)));

	links.push({ path: "/", element: <Navigate to={links[0]?.path} replace /> });

	return useRoutes([
		{
			path: "/cli-login",
			element: <CliLogin />,
		},
		{
			path: "/",
			children: [
				{
					path: "/",
					element: isSignedIn ? <DashboardLayout /> : <Navigate to="/login" />,
					children: links,
				},
				{
					path: "/",
					element: <MainLayout />,
					children: [
						{
							path: "login",
							element: isSignedIn ? <Navigate to="/" /> : <LoginView />,
						},
						{ path: "register", element: <RegisterView /> },
						{ path: "404", element: <NotFoundView /> },
						{ path: "", element: <Navigate replace to={isSignedIn ? "/" : "/login"} /> },
					],
				},
			],
		},
	]);
}
