import { Button, ListItem, Menu, MenuItem, Stack, Typography, List, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAlert from "src/utils/Alert";
import API from "src/API";
import View from "src/components/View";
import { useMerchant } from "src/layouts/merchant/MerchantLayout";

export default function ReportsView() {
	const [isLoading, setLoading] = useState(true);
	const [{ reports }, setReports] = useState({ reports: [], prev: null, next: null });
	const [anchorEl, setAnchorEl] = useState(false);
	const nav = useNavigate();
	const { merchant } = useMerchant();
	const { error, info } = useAlert();

	useEffect(() => {
		API.reports.getAllByMid(merchant._id).then(setReports).catch(error).finally(setLoading);
	}, [error, merchant._id]);

	const handleCreateReport = () => {
		setAnchorEl();
		const month = "07";
		const year = "2022";
		info("Creating report");
		API.reports
			.create({ mid: "us87drxKDihIVLVIwm62", month, year })
			.then((report) => {
				// setReports((r) => [report, ...r]);
				// window.location.hash = "#" + report.id;
			})
			.catch(error);
	};

	return (
		<View open={false} title="Reports" isLoading={isLoading}>
			<Stack direction="row" justifyContent="space-between">
				<Button onClick={() => nav(-1)} variant="outlined">
					Back
				</Button>

				<Button onClick={({ target }) => setAnchorEl(target)} variant="outlined">
					menu
				</Button>
				<Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl()}>
					<MenuItem onClick={handleCreateReport}>Create Report</MenuItem>
				</Menu>
			</Stack>
			{!isLoading && !reports.length ? (
				<Typography textAlign="center">NO REPORTS</Typography>
			) : (
				<Stack spacing={1}>
					<List>
						{reports.map((report) => (
							<ListItem key={report.id} button onClick={() => nav(report.id)}>
								<ListItemText primary={report.id} secondary={report.chargeTotal} />
							</ListItem>
						))}
					</List>
				</Stack>
			)}
		</View>
	);
}
