import { Stack, TextField, Switch, Collapse } from "@mui/material";
import { useEffect, useState } from "react";

import { useMerchant } from "src/layouts/merchant/MerchantLayout";

import View from "src/components/View";
import { Card, CardContent, CardHeader } from "@mui/material";
import useAlert from "src/utils/Alert";
import { LoadingButton } from "@mui/lab";

export default function DevView() {
	const { merchant, save } = useMerchant();
	const [isLoading, setLoading] = useState(true);
	const [sms, setSms] = useState();
	const { success, error } = useAlert();

	const handleSave = () => {
		setLoading(true);
		save({ sms })
			.then(() => success("SMS Saved!"))
			.catch(error)
			.finally(setLoading);
	};

	const handleChange = (trigger) => (v) => {
		setSms((sms) => {
			Object.assign(sms[trigger], v);
			return { ...sms };
		});
	};

	useEffect(() => {
		if (!merchant) return;
		setLoading(false);
		setSms(merchant.sms);
	}, [merchant]);

	return (
		<View.Merchant
			title="Merchant"
			rightAction={
				<LoadingButton variant="outlined" loading={isLoading} onClick={handleSave}>
					Save
				</LoadingButton>
			}
		>
			{sms && (
				<Stack spacing={1}>
					<SMSCard triggerLabel={"In Transit"} content={sms.inTransit.content} active={sms.inTransit.active} onChange={handleChange("inTransit")} />
					<SMSCard triggerLabel={"Out For Delivery"} content={sms.outForDelivery.content} active={sms.outForDelivery.active} onChange={handleChange("outForDelivery")} />
					<SMSCard triggerLabel={"Delivered"} content={sms.delivered.content} active={sms.delivered.active} onChange={handleChange("delivered")} />
				</Stack>
			)}
		</View.Merchant>
	);
}

function SMSCard({ triggerLabel, content, active, onChange }) {
	return (
		<Card>
			<CardHeader title={triggerLabel} action={<Switch checked={active} onChange={(e, v) => onChange({ active: v })} />} />
			<Collapse in={active}>
				<CardContent>
					<TextField disabled={!active} fullWidth multiline minRows={4} value={content} onChange={({ target }) => onChange({ content: target.value })} />
				</CardContent>
			</Collapse>
		</Card>
	);
}
